// TODO 画面を全部reactに統一したらTokenStoreを消せないか検討
export class TokenStore {
  private static key = "idToken"

  static setToken(token: string): void {
    document.cookie = `${TokenStore.key}=${token};max-age=${12 * 60 * 60};path=/`
  }

  static getToken(): string | undefined {
    return document.cookie
      .split(";")
      .find((row) => row.trim().startsWith(TokenStore.key))
      ?.split("=")?.[1]
  }

  static clearToken(): void {
    document.cookie = `${TokenStore.key}=;max-age=${0};path=/`
  }

  static hasToken(): boolean {
    return document.cookie.split(";").some((row) => row.trim().startsWith(TokenStore.key))
  }
}
