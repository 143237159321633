import { WorkspaceApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { Workspace, PutWorkspaceRequest } from "model"

export interface WorkspaceResponse {
  workspaceId: string
  workspaceName: string
  email: string
  createdDatetime: string
  updatedDatetime: string
}

export const toWorkspace = (data: WorkspaceResponse): Workspace =>
  Object.assign(data, {
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as Workspace

export class WorkspaceApiClientImpl implements WorkspaceApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  putWorkspace = async (workspaceId: string, body: PutWorkspaceRequest): Promise<Workspace> => {
    const path = `/api/v1/workspaces/${workspaceId}`
    const res = await this.client.putWithAuth<WorkspaceResponse>(path, body)
    return toWorkspace(res.data)
  }
}
