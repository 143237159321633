import { styled } from "linaria/react"
import React, { useMemo, useCallback, useState } from "react"
import { Link } from "react-router-dom"
import "moment/locale/ja"
import ReactSelect, { StylesConfig } from "react-select"

import { clients } from "api/clients"
import { WorkspaceMember, Role, RoleOption } from "component/page/workspaceMembers"
import * as m from "model"

type MembersTableProps = {
  workspaceId: string | null
  myWorkspaceMemberId: string | null
  members: WorkspaceMember[]
  isOwner: boolean
  setMembers: (f: (prev: WorkspaceMember[]) => WorkspaceMember[]) => void
  assignableRoles: RoleOption[]
  textFilter: string
  roleFilter: Role
}

export type SortKey = "username" | "company" | "role"

export type SortOption = {
  key: SortKey
  order: "asc" | "desc"
}

const styles: StylesConfig<RoleOption> = {
  control: (styles) => ({ ...styles, width: 200 }),
}

const MembersTables = styled.table`
  &.ui.striped.table {
    margin-top: 20px;
  }
`

export const MembersTable: React.FC<MembersTableProps> = React.memo((props) => {
  const [sortOption, setSortOption] = useState<SortOption>({ key: "username", order: "asc" })
  const [memberIdUpdatingRole, setMemberIdUpdatingRole] = useState<string | undefined>(undefined)

  const filteredMembers = useMemo(
    () =>
      props.members.filter((x) => {
        const statusCondition = x.status === "active"
        const textCondition =
          !props.textFilter || x.username.includes(props.textFilter) || x.company.includes(props.textFilter)
        const roleCondition = props.roleFilter === "all" || x.role === props.roleFilter
        return statusCondition && textCondition && roleCondition
      }),
    [props.members, props.textFilter, props.roleFilter]
  )
  const sortedActiveMembers = useMemo(() => {
    return [...filteredMembers].sort((a, b) => {
      const ascSortValue = ((sortKey: SortKey) => {
        switch (sortKey) {
          case "username":
            return a.username.localeCompare(b.username, "ja-JP")
          case "company":
            return a.company.localeCompare(b.company, "ja-JP")
          case "role":
            return a.role.localeCompare(b.role, "ja-JP")
          default:
            return a.username.localeCompare(b.username, "ja-JP")
        }
      })(sortOption.key)
      return sortOption.order === "asc" ? ascSortValue : -ascSortValue
    })
  }, [filteredMembers, sortOption])

  const updateRole = useCallback(
    async (role, member: WorkspaceMember) => {
      if (props.workspaceId === null) {
        return
      }
      setMemberIdUpdatingRole(member.workspaceMemberId)
      const workspaceTags = await clients.workspaceTag.getWorkspaceMemberTags(
        props.workspaceId,
        member.workspaceMemberId
      )
      const body: m.PutWorkspaceMemberRequest = {
        role: role.value,
        workspaceTags: workspaceTags.map((x) => x.workspaceTagId),
        lastUpdatedDatetime: member.updatedDatetime,
      }
      clients.workspaceMember
        .putWorkspaceMember(props.workspaceId, member.workspaceMemberId, body)
        .then((member) => {
          props.setMembers((members: WorkspaceMember[]) =>
            members.map((x: WorkspaceMember) => {
              return x.workspaceMemberId === member.workspaceMemberId
                ? { ...x, role: member.role, updatedDatetime: member.updatedDatetime.toISOString() }
                : x
            })
          )
        })
        .catch((error: Error) => {
          window.alert(`役割の更新に失敗しました: ${error.message}`)
        })
        .finally(() => setMemberIdUpdatingRole(undefined))
    },
    [props]
  )

  const memberElements = useMemo(() => {
    return sortedActiveMembers.map((member) => {
      return (
        <tr key={member.workspaceMemberId}>
          <td className="single line">
            <Link to={`./members/${member.workspaceMemberId}`}>{member.username}</Link>
          </td>
          <td className="single line">{member.company}</td>
          <td className="single line">
            {props.isOwner && props.myWorkspaceMemberId !== member.workspaceMemberId ? (
              <ReactSelect
                options={props.assignableRoles}
                styles={styles}
                onChange={(x) => updateRole(x, member)}
                value={props.assignableRoles.find((assignableRole) => assignableRole.value === member.role)}
                isLoading={memberIdUpdatingRole === member.workspaceMemberId}
              />
            ) : (
              props.assignableRoles.find((assignableRole) => assignableRole.value === member.role)?.label
            )}
          </td>
        </tr>
      )
    })
  }, [
    sortedActiveMembers,
    memberIdUpdatingRole,
    updateRole,
    props.isOwner,
    props.myWorkspaceMemberId,
    props.assignableRoles,
  ])

  const toggleSortKey = useCallback(
    (newSortKey: SortKey) => {
      if (sortOption.key === newSortKey && sortOption.order === "asc") {
        setSortOption({ key: newSortKey, order: "desc" })
      } else {
        setSortOption({ key: newSortKey, order: "asc" })
      }
    },
    [sortOption]
  )
  const getSortIcon = useCallback(
    (sortKey: SortKey) => {
      if (sortKey !== sortOption.key) {
        return "grey sort icon"
      }
      return sortOption.order === "asc" ? "red up sort icon" : "red down sort icon"
    },
    [sortOption]
  )

  return (
    <MembersTables className="ui striped table">
      <thead>
        <tr>
          <th className="single line six wide" onClick={() => toggleSortKey("username")}>
            ユーザー名<i className={getSortIcon("username")}></i>
          </th>
          <th className="single line six wide" onClick={() => toggleSortKey("company")}>
            会社名<i className={getSortIcon("company")}></i>
          </th>
          <th className="single line four wide" onClick={() => toggleSortKey("role")}>
            役割<i className={getSortIcon("role")}></i>
          </th>
        </tr>
      </thead>
      <tbody>{memberElements}</tbody>
    </MembersTables>
  )
})

export default MembersTable
