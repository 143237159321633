import { WorkspaceApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { Workspace, PutWorkspaceRequest } from "model"

export class WorkspaceApiClientMock implements WorkspaceApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  putWorkspace = async (workspaceId: string, body: PutWorkspaceRequest): Promise<Workspace> => {
    return {
      workspaceId: workspaceId,
      workspaceName: body.workspaceName,
      email: body.email,
      createdDatetime: new Date(),
      updatedDatetime: new Date(),
    }
  }
}
