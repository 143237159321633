import { ExpectedWorkingTimeApiClient } from "api"
import { members } from "api/mock/workspaceMemberApiClientMock"
import { DateOnly, ExpectedWorkingTime, WorkspaceMember, PutExpectedWorkingTimeRequest } from "model"
import { dayms, dayString } from "utils/date"

const orgId = "kurusugawa"
const pastTime = new Date("2020-01-01T12:00:00Z")
const today = DateOnly.fromDateAsUTC(new Date())

function createExpectedWorkingTime(member: WorkspaceMember, date: string, hour: number): ExpectedWorkingTime {
  return {
    workspaceId: orgId,
    workspaceMemberId: member.workspaceMemberId,
    date: DateOnly.fromString(date),
    expectedWorkingHours: hour,
    createdDatetime: pastTime,
    updatedDatetime: pastTime,
  }
}

function createExpectedWorkingTimes(member: WorkspaceMember, termStart: DateOnly, days = 1000): ExpectedWorkingTime[] {
  return Array.from({ length: days }).flatMap((x, i) => {
    const date = new Date(Math.floor((termStart.utcZeroHourDate.getTime() + i * dayms) / dayms) * dayms)
    const hours = date.getDay() === 6 ? 1 : date.getDay() === 0 ? undefined : 8
    return hours ? [createExpectedWorkingTime(member, dayString(date), hours)] : []
  })
}

export class ExpectedWorkingTimeApiClientMock implements ExpectedWorkingTimeApiClient {
  list = async (workspaceId: string, termStart: string, termEnd: string): Promise<ExpectedWorkingTime[]> => {
    console.log(`ExpectedWorkingTimeApiClientMock.list(${workspaceId}, ${termStart}, ${termEnd})`)
    const expecteds: ExpectedWorkingTime[] = members.flatMap((m) =>
      createExpectedWorkingTimes(m, DateOnly.fromString(termStart))
    )
    const fixExpectedWorkingTimeTestMember = members.find((m) => m.workspaceMemberId === "k")
    const fixExpectedWorkingTimeTest = fixExpectedWorkingTimeTestMember
      ? [createExpectedWorkingTime(fixExpectedWorkingTimeTestMember, today.toISOString(), 6.6666666667)]
      : []
    return [...expecteds, ...fixExpectedWorkingTimeTest]
  }

  getExpectedWorkingTimesByWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    termStart?: string,
    termEnd?: string
  ): Promise<ExpectedWorkingTime[]> => {
    console.log(
      `ExpectedWorkingTimeApiClientMock.getExpectedWorkingTimesByWorkspaceMember(${workspaceId}, ${workspaceMemberId}, ${
        termStart ?? "undefined"
      }, ${termEnd ?? "undefined"})`
    )
    const workspaceMember: WorkspaceMember = {
      workspaceMemberId: workspaceMemberId,
      workspaceId: workspaceId,
      accountId: workspaceMemberId,
      userId: workspaceMemberId,
      username: workspaceMemberId,
      role: "worker",
      status: "active",
      createdDatetime: pastTime,
      updatedDatetime: pastTime,
    }
    return createExpectedWorkingTimes(workspaceMember, DateOnly.fromString(termStart ?? new Date().toISOString()), 14)
  }

  putExpectedWorkingTimeByWorkspaceMember = async (
    request: PutExpectedWorkingTimeRequest
  ): Promise<ExpectedWorkingTime> => {
    console.log(
      `ExpectedWorkingTimeApiClientMock.putExpectedWorkingTimeByWorkspaceMember(${request.workspaceId}, ${
        request.workspaceMemberId
      }, ${request.date.toISOString()}, ${request.expectedWorkingHours})`
    )
    const result: ExpectedWorkingTime = {
      ...request,
      createdDatetime: request.createdDatetime ?? new Date(),
      updatedDatetime: new Date(),
    }
    return Promise.resolve(result)
  }

  deleteExpectedWorkingTimeByWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    date: string
  ): Promise<ExpectedWorkingTime> => {
    console.log(
      `ExpectedWorkingTimeApiClientMock.deleteExpectedWorkingTimeByWorkspaceMember(${workspaceId}, ${workspaceMemberId}, ${date})`
    )
    return Promise.resolve({
      workspaceId,
      workspaceMemberId,
      date: DateOnly.fromString(date),
      expectedWorkingHours: 8,
      createdDatetime: new Date(),
      updatedDatetime: new Date(),
    })
  }
}
