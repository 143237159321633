import { JobApiClient, JobSortKey } from "api"
import { HttpApiClient } from "api/httpApiClient"
import * as m from "model"

interface JobResponse {
  workspaceId: string
  jobId: string
  jobName: string
  parentJobId: string
  jobTree: string
  status: "unarchived" | "archived"
  targetHours: number
  note: string
  externalLinkageInfo: m.JobExternalLinkageInfo
  createdDatetime: string
  updatedDatetime: string
}

const toJob = (data: JobResponse): m.Job =>
  Object.assign(data, {
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as m.Job

export class JobApiClientImpl implements JobApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  getJob = async (workspaceId: string, jobId: string): Promise<m.Job> => {
    const path = `/api/v1/workspaces/${workspaceId}/jobs/${jobId}`
    const res = await this.client.getWithAuth<JobResponse>(path)
    return toJob(res.data)
  }

  putJob = async (workspaceId: string, jobId: string, request: m.PutJobRequest): Promise<m.Job> => {
    const path = `/api/v1/workspaces/${workspaceId}/jobs/${jobId}`
    const res = await this.client.putWithAuth<JobResponse>(path, request)
    return toJob(res.data)
  }

  deleteJob = async (workspaceId: string, jobId: string): Promise<m.Job> => {
    const path = `/api/v1/workspaces/${workspaceId}/jobs/${jobId}`
    const res = await this.client.deleteWithAuth<JobResponse>(path)
    return toJob(res.data)
  }

  getChildren = async (workspaceId: string, jobId: string): Promise<m.Job[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/jobs/${jobId}/children`
    const res = await this.client.getWithAuth<JobResponse[]>(path)
    return res.data.map(toJob)
  }

  list = async (workspaceId: string, sortKeys: JobSortKey[] = []): Promise<m.Job[]> => {
    const param = sortKeys.length === 0 ? undefined : new Map<string, string>([["sort", sortKeys.join(",")]])
    const path = `/api/v1/workspaces/${workspaceId}/jobs`
    const res = await this.client.getWithAuth<JobResponse[]>(path, param)
    return res.data.map(toJob)
  }
}
