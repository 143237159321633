import { css } from "linaria"
import { styled } from "linaria/react"
import React, { useEffect, useMemo, useState, useCallback } from "react"
import {
  Button,
  Dropdown,
  DropdownProps,
  Grid,
  Input,
  InputOnChangeData,
  Message,
  Popup,
  Container,
} from "semantic-ui-react"
import { v4 as uuidv4 } from "uuid"

import { clients } from "api/clients"
import DatePicker from "component/common/DatePicker"
import ActualWorkingTimeList from "component/common/actualWorkingTimeList"
import { HistoriesStore } from "dataStore/historiesStore"
import * as m from "model"
import * as time from "utils/time"

const RootContainer = styled.div`
  background-color: #eee;
  padding-top: 30px;
`
const StyledContainer = styled(Container)`
  &&&&&& {
    margin: 30px;
    margin-top: 0px;
    width: initial;
  }
`
const ActualWorkingTimeListContainer = styled.div`
  margin-top: 30px;
  margin-bottom: 0px;
`
const TimeRegisterGrid = styled(Grid)`
  &&& {
    background: rgb(255, 255, 255);
    margin: 0px;
  }
`
const InnerItem = styled.div`
  padding: 0px 15px;
  padding-bottom: 15px;
`
const LabelText = styled.div`
  text-align: left;
`
const LineDivider = styled.span`
  &:before {
    content: "-";
    padding: 3px;
  }
`
const inlineItemClassName = css`
  display: inline-block;
`
const actualTimeClassName = css`
  width: 71px;
`
const actualNoteClassName = css`
  width: 445px;
`

const now = new Date()
const today = m.DateOnly.fromDateAsLocal(now)

function distinct<T>(xs: T[]): T[] {
  const result: T[] = []
  xs.reduce((acc, x) => {
    if (acc.has(x)) return acc
    result.push(x)
    return acc.add(x)
  }, new Set<T>())
  return result
}

function sortJobs(jobs: m.Job[], histories: string[]): m.Job[] {
  const historyMap = histories.reduce((acc, jobId, i) => acc.set(jobId, i), new Map<string, number>())
  return jobs.sort((x, y) => {
    const history =
      (historyMap.get(x.jobId) ?? Number.MAX_SAFE_INTEGER) - (historyMap.get(y.jobId) ?? Number.MAX_SAFE_INTEGER)
    if (history === 0) return x.jobName.localeCompare(y.jobName)
    else return history
  })
}

export type ActualWorkingTimePageProps = {
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
}

export const ActualWorkingTimePage: React.FC<ActualWorkingTimePageProps> = (props) => {
  const [requiresLoadHistories, setRequiresLoadHistories] = useState(true)
  const [requiresLoadActualWorkingTimes, setRequiresLoadActualWorkingTimes] = useState(true)
  const [allJobs, setAllJobs] = useState<m.Job[]>([])
  const [unarchivedJobs, setUnarchivedJobs] = useState<m.Job[]>([])
  const [projectDropdownList, setProjectDropdownList] = useState<m.Job[]>([])
  const [jobDropdownList, setJobDropdownList] = useState<m.Job[]>([])
  const [project, setProject] = useState<m.Job | null>(null)
  const [job, setJob] = useState<m.Job | null>(null)
  const [actualDate, setActualDate] = useState<m.DateOnly | null>(today)
  const [actualStart, setActualStart] = useState<number>(time.dateToMsWithinDay(now))
  const [actualInterval, setActualInterval] = useState<number>(0)
  const [actualNote, setActualNote] = useState<string>("")
  const [actualStartValue, setActualStartValue] = useState<string>(time.dateToTimeString(now))
  const [actualEndValue, setActualEndValue] = useState<string>(time.dateToTimeString(now))
  const [actualIntervalValue, setActualIntervalValue] = useState<string>("00:00")
  const [actualError, setActualError] = useState<string | null>(null)
  const [addingActual, setAddingActual] = useState(false)

  const historiesStore = useMemo(
    () => (props.workspaceId ? new HistoriesStore(props.workspaceId) : null),
    [props.workspaceId]
  )
  const actualEndWithinDay = useMemo(
    () => time.fixWithinDay(actualStart + actualInterval),
    [actualInterval, actualStart]
  )

  const updateJobs = useCallback(
    (project: m.Job) => {
      const children = sortJobs(
        unarchivedJobs.filter((job) => m.getProjectId(job) === project.jobId && !m.isProject(job)),
        historiesStore?.jobHistories ?? []
      )
      setJobDropdownList(children)
    },
    [historiesStore, unarchivedJobs]
  )

  const onChangeProject = useCallback(
    (e, data: DropdownProps) => {
      if (data.value) {
        const projectId: string = data.value.toString()
        const project = unarchivedJobs.find((job) => job.jobId === projectId) ?? null
        setProject(project)
        if (project) updateJobs(project)
      }
    },
    [unarchivedJobs, updateJobs]
  )

  const onChangeJob = useCallback(
    (e, data: DropdownProps) => {
      if (data.value) {
        const jobId: string = data.value.toString()
        setJob(unarchivedJobs.find((job) => job.jobId === jobId) ?? null)
      }
    },
    [unarchivedJobs]
  )

  const onChangeActualStart = useCallback((e, data: InputOnChangeData) => setActualStartValue(data.value), [])

  const onChangeActualEnd = useCallback((e, data: InputOnChangeData) => setActualEndValue(data.value), [])

  const onChangeActualInterval = useCallback((e, data: InputOnChangeData) => setActualIntervalValue(data.value), [])

  const onChangeActualNote = useCallback((e, data: InputOnChangeData) => setActualNote(data.value), [])

  const onBlurActualStart = useCallback(() => {
    const ms = time.timeStringToMs(actualStartValue, true) ?? actualStart
    const interval = time.fixWithinDay(actualEndWithinDay - ms)
    setActualStart(ms)
    setActualStartValue(time.msToTimeString(ms))
    setActualInterval(interval)
    setActualIntervalValue(time.msToTimeString(interval))
  }, [actualEndWithinDay, actualStart, actualStartValue])

  const onBlurActualEnd = useCallback(() => {
    const ms = time.timeStringToMs(actualEndValue, true) ?? actualEndWithinDay
    const interval = time.fixWithinDay(ms - actualStart)
    setActualEndValue(time.msToTimeString(ms))
    setActualInterval(interval)
    setActualIntervalValue(time.msToTimeString(interval))
  }, [actualEndWithinDay, actualEndValue, actualStart])

  const onBlurActualInterval = useCallback(() => {
    const ms = time.timeStringToMs(actualIntervalValue, true) ?? actualInterval
    const endMs = time.fixWithinDay(actualStart + ms)
    setActualInterval(ms)
    setActualIntervalValue(time.msToTimeString(ms))
    setActualEndValue(time.msToTimeString(endMs))
  }, [actualInterval, actualIntervalValue, actualStart])

  const addActualWorkingTime = useCallback(
    (workspaceId: string, workspaceMember: m.WorkspaceMember, project: m.Job, job: m.Job, actualDate: m.DateOnly) => {
      if (historiesStore) {
        const projectHistory = distinct([project.jobId, ...historiesStore.projectHisotries])
        historiesStore.projectHisotries = projectHistory
        const jobHistory = distinct([job.jobId, ...historiesStore.jobHistories])
        historiesStore.jobHistories = jobHistory
      }

      const actualDateMs = actualDate.toZeroHourLocalDate().getTime()
      const actualEndDate = new Date(actualDateMs + actualStart + actualInterval)
      const dateOnlyActualEndDate = m.DateOnly.fromDateAsLocal(actualEndDate)
      // 現状は、当日内なら未来の時間帯でも登録可能にしている。
      // 将来的にストップウォッチ形式のUIを提供するタイミングで、当日内であったとしても未来の実績は登録できないようにしたい。
      // 経緯: https://kurusugawajp.slack.com/archives/CDU2BF9BL/p1648553921128679
      if (today.utcZeroHourDate.getTime() < dateOnlyActualEndDate.utcZeroHourDate.getTime()) {
        setActualError("未来の日付の実績は登録できません")
        return
      }
      setActualError(null)
      const request: m.PutActualWorkingTimeRequest = {
        actualWorkingTimeId: uuidv4(),
        workspaceId: workspaceId,
        workspaceMemberId: workspaceMember.workspaceMemberId,
        jobId: job.jobId,
        startDatetime: new Date(actualDateMs + actualStart),
        endDatetime: actualEndDate,
        note: actualNote === "" ? undefined : actualNote,
      }
      setAddingActual(true)
      clients.actualWorkingTime.put(request).then(() => {
        setAddingActual(false)
        setRequiresLoadActualWorkingTimes(true)
      })
    },
    [actualInterval, actualNote, actualStart, historiesStore]
  )

  const onClickAddActualButton = (): void => {
    if (addingActual) return
    if (!project) setActualError("プロジェクトを選択してください")
    else if (!job) setActualError("ジョブを選択してください")
    else if (!actualDate) setActualError("日付が不正です")
    else if (actualInterval === 0) setActualError("作業時間は1分以上必要です")
    else if (props.workspaceId != null && props.workspaceMember != null)
      addActualWorkingTime(props.workspaceId, props.workspaceMember, project, job, actualDate)
  }

  const projectDropdownOptions = useMemo(
    () =>
      projectDropdownList.map((project) => ({
        key: project.jobId,
        text: project.jobName,
        value: project.jobId,
        content: <Popup content={project.jobName} mouseEnterDelay={500} trigger={<div>{project.jobName}</div>} />,
      })),
    [projectDropdownList]
  )

  const jobDropdownOptions = useMemo(
    () =>
      jobDropdownList.map((job) => ({
        key: job.jobId,
        text: job.jobName,
        value: job.jobId,
        content: <Popup content={job.jobName} mouseEnterDelay={500} trigger={<div>{job.jobName}</div>} />,
      })),
    [jobDropdownList]
  )

  // ジョブと実績の読み込み
  useEffect(() => {
    // ログインしてない場合や表示権限がない場合はヘッダーにて別画面に遷移する前提
    if (props.workspaceId) {
      setProject(null)
      setJob(null)
      clients.job.list(props.workspaceId).then((allJobs) => {
        setAllJobs(allJobs)
        const jobs = allJobs.filter(m.isInProgress)
        setUnarchivedJobs(jobs)
        setRequiresLoadHistories(true)
      })
      setRequiresLoadActualWorkingTimes(true)
    }
  }, [props.workspaceId])

  // 選択したプロジェクトとジョブの履歴の読み込み
  useEffect(() => {
    if (requiresLoadHistories) {
      const projects = sortJobs(
        unarchivedJobs.filter((job) => m.isProject(job)),
        historiesStore?.projectHisotries ?? []
      )
      setProjectDropdownList(projects)

      // 直近で選択したプロジェクトとジョブのうちまだunarchivedのものを初期の選択とする
      // 存在しなければ直近で選択したプロジェクトを初期の選択とする
      const resentJobId = historiesStore?.jobHistories.find((jobId) =>
        unarchivedJobs.some((job) => job.jobId === jobId)
      )
      const resentJob = unarchivedJobs.find((job) => job.jobId === resentJobId)
      const resentProjectId = resentJob ? m.getProjectId(resentJob) : undefined
      const resentProject = unarchivedJobs.find((job) => job.jobId === resentProjectId)
      if (resentProject && resentJob) {
        setProject(resentProject)
        updateJobs(resentProject)
        setJob(resentJob)
      } else if (projects.length > 0) {
        const resentProjectId = historiesStore?.projectHisotries.find((projectId) =>
          unarchivedJobs.some((job) => job.jobId === projectId)
        )
        const resentProject = projects.find((project) => project.jobId === resentProjectId)
        setProject(resentProject ?? null)
        if (resentProject) {
          updateJobs(resentProject)
          setJob(null)
        } else setJobDropdownList([])
      }
      setRequiresLoadHistories(false)
    }
  }, [historiesStore, requiresLoadHistories, unarchivedJobs, updateJobs])

  return (
    <RootContainer>
      <StyledContainer>
        <TimeRegisterGrid verticalAlign="middle">
          <Grid.Row>
            <Grid.Column width={7}>
              <InnerItem>
                <div>
                  <span className="ui small text gray">プロジェクト</span>
                </div>
                <Dropdown
                  placeholder="プロジェクト"
                  fluid
                  search
                  selection
                  options={projectDropdownOptions}
                  value={project?.jobId ?? ""}
                  onChange={onChangeProject}
                />
              </InnerItem>
              <InnerItem>
                <span className="ui small text gray">ジョブ</span>
                <Dropdown
                  placeholder="ジョブ"
                  fluid
                  search
                  selection
                  options={jobDropdownOptions}
                  value={job?.jobId ?? ""}
                  onChange={onChangeJob}
                />
              </InnerItem>
            </Grid.Column>
            <Grid.Column width={7} textAlign="right">
              <InnerItem className={inlineItemClassName}>
                <LabelText>
                  <span className="ui small text gray">日付</span>
                </LabelText>
                <DatePicker
                  value={actualDate?.toISOString()?.replaceAll("-", "/") ?? ""}
                  onChange={(e, { value }) => setActualDate(m.DateOnly.fromString(value.replaceAll("/", "-")))}
                  placeholder="日付"
                  readOnly
                />
              </InnerItem>
              <InnerItem className={inlineItemClassName}>
                <div className={inlineItemClassName}>
                  <LabelText>
                    <span className="ui small text gray">開始</span>
                  </LabelText>
                  <div className={inlineItemClassName}>
                    <Input
                      placeholder="開始"
                      className={actualTimeClassName}
                      onChange={onChangeActualStart}
                      onBlur={onBlurActualStart}
                      value={actualStartValue}
                    />
                  </div>
                </div>
                <LineDivider className={inlineItemClassName}></LineDivider>
                <div className={inlineItemClassName}>
                  <LabelText>
                    <span className="ui small text gray">終了</span>
                  </LabelText>
                  <div className={inlineItemClassName}>
                    <Input
                      placeholder="終了"
                      className={actualTimeClassName}
                      onChange={onChangeActualEnd}
                      onBlur={onBlurActualEnd}
                      value={actualEndValue}
                    />
                  </div>
                </div>
              </InnerItem>
              <InnerItem className={inlineItemClassName}>
                <LabelText>
                  <span className="ui small text gray">時間</span>
                </LabelText>
                <Input
                  placeholder="時間"
                  className={actualTimeClassName}
                  onChange={onChangeActualInterval}
                  onBlur={onBlurActualInterval}
                  value={actualIntervalValue}
                />
              </InnerItem>
              <InnerItem className={inlineItemClassName}>
                <LabelText>
                  <span className="ui small text gray">備考</span>
                </LabelText>
                <Input
                  className={actualNoteClassName}
                  placeholder="備考 (省略可能)"
                  onChange={onChangeActualNote}
                  value={actualNote}
                />
              </InnerItem>
            </Grid.Column>
            <Grid.Column width={2} textAlign="center">
              <Button color="olive" size="large" onClick={onClickAddActualButton}>
                追加
              </Button>
            </Grid.Column>
          </Grid.Row>
          {actualError ? (
            <Grid.Row>
              <Grid.Column>
                <Message negative>
                  <Message.Header>{actualError ?? ""}</Message.Header>
                </Message>
              </Grid.Column>
            </Grid.Row>
          ) : (
            <></>
          )}
        </TimeRegisterGrid>
        <ActualWorkingTimeListContainer>
          <ActualWorkingTimeList
            workspaceId={props.workspaceId}
            workspaceMember={props.workspaceMember}
            jobs={allJobs}
            requiresLoadActualWorkingTimes={requiresLoadActualWorkingTimes}
            setRequiresLoadActualWorkingTimes={setRequiresLoadActualWorkingTimes}
            historiesStore={historiesStore ?? undefined}
          />
        </ActualWorkingTimeListContainer>
      </StyledContainer>
    </RootContainer>
  )
}

export default ActualWorkingTimePage
