import moment from "moment-timezone"

import { dayms, dayString, toDateOnlyUnixtimeMs } from "utils/date"
import { PartiallyPartial } from "utils/types"

export type Locale = "ja-JP" | "en-US"
export type AccountExternalLinkageInfo = {
  annofab?: { accountId: string }
}

export class DateOnly {
  private constructor(readonly utcZeroHourDate: Date) {}
  static fromDateAsUTC(date: Date): DateOnly {
    return this.fromUnixtimeMs(date.getTime())
  }
  static fromDateAsLocal(date: Date): DateOnly {
    const offsetUnixtimeMs = date.getTime() - date.getTimezoneOffset() * 60 * 1000
    return this.fromUnixtimeMs(offsetUnixtimeMs)
  }
  static fromUnixtimeMs(unixtimeMs: number): DateOnly {
    return new DateOnly(new Date(toDateOnlyUnixtimeMs(unixtimeMs)))
  }
  static fromString(dateString: string): DateOnly {
    return new DateOnly(new Date(dateString.split("T")[0]))
  }
  toZeroHourDate(timezone: string): Date {
    return moment(
      {
        year: this.utcZeroHourDate.getUTCFullYear(),
        month: this.utcZeroHourDate.getUTCMonth(),
        day: this.utcZeroHourDate.getUTCDate(),
      },
      timezone
    ).toDate()
  }
  toZeroHourLocalDate(): Date {
    return new Date(
      this.utcZeroHourDate.getUTCFullYear(),
      this.utcZeroHourDate.getUTCMonth(),
      this.utcZeroHourDate.getUTCDate()
    )
  }
  toISOString(): string {
    return dayString(this.utcZeroHourDate)
  }
  add(days: number): DateOnly {
    return DateOnly.fromUnixtimeMs(this.utcZeroHourDate.getTime() + days * dayms)
  }
  diff(other: DateOnly): number {
    return Math.abs(this.utcZeroHourDate.getTime() - other.utcZeroHourDate.getTime()) / dayms
  }
}

export interface Account {
  accountId: string
  userId: string
  username: string
  email: string
  locale: Locale
  authority: "user" | "admin"
  externalLinkageInfo: AccountExternalLinkageInfo
  createdDatetime: Date
  updatedDatetime: Date
}

export interface PutMyAccountRequest {
  username: string
  userId: string
  locale: Locale
  lastUpdatedDatetime: string
  externalLinkageInfo: AccountExternalLinkageInfo
}

export interface Workspace {
  workspaceId: string
  workspaceName: string
  email: string
  createdDatetime: Date
  updatedDatetime: Date
}

export interface PutWorkspaceRequest {
  workspaceName: string
  email: string
  lastUpdatedDatetime?: string
}

export type WorkspaceMemberId = string
export type Role = "worker" | "manager" | "owner"
export type WorkspaceMemberStatus = "active" | "inactive" | "waiting_response"
export interface WorkspaceMember {
  workspaceMemberId: WorkspaceMemberId
  workspaceId: string
  accountId: string
  userId: string
  username: string
  role: Role
  status: WorkspaceMemberStatus
  inactivatedDatetime?: Date
  createdDatetime: Date
  updatedDatetime: Date
}
export interface PutWorkspaceMemberRequest {
  userId?: string
  role: Role
  workspaceTags: string[]
  lastUpdatedDatetime?: string
}

export interface WorkspaceTag {
  workspaceTagId: string
  workspaceId: string
  workspaceTagName: string
  createdDatetime: string
  updatedDatetime: string
}
export const companyTagPrefix = "company:"

export type JobId = string
export type ProjectId = string
export type JobStatus = "unarchived" | "archived"
export interface Job {
  workspaceId: string
  jobId: string
  jobName: string
  jobTree: string
  status: JobStatus
  targetHours?: number
  note?: string
  externalLinkageInfo: JobExternalLinkageInfo
  createdDatetime: Date
  updatedDatetime: Date
}

export const isInProgress = (job: Job): boolean => {
  return job.status === "unarchived"
}
export const isArchived = (job: Job): boolean => {
  return job.status === "archived"
}
export const isProject = (job: Job): boolean => {
  return job.jobTree === `${job.workspaceId}/${job.jobId}`
}
export const getProjectId = (job: Job): ProjectId => {
  const treeJobIds = job.jobTree.split("/")
  // 最初の項目にはワークスペースIDが入っているため次の項目がルートジョブ (プロジェクト)
  return treeJobIds[1]
}

export interface JobExternalLinkageInfo {
  url?: string
}

export interface PutJobRequest {
  jobName: string
  parentJobId?: string
  status: JobStatus
  targetHours?: number
  note?: string
  externalLinkageInfo: JobExternalLinkageInfo
  lastUpdatedDatetime?: Date
}

export interface ExpectedWorkingTime {
  workspaceId: string
  workspaceMemberId: WorkspaceMemberId
  date: DateOnly
  expectedWorkingHours: number
  createdDatetime: Date
  updatedDatetime: Date
}

export type PutExpectedWorkingTimeRequest = PartiallyPartial<ExpectedWorkingTime, "createdDatetime" | "updatedDatetime">

export interface ActualWorkingTime {
  workspaceId: string
  actualWorkingTimeId: string
  jobId: string
  workspaceMemberId: WorkspaceMemberId
  startDatetime: Date
  endDatetime: Date
  note?: string
  createdDatetime: Date
  updatedDatetime: Date
}

export type PutActualWorkingTimeRequest = PartiallyPartial<ActualWorkingTime, "createdDatetime" | "updatedDatetime">

export interface Schedule {
  scheduleId: string
  workspaceId: string
  jobId: string
  workspaceMemberId: WorkspaceMemberId
  startDate: DateOnly
  endDate: DateOnly
  type: "hours" | "percentage"
  value: number
  createdDatetime: Date
  updatedDatetime: Date
}

export type PutScheduleRequest = PartiallyPartial<Schedule, "createdDatetime" | "updatedDatetime">

export type SumOfTimes = Record<string, Record<string, number>>

export type RootJobSumParent = {
  total: number
  children: Map<string, number>
}

export type RootJobSum = Map<string, RootJobSumParent>

export type LogLevel = "INFO" | "WARN" | "ERROR"

export type PostLogRequest = {
  clientDatetime: Date
  logLevel: LogLevel
  message: string
  stacktrace?: string
  environment?: Record<string, string>
}
