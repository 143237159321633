import { dayms } from "utils/date"

const localTimezoneOffset = new Date().getTimezoneOffset()
export const localTimezoneOffsetMs = localTimezoneOffset * 60 * 1000

const hourToMillis = (h: number): number => h * 60 * 60 * 1000
const minuteToMillis = (m: number): number => m * 60 * 1000

export const fixWithinDay = (ms: number): number => {
  let result = ms
  while (result < 0) result += dayms
  return result % dayms
}

export const timeStringToMs = (ts: string, fixesWithinDay?: boolean): number | undefined => {
  const withColonRegExp = /^(\d+):(\d{1,2})?$/
  const withoutColonRegExp = /^(\d{1,2})(\d{2})?$/
  const matchResult = (ts.match(withColonRegExp) ?? ts.match(withoutColonRegExp))?.slice(1)
  if (!matchResult) {
    return undefined
  }
  const [hour, minute] = matchResult.map((s) => (s == null || s === "" ? 0 : parseInt(s, 10)))
  const result = hourToMillis(hour) + minuteToMillis(minute)
  return fixesWithinDay ?? false ? fixWithinDay(result) : result
}

export const msToTimeString = (ms: number): string => {
  const minuteTime = Math.round(fixWithinDay(ms) / 60 / 1000)
  const hour = `${Math.floor(minuteTime / 60)}`
  const minute = `${minuteTime % 60}`
  return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
}

export const dateToTimeString = (date: Date): string => {
  const hour = `${date.getHours()}`
  const minute = `${date.getMinutes()}`
  return `${hour.padStart(2, "0")}:${minute.padStart(2, "0")}`
}

export const dateToMsWithinDay = (date: Date): number => {
  const hour = date.getHours()
  const minute = date.getMinutes()
  return (hour * 60 + minute) * 60 * 1000
}
