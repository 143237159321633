import React, { useCallback, useState } from "react"

import "component/page/signUp/index.css"
import { clients } from "api/clients"

export type SignUpPageProps = {
  // no prop
}

export const SignUpPage: React.FC<SignUpPageProps> = (props) => {
  const [email, setEmail] = useState("")
  const [errorMessage, setErrorMessage] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [isSendMail, setIsSendMail] = useState(false)

  const signUp = useCallback(async (): Promise<void> => {
    setErrorMessage("")
    setIsSendMail(true)
    await clients.account
      .postSignUp({ email: email })
      .then(() => {
        setIsSuccess(true)
      })
      .catch((error) => {
        setIsSendMail(false)
        //エラーメッセージを画面に表示
        setErrorMessage(error.message)
      })
  }, [email])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
  }, [])

  return (
    <div className="ui container">
      <h1 className="ui header" style={{ marginTop: "0.5em", textAlign: "center" }}>
        アカウント登録
      </h1>
      <div className="aw-sign-up-form">
        <form className="ui form" onSubmit={(e) => e.preventDefault()}>
          <div className="field">
            <label>メールアドレス</label>
            <div className="ui left icon input">
              <i className="mail icon" />
              <input
                type="email"
                name="email"
                placeholder="例) taro@example.jp"
                value={email}
                onChange={handleChange}
              />
            </div>
            <div className="ui small pointing label">
              <div>
                <b>*@annowork.com</b>からメールを受信できるように、迷惑メールフィルタを設定してください。
              </div>
            </div>
          </div>
          <button
            className="ui olive button"
            type="submit"
            onClick={signUp}
            disabled={isSendMail || email.length === 0}
          >
            確認メールを送信する
          </button>
        </form>
      </div>
      <div className="aw-sign-up-error">
        <span className="ui red text">{errorMessage}</span>
      </div>
      <div className="aw-sign-up-success" style={{ display: isSuccess ? "" : "none" }}>
        <span className="ui text">
          上記アドレスにメールを送信したので、ご確認ください。
          <br />
          このページを閉じてください。
        </span>
      </div>
    </div>
  )
}

export default SignUpPage
