import { styled } from "linaria/react"
import React, { useMemo, useCallback, useState } from "react"
import { Link } from "react-router-dom"

import Report from "component/page/reports/index"
import * as m from "model"

import "moment/locale/ja"

const SummaryTr = styled.tr`
  font-weight: bold;
`

export type MemberStatusFilter = m.WorkspaceMemberStatus | "all"
export type SortKey =
  | "username"
  | "company"
  | "status"
  | "expected-working-hours"
  | "assigned-working-hours"
  | "actual-working-hours"
  | "not-worked-hours"
  | "assignable-working-hours"
export type SortOption = {
  key: SortKey
  order: "asc" | "desc"
}

export interface Report {
  workspaceMemberId: string
  workspaceId: string
  username: string
  status: m.WorkspaceMemberStatus
  company: string
  expectedWorkingHours: number
  assignedWorkingHours: number
  actualWorkingHours: number
  notWorkedHours: number
  assignableWorkingHours: number
}

interface SummaryReport {
  totalExpectedWorkingHours: number
  totalAssignedWorkingHours: number
  totalActualWorkingHours: number
  totalNotWorkedHours: number
  totalAssignableWorkingHours: number
}

type ReportsTableProps = {
  reports: Report[]
  memberStatusFilter: MemberStatusFilter
}

export const roundReport = (x: number): number => {
  // レポートは少数第二位まで表示する
  return Math.round(x * 100) / 100
}

export const ReportsTable: React.FC<ReportsTableProps> = React.memo((props) => {
  const [sortOption, setSortOption] = useState<SortOption>({ key: "username", order: "asc" })

  const filteredReports = useMemo(() => {
    return props.reports.filter((x) => props.memberStatusFilter === "all" || props.memberStatusFilter === x.status)
  }, [props.reports, props.memberStatusFilter])
  const sortedReports = useMemo(() => {
    return [...filteredReports].sort((a, b) => {
      const ascSortValue = ((sortKey: SortKey) => {
        switch (sortKey) {
          case "username":
            return a.username.localeCompare(b.username, "ja-JP")
          case "company":
            return a.company.localeCompare(b.company, "ja-JP")
          case "status":
            return a.status.localeCompare(b.status, "ja-JP")
          case "expected-working-hours":
            return a.expectedWorkingHours - b.expectedWorkingHours
          case "assigned-working-hours":
            return a.assignedWorkingHours - b.assignedWorkingHours
          case "actual-working-hours":
            return a.actualWorkingHours - b.actualWorkingHours
          case "not-worked-hours":
            return a.notWorkedHours - b.notWorkedHours
          case "assignable-working-hours":
            return a.assignableWorkingHours - b.assignableWorkingHours
          default:
            return a.username.localeCompare(b.username, "ja-JP")
        }
      })(sortOption.key)
      return sortOption.order === "asc" ? ascSortValue : -ascSortValue
    })
  }, [filteredReports, sortOption])
  const summaryReport: SummaryReport = useMemo(() => {
    const unroundedSummaryReport = filteredReports.reduce(
      (acc, report) => {
        return {
          totalExpectedWorkingHours: acc.totalExpectedWorkingHours + report.expectedWorkingHours,
          totalAssignedWorkingHours: acc.totalAssignedWorkingHours + report.assignedWorkingHours,
          totalActualWorkingHours: acc.totalActualWorkingHours + report.actualWorkingHours,
          totalNotWorkedHours: acc.totalNotWorkedHours + report.notWorkedHours,
          totalAssignableWorkingHours: acc.totalAssignableWorkingHours + report.assignableWorkingHours,
        }
      },
      {
        totalExpectedWorkingHours: 0,
        totalAssignedWorkingHours: 0,
        totalActualWorkingHours: 0,
        totalNotWorkedHours: 0,
        totalAssignableWorkingHours: 0,
      }
    )
    return {
      totalExpectedWorkingHours: roundReport(unroundedSummaryReport.totalExpectedWorkingHours),
      totalAssignedWorkingHours: roundReport(unroundedSummaryReport.totalAssignedWorkingHours),
      totalActualWorkingHours: roundReport(unroundedSummaryReport.totalActualWorkingHours),
      totalNotWorkedHours: roundReport(unroundedSummaryReport.totalNotWorkedHours),
      totalAssignableWorkingHours: roundReport(unroundedSummaryReport.totalAssignableWorkingHours),
    }
  }, [filteredReports])
  const reportElements = useMemo(() => {
    return sortedReports.map((report) => {
      return (
        <tr key={report.workspaceMemberId}>
          <td className="single line">
            <Link
              to={`/workspaces/${report.workspaceId}/members/${report.workspaceMemberId}`}
              target="_blank"
              rel="noreferrer"
            >
              {report.username}
            </Link>
          </td>
          <td className="single line">{report.company}</td>
          <td className="single line">
            {report.status === "active" ? (
              <span className="ui teal label">
                <i className="user icon"></i>
                活動中
              </span>
            ) : (
              <span className="ui gray label">
                <i className="user slash icon"></i>
                脱退済み
              </span>
            )}
          </td>
          <td className="right aligned single line">{report.expectedWorkingHours}</td>
          <td className="right aligned single line">{report.assignedWorkingHours}</td>
          <td className="right aligned single line">{report.actualWorkingHours}</td>
          <td className="right aligned single line">{report.notWorkedHours}</td>
          <td className="right aligned single line">{report.assignableWorkingHours}</td>
        </tr>
      )
    })
  }, [sortedReports])

  const toggleSortKey = useCallback(
    (newSortKey: SortKey) => {
      if (sortOption.key === newSortKey && sortOption.order === "asc") {
        setSortOption({ key: newSortKey, order: "desc" })
      } else {
        setSortOption({ key: newSortKey, order: "asc" })
      }
    },
    [sortOption]
  )
  const getSortIcon = useCallback(
    (sortKey: SortKey) => {
      if (sortKey !== sortOption.key) {
        return "grey sort icon"
      }
      return sortOption.order === "asc" ? "red up sort icon" : "red down sort icon"
    },
    [sortOption]
  )

  return (
    <table className="ui celled striped table">
      <thead>
        <tr>
          <th className="three wide" onClick={() => toggleSortKey("username")}>
            ユーザー名<i className={getSortIcon("username")}></i>
          </th>
          <th className="three wide" onClick={() => toggleSortKey("company")}>
            会社名<i className={getSortIcon("company")}></i>
          </th>
          <th className="single line one wide" onClick={() => toggleSortKey("status")}>
            状態<i className={getSortIcon("status")}></i>
          </th>
          <th className="right aligned single line two wide" onClick={() => toggleSortKey("expected-working-hours")}>
            予定稼働時間<i className={getSortIcon("expected-working-hours")}></i>
          </th>
          <th className="right aligned single line two wide" onClick={() => toggleSortKey("assigned-working-hours")}>
            アサイン時間<i className={getSortIcon("assigned-working-hours")}></i>
          </th>
          <th className="right aligned single line two wide" onClick={() => toggleSortKey("actual-working-hours")}>
            実績時間<i className={getSortIcon("actual-working-hours")}></i>
          </th>
          <th className="right aligned single line two wide" onClick={() => toggleSortKey("not-worked-hours")}>
            予定稼働 - 実績<i className={getSortIcon("not-worked-hours")}></i>
          </th>
          <th className="right aligned single line one wide" onClick={() => toggleSortKey("assignable-working-hours")}>
            空き時間<i className={getSortIcon("assignable-working-hours")}></i>
          </th>
        </tr>
      </thead>
      <tbody>
        <SummaryTr>
          <td>合計</td>
          <td></td>
          <td></td>
          <td className="right aligned single line">{summaryReport.totalExpectedWorkingHours}</td>
          <td className="right aligned single line">{summaryReport.totalAssignedWorkingHours}</td>
          <td className="right aligned single line">{summaryReport.totalActualWorkingHours}</td>
          <td className="right aligned single line">{summaryReport.totalNotWorkedHours}</td>
          <td className="right aligned single line">{summaryReport.totalAssignableWorkingHours}</td>
        </SummaryTr>
        {reportElements}
      </tbody>
    </table>
  )
})

export default ReportsTable
