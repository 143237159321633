import { ScheduleApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { Schedule, PutScheduleRequest, DateOnly } from "model"

export interface ScheduleResponse {
  scheduleId: string
  workspaceId: string
  jobId: string
  workspaceMemberId: string
  startDate: string
  endDate: string
  type: "hours" | "percentage"
  value: number
  createdDatetime: string
  updatedDatetime: string
}

export const toSchedule = (data: ScheduleResponse): Schedule =>
  Object.assign(data, {
    startDate: DateOnly.fromString(data.startDate),
    endDate: DateOnly.fromString(data.endDate),
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as Schedule

export class ScheduleApiClientImpl implements ScheduleApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  get = async (workspaceId: string, scheduleId: string): Promise<Schedule> => {
    const path = `/api/v1/workspaces/${workspaceId}/schedules/${scheduleId}`
    const res = await this.client.getWithAuth<ScheduleResponse>(path)
    return toSchedule(res.data)
  }

  list = async (workspaceId: string, termStart: string, termEnd: string, jobId?: string): Promise<Schedule[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/schedules`
    const params = new Map<string, string>()
    params.set("term_start", termStart)
    params.set("term_end", termEnd)
    if (jobId) params.set("job_id", jobId)
    const res = await this.client.getWithAuth<ScheduleResponse[]>(path, params)
    return res.data.map(toSchedule)
  }

  listSum = async (workspaceId: string, jobId?: string): Promise<Record<string, Record<string, number>>> => {
    const path = `/api/v1/workspaces/${workspaceId}/sum-of-schedules`
    const params = new Map<string, string>()
    if (jobId) params.set("job_id", jobId)
    const res = await this.client.getWithAuth<Record<string, Record<string, number>>>(path, params, undefined, "keep")
    return res.data
  }

  put = async (schedule: PutScheduleRequest): Promise<Schedule> => {
    const path = `/api/v1/workspaces/${schedule.workspaceId}/schedules/${schedule.scheduleId}`
    const body = Object.assign(schedule, {
      startDate: schedule.startDate.toISOString(),
      endDate: schedule.endDate.toISOString(),
      createdDatetime: undefined,
      updatedDatetime: undefined,
      lastUpdatedDatetime: schedule.updatedDatetime?.toISOString(),
    })
    const res = await this.client.putWithAuth<ScheduleResponse>(path, body)
    return toSchedule(res.data)
  }

  delete = async (workspaceId: string, scheduleId: string): Promise<Schedule> => {
    const path = `/api/v1/workspaces/${workspaceId}/schedules/${scheduleId}`
    const res = await this.client.deleteWithAuth<ScheduleResponse>(path)
    return toSchedule(res.data)
  }
}
