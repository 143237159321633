import { MyInfoApiClient } from "api"
import * as m from "model"

export class MyInfoApiClientMock implements MyInfoApiClient {
  private readonly account: m.Account = {
    accountId: "account_id",
    userId: "user_id",
    username: "username",
    authority: "user",
    email: "email",
    locale: "ja-JP",
    externalLinkageInfo: {},
    createdDatetime: new Date(),
    updatedDatetime: new Date(),
  }

  getMyAccount(): Promise<m.Account> {
    return Promise.resolve(this.account)
  }

  putMyAccount(request: m.PutMyAccountRequest): Promise<m.Account> {
    return Promise.resolve(this.account)
  }

  getMyWorkspaces = async (): Promise<m.Workspace[]> =>
    Promise.resolve([
      {
        workspaceId: "kurusugawa",
        workspaceName: "来栖川電算",
        email: "kci@example.com",
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
      {
        workspaceId: "e8f1ab36-3103-43db-bdd8-8207f30de347",
        workspaceName: "来栖川電算アノテーションチーム",
        email: "test2@example.com",
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
    ])

  getMyWorkspaceMembers = async (workspaceId?: string): Promise<m.WorkspaceMember[]> => {
    const members = [
      {
        workspaceId: "kurusugawa",
        workspaceMemberId: "workspace_member_1",
        accountId: "accountId",
        userId: "user_id",
        username: "来栖川メンバー",
        role: "owner",
        status: "active",
        inactivatedDatetime: undefined,
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
      {
        workspaceId: "e8f1ab36-3103-43db-bdd8-8207f30de347",
        workspaceMemberId: "workspace_member_2",
        accountId: "accountId",
        userId: "user_id",
        username: "アノテーションメンバー",
        role: "owner",
        status: "active",
        inactivatedDatetime: undefined,
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
    ] as m.WorkspaceMember[]
    return Promise.resolve(workspaceId ? members.filter((member) => member.workspaceId === workspaceId) : members)
  }

  changePassword(oldPassword: string, newPassword: string): Promise<void> {
    return Promise.resolve()
  }

  getMySchedules = async (workspaceId?: string, termStart?: string, termEnd?: string): Promise<m.Schedule[]> => {
    const schedules: m.Schedule[] = [
      {
        scheduleId: "schedule1",
        workspaceId: "kurusugawa",
        jobId: "job1",
        workspaceMemberId: "workspace_member_1",
        startDate: m.DateOnly.fromString(termStart || new Date().toLocaleDateString()),
        endDate: m.DateOnly.fromString(termEnd || new Date().toLocaleDateString()),
        type: "hours",
        value: 3,
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
      {
        scheduleId: "schedule2",
        workspaceId: "kurusugawa",
        jobId: "job2",
        workspaceMemberId: "workspace_member_1",
        startDate: m.DateOnly.fromString(termStart || new Date().toLocaleDateString()),
        endDate: m.DateOnly.fromString(termEnd || new Date().toLocaleDateString()),
        type: "percentage",
        value: 50,
        createdDatetime: new Date(),
        updatedDatetime: new Date(),
      },
    ]
    return Promise.resolve(
      workspaceId ? schedules.filter((schedule) => schedule.workspaceId === workspaceId) : schedules
    )
  }
}
