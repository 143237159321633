import * as api from "api/index"

export class AccountApiClientMock implements api.AccountApiClient {
  postSignUp(request: api.SignUpRequest): Promise<void> {
    return Promise.resolve()
  }

  postConfirmSignUp(request: api.ConfirmSignUpRequest): Promise<void> {
    return Promise.resolve()
  }

  postResetPassword(request: api.ResetPasswordRequest): Promise<void> {
    return Promise.resolve()
  }

  postConfirmResetPassword(request: api.ConfirmResetPasswordRequest): Promise<void> {
    return Promise.resolve()
  }
}
