import { LoginApiClient, LoginRequest, LoginResponse } from "api/index"

export class LoginApiClientMock implements LoginApiClient {
  postLogin(request: LoginRequest): Promise<LoginResponse> {
    return Promise.resolve({
      idToken: "dummyIdToken",
      accessToken: "dummyAccessToken",
      refreshToken: "dummyRefreshToken",
    })
  }
}
