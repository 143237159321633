import { useMemo, useRef, useState } from "react"

import { TokenStore } from "dataStore/tokenStore"
import { UserIdStore } from "dataStore/userIdStore"
import { WorkspaceMember } from "model"
import { AppStore, ModalState } from "model/appStore"
import { ScheduleState, ScheduleSumState } from "model/scheduleState"

export interface Dispatch<T> {
  apply(f: (state: T) => T): T
  applyPromise(f: (state: T) => Promise<T>): Promise<T>
}
export function useDispatch<T>(initState: T): [T, Dispatch<T>] {
  const [state, setState] = useState(initState)
  const stateRef = useRef(state)
  stateRef.current = state
  const dispatch = useMemo(() => {
    return {
      apply(f: (state: T) => T): T {
        const ret = f(stateRef.current)
        setState(ret)
        return ret
      },
      async applyPromise(f: (state: T) => Promise<T>): Promise<T> {
        const ret = await f(stateRef.current)
        setState(ret)
        return ret
      },
    }
  }, [setState])
  return [state, dispatch]
}

export interface Reducer {
  loadInitialData(store: AppStore): Promise<AppStore>
  loadUserId(store: AppStore): Promise<AppStore>
  setUserId(userId: string): (store: AppStore) => AppStore
  resetUserId(): (store: AppStore) => AppStore
  setModal(modal: ModalState): (store: AppStore) => AppStore
  setToken(token: string): (store: AppStore) => AppStore
  resetToken(): (store: AppStore) => AppStore
  setWorkspaceId(workspaceId: string): (store: AppStore) => AppStore
  setWorkspaceMember(workspaceMember: WorkspaceMember): (store: AppStore) => AppStore
  setScheduleState(schedule: ScheduleState | ((prev: ScheduleState) => ScheduleState)): (store: AppStore) => AppStore
  setScheduleSumState(scheduleSum: ScheduleSumState): (store: AppStore) => AppStore
}

// local, 本番によらない処理はここで提供する
export const reducer = {
  setModal(modal: ModalState): (store: AppStore) => AppStore {
    return (store) => ({ ...store, modal })
  },
  setToken(token: string): (store: AppStore) => AppStore {
    TokenStore.setToken(token)
    return (store) => ({ ...store, token })
  },
  resetToken(): (store: AppStore) => AppStore {
    TokenStore.clearToken()
    return (store) => ({ ...store, token: null })
  },
  setUserId(userId: string): (store: AppStore) => AppStore {
    UserIdStore.set(userId)
    return (store) => ({ ...store, userId })
  },
  resetUserId(): (store: AppStore) => AppStore {
    UserIdStore.clear()
    return (store) => ({ ...store, userId: null })
  },
  setWorkspaceId(workspaceId: string): (store: AppStore) => AppStore {
    return (store) => ({ ...store, workspaceId })
  },
  setWorkspaceMember(workspaceMember: WorkspaceMember): (store: AppStore) => AppStore {
    return (store) => ({ ...store, workspaceMember })
  },
  setScheduleState(schedule: ScheduleState | ((prev: ScheduleState) => ScheduleState)): (store: AppStore) => AppStore {
    return (store) => {
      const newSchedule = typeof schedule === "function" ? schedule(store.schedule) : schedule
      return { ...store, schedule: newSchedule }
    }
  },
  setScheduleSumState(scheduleSum: ScheduleSumState): (store: AppStore) => AppStore {
    return (store) => ({ ...store, scheduleSum })
  },
}
