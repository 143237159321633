import { styled } from "linaria/lib/react"
import React from "react"

import { ExpectedWorkingTimeCalendar } from "component/common/expectedWorkingTime/calendar"
import * as m from "model"

type ExpectedWorkingTimePageProps = {
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
}

const CalendarContainer = styled.div`
  margin-top: 1em;
`

export const ExpectedWorkingTimePage: React.FC<ExpectedWorkingTimePageProps> = (props) => {
  if (!props.workspaceId || !props.workspaceMember) {
    return <></>
  }

  return (
    <CalendarContainer>
      <ExpectedWorkingTimeCalendar
        workspaceId={props.workspaceId}
        workspaceMember={props.workspaceMember}
        isReadOnly={false}
      />
    </CalendarContainer>
  )
}

export default ExpectedWorkingTimePage
