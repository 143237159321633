import React, { useEffect, useMemo, useState } from "react"
import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import Header from "component/header"
import ActualWorkingTime from "component/page/actualWorkingTime"
import ConfirmResetPassword from "component/page/confirmResetPassword"
import ConfirmSignUp from "component/page/confirmSignUp"
import ExpectedWorkingTime from "component/page/expectedWorkingTime"
import Login from "component/page/login"
import MyAccountSetting from "component/page/myAccountSetting"
import MyPage from "component/page/myPage"
import NotFoundPage from "component/page/notFound"
import Project from "component/page/project"
import Projects from "component/page/projects"
import Reports from "component/page/reports"
import ResetPassword from "component/page/resetPassword"
import Schedule from "component/page/schedule"
import SignUp from "component/page/signUp"
import WorkspaceMember from "component/page/workspaceMember"
import WorkspaceMembers from "component/page/workspaceMembers"
import UnhandledErrorModal from "component/unhandledErrorModal"
import { AppStore, createUnhandledErrorModalState } from "model/appStore"
import { Reducer, useDispatch } from "reducer/common"

import "fomantic-ui-css/semantic.min.css"
import "App.css"

export type AppProps = {
  initialStore: AppStore
  reducer: Reducer
}

export const App: React.FC<AppProps> = (props) => {
  const [appStore, dispatch] = useDispatch(props.initialStore)
  const [loading, setLoading] = useState(true)

  const unhandledErrorModalState = useMemo(
    () => (appStore.modal?.kind === "unhandledError" ? appStore.modal : null),
    [appStore.modal]
  )

  useEffect(() => {
    // 初回読み込み
    const timeoutId = setTimeout(() => {
      setLoading(true)
      dispatch.applyPromise(props.reducer.loadInitialData).then(() => {
        setLoading(false)
      })

      window.addEventListener("unhandledrejection", async (event: PromiseRejectionEvent) => {
        const error: Error | string = event.reason instanceof Error ? event.reason : event.reason.toString()
        const modalState = createUnhandledErrorModalState(error, "ERROR")
        dispatch.apply(props.reducer.setModal(modalState))
      })

      window.addEventListener("error", async (event: ErrorEvent) => {
        const message: string = event.error?.toString() ?? event.message
        const modalState = createUnhandledErrorModalState(message, "ERROR")
        dispatch.apply(props.reducer.setModal(modalState))
      })
    })
    return () => clearTimeout(timeoutId)
  }, [dispatch, props.reducer])

  if (loading) return <></>

  return (
    <Router>
      <div>
        <Header
          dispatch={dispatch}
          reducer={props.reducer}
          workspaceId={appStore.workspaceId}
          workspaceMember={appStore.workspaceMember}
        />
        <UnhandledErrorModal dispatch={dispatch} reducer={props.reducer} state={unhandledErrorModalState} />
        <Switch>
          <Route exact path="/">
            <Redirect to="/login" />
          </Route>
          <Route exact path="/login">
            <Login dispatch={dispatch} reducer={props.reducer} />
          </Route>
          <Route path="/workspaces/:workspace_id/my">
            <MyPage workspaceId={appStore.workspaceId} myMember={appStore.workspaceMember} />
          </Route>
          <Route path="/workspaces/:workspace_id/projects">
            <Projects workspaceId={appStore.workspaceId} />
          </Route>
          <Route path="/workspaces/:workspace_id/project">
            <Project workspaceId={appStore.workspaceId} />
          </Route>
          <Route exact path="/workspaces/:workspace_id/members">
            <WorkspaceMembers
              dispatch={dispatch}
              reducer={props.reducer}
              workspaceId={appStore.workspaceId}
              myMember={appStore.workspaceMember}
              modal={appStore.modal}
            />
          </Route>
          <Route exact path="/workspaces/:workspace_id/members/:memberId">
            <WorkspaceMember workspaceId={appStore.workspaceId} myMember={appStore.workspaceMember} />
          </Route>
          <Route path="/workspaces/:workspace_id/reports">
            <Reports workspaceId={appStore.workspaceId} />
          </Route>
          <Route path="/workspaces/:workspace_id/schedule">
            <Schedule
              dispatch={dispatch}
              reducer={props.reducer}
              modal={appStore.modal}
              token={appStore.token}
              userId={appStore.userId}
              workspaceId={appStore.workspaceId}
              workspaceMember={appStore.workspaceMember}
              schedule={appStore.schedule}
              scheduleSum={appStore.scheduleSum}
            />
          </Route>
          <Route path="/workspaces/:workspace_id/availability">
            <ExpectedWorkingTime workspaceId={appStore.workspaceId} workspaceMember={appStore.workspaceMember} />
          </Route>
          <Route path="/workspaces/:workspace_id/actual">
            <ActualWorkingTime workspaceId={appStore.workspaceId} workspaceMember={appStore.workspaceMember} />
          </Route>
          <Route exact path="/settings/account">
            <MyAccountSetting dispatch={dispatch} reducer={props.reducer} />
          </Route>
          <Route exact path="/sign-up">
            <SignUp />
          </Route>
          <Route path="/confirm-sign-up">
            <ConfirmSignUp dispatch={dispatch} reducer={props.reducer} />
          </Route>
          <Route exact path="/reset-password">
            <ResetPassword />
          </Route>
          <Route path="/confirm-reset-password">
            <ConfirmResetPassword />
          </Route>
          <Route exact path="*">
            <NotFoundPage></NotFoundPage>
          </Route>
        </Switch>
      </div>
    </Router>
  )
}

export default App
