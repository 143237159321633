import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { clients } from "api/clients"
import { DatePickerWithButtons, hasValidDateFormat } from "component/common"
import {
  ScheduleTable,
  DailyReport,
  AssignedWorkingHoursReport,
  ActualWorkingHoursReport,
} from "component/common/schedule/scheduleTable"
import * as m from "model"
import * as du from "utils/date"

// 画面上で選択された日付から一週間分のレポートを表示する
const defaultTermDays = 7

const getDailyExpectedWorkingHours = (
  expectedWorkingTimes: m.ExpectedWorkingTime[]
): Map<du.LocalDateString, number> => {
  return expectedWorkingTimes.reduce((acc, expectedWorkingTime) => {
    return acc.set(
      expectedWorkingTime.date.utcZeroHourDate.toLocaleDateString(),
      expectedWorkingTime.expectedWorkingHours
    )
  }, new Map<du.LocalDateString, number>())
}

const getDailyAssignedWorkingHoursByProject = (
  schedules: m.Schedule[],
  dailyExpectedWorkingHours: Map<du.LocalDateString, number>,
  jobMap: Map<m.JobId, m.Job>
): Map<du.LocalDateString, Map<m.ProjectId, AssignedWorkingHoursReport>> => {
  return schedules.reduce((acc, { jobId, startDate, endDate, type, value }) => {
    const job = jobMap.get(jobId)
    const projectId = job && m.getProjectId(job)
    const project = projectId && jobMap.get(projectId)
    if (!project) return acc

    const diffDays = endDate.diff(startDate) + 1
    Array.from({ length: diffDays }).forEach((_, index) => {
      const localDateString = du.plusDays(startDate.utcZeroHourDate, index).toLocaleDateString()
      const reportsByProject: Map<m.ProjectId, AssignedWorkingHoursReport> = acc.get(localDateString) ?? new Map()
      const assignedWorkingHours = reportsByProject.get(projectId)?.assignedWorkingHours ?? 0
      const totalAssignedWorkingHours = (() => {
        if (type === "hours") {
          return value + assignedWorkingHours
        } else {
          const expectedWorkingHours = dailyExpectedWorkingHours.get(localDateString) ?? 0
          return (expectedWorkingHours * value) / 100 + assignedWorkingHours
        }
      })()
      const report: AssignedWorkingHoursReport = {
        projectId: projectId,
        projectName: project.jobName,
        assignedWorkingHours: totalAssignedWorkingHours,
      }
      acc.set(localDateString, reportsByProject.set(projectId, report))
    })
    return acc
  }, new Map<du.LocalDateString, Map<m.ProjectId, AssignedWorkingHoursReport>>())
}

const getDailyActualWorkingHoursByProject = (
  actualWorkingTimes: m.ActualWorkingTime[],
  jobMap: Map<m.JobId, m.Job>
): Map<du.LocalDateString, Map<m.ProjectId, ActualWorkingHoursReport>> => {
  return actualWorkingTimes.reduce((acc, { jobId, startDatetime, endDatetime }) => {
    const job = jobMap.get(jobId)
    const projectId = job && m.getProjectId(job)
    const project = projectId && jobMap.get(projectId)
    if (!project) return acc

    const startDate = startDatetime.toLocaleDateString()
    const endDate = endDatetime.toLocaleDateString()

    const reportsByProjectOnStartDate: Map<m.ProjectId, ActualWorkingHoursReport> = acc.get(startDate) ?? new Map()
    const reportsByProjectOnEndDate: Map<m.ProjectId, ActualWorkingHoursReport> = acc.get(endDate) ?? new Map()

    const actualWorkingHoursOnStartDate = reportsByProjectOnStartDate.get(projectId)?.actualWorkingHours ?? 0
    const actualWorkingHoursOnEndDate = reportsByProjectOnEndDate.get(projectId)?.actualWorkingHours ?? 0

    const defaultReport: ActualWorkingHoursReport = {
      projectId: projectId,
      projectName: project.jobName,
      actualWorkingHours: 0,
    }

    if (startDate === endDate) {
      const totalActualWorkingHours =
        du.toHours(endDatetime.getTime() - startDatetime.getTime()) + actualWorkingHoursOnStartDate
      const report = Object.assign({}, defaultReport, { actualWorkingHours: totalActualWorkingHours })
      return acc.set(startDate, reportsByProjectOnStartDate.set(projectId, report))
    }

    const splitDate = new Date(startDatetime.getFullYear(), startDatetime.getMonth(), startDatetime.getDate() + 1)

    const totalActualWorkingHoursOnStartDate =
      du.toHours(splitDate.getTime() - startDatetime.getTime()) + actualWorkingHoursOnStartDate
    const totalActualWorkingHoursOnEndDate =
      du.toHours(endDatetime.getTime() - splitDate.getTime()) + actualWorkingHoursOnEndDate

    const reportOnStartDate = Object.assign({}, defaultReport, {
      actualWorkingHours: totalActualWorkingHoursOnStartDate,
    })
    const reportOnEndDate = Object.assign({}, defaultReport, { actualWorkingHours: totalActualWorkingHoursOnEndDate })

    acc.set(startDate, reportsByProjectOnStartDate.set(projectId, reportOnStartDate))
    acc.set(endDate, reportsByProjectOnEndDate.set(projectId, reportOnEndDate))

    return acc
  }, new Map<du.LocalDateString, Map<m.ProjectId, ActualWorkingHoursReport>>())
}

const loadDailyReports = async (
  workspaceId: string,
  workspaceMemberId: string,
  myWorkspaceMemberId: string,
  jobMap: Map<m.JobId, m.Job>,
  termStartStr: string
): Promise<DailyReport[]> => {
  const termStart = new Date(termStartStr)
  const termEnd = du.plusDays(termStart, defaultTermDays - 1)
  const nextDayOfTermEnd = du.plusDays(termEnd, 1)
  const termStartISO8601Str = du.toISO8601LocalDateString(termStart)
  const termEndISO8601Str = du.toISO8601LocalDateString(termEnd)

  const getExpectedWorkingTimes = clients.expectedWorkingTime.getExpectedWorkingTimesByWorkspaceMember(
    workspaceId,
    workspaceMemberId,
    termStartISO8601Str,
    termEndISO8601Str
  )
  const getSchedules =
    workspaceMemberId === myWorkspaceMemberId
      ? clients.myInfo.getMySchedules(workspaceId, termStartISO8601Str, termEndISO8601Str)
      : clients.schedule
          .list(workspaceId, termStartISO8601Str, termEndISO8601Str)
          .then((schedules) => schedules.filter((x) => x.workspaceMemberId === workspaceMemberId))
  const getActualWorkingTimes = clients.actualWorkingTime.listByMember(
    workspaceId,
    workspaceMemberId,
    termStart,
    nextDayOfTermEnd
  )
  const [expectedWorkingTimse, schedules, actualWorkingTimes] = await Promise.all([
    getExpectedWorkingTimes,
    getSchedules,
    getActualWorkingTimes,
  ])

  const dailyExpectedWorkingHours = getDailyExpectedWorkingHours(expectedWorkingTimse)
  const dailyAssignedWorkingHoursByProject = getDailyAssignedWorkingHoursByProject(
    schedules,
    dailyExpectedWorkingHours,
    jobMap
  )
  const dailyActualWorkingHoursByProject = getDailyActualWorkingHoursByProject(actualWorkingTimes, jobMap)

  return Array.from({ length: defaultTermDays }).map((_, index) => {
    const date = du.plusDays(termStart, index)
    const localDateString = date.toLocaleDateString()

    const expectedWorkingHours: number = dailyExpectedWorkingHours.get(localDateString) ?? 0
    const assignedWorkingHoursByProject: Map<m.ProjectId, AssignedWorkingHoursReport> =
      dailyAssignedWorkingHoursByProject.get(localDateString) ?? new Map()
    const actualWorkingHoursbyProject: Map<m.ProjectId, ActualWorkingHoursReport> =
      dailyActualWorkingHoursByProject.get(localDateString) ?? new Map()

    return {
      date: date,
      expectedWorkingHours: expectedWorkingHours,
      assignedWorkingHoursReports: Array.from(assignedWorkingHoursByProject.values()),
      actualWorkingHoursReports: Array.from(actualWorkingHoursbyProject.values()),
    }
  })
}

export type ScheduleTabProps = {
  workspaceId: string
  workspaceMember: m.WorkspaceMember
  myWorkspaceMemberId: string
  jobMap: Map<m.JobId, m.Job>
}

export const ScheduleTab: React.FC<ScheduleTabProps> = React.memo((props) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location])

  const [termStartStr, setTermStartStr] = useState<string>("")
  const [dailyReports, setDailyReports] = useState<DailyReport[]>([])

  const onDateChange = useCallback(
    (value) => {
      if (hasValidDateFormat(value)) {
        history.push(`${location.pathname}?term_start=${value}${location.hash}`)
      } else {
        setTermStartStr(value)
      }
    },
    [history, location]
  )

  const plusDays = useCallback(
    (days: number) => {
      if (!hasValidDateFormat(termStartStr)) return
      const date = du.plusDays(new Date(termStartStr), days)
      onDateChange(du.toZeroPaddedLocalDateString(date))
    },
    [termStartStr, onDateChange]
  )
  const plusOneWeek = useCallback(() => plusDays(defaultTermDays), [plusDays])
  const minusOneWeek = useCallback(() => plusDays(-defaultTermDays), [plusDays])

  useEffect(() => {
    const termQuery = queryParams.get("term_start")
    const hasValidTerm = termQuery !== null && hasValidDateFormat(termQuery)
    setTermStartStr(hasValidTerm ? termQuery : du.toZeroPaddedLocalDateString(new Date()))
  }, [queryParams, setTermStartStr])

  useEffect(() => {
    if (props.workspaceId && hasValidDateFormat(termStartStr)) {
      loadDailyReports(
        props.workspaceId,
        props.workspaceMember.workspaceMemberId,
        props.myWorkspaceMemberId,
        props.jobMap,
        termStartStr
      ).then((x) => setDailyReports(x))
    }
  }, [props, termStartStr, setDailyReports])

  return (
    <div className="ui tab basic active segment">
      <DatePickerWithButtons
        value={termStartStr}
        onCalendarChange={(event, { value }) => onDateChange(value)}
        onNextClick={plusOneWeek}
        onPrevClick={minusOneWeek}
      ></DatePickerWithButtons>
      <ScheduleTable dailyReports={dailyReports}></ScheduleTable>
    </div>
  )
})

export default ScheduleTab
