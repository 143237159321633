import { css } from "linaria"
import { styled } from "linaria/react"
import moment from "moment"
import { Button, Grid, Input } from "semantic-ui-react"

import * as m from "model"

export const OuterRowGridRow = styled(Grid.Row)`
  border-bottom: solid 0.8em lightgray;

  &.aw-top-row {
    border-top: solid 0.8em lightgray;
  }
`

export const OuterRowGridColumn = styled(Grid.Column)`
  display: flex;
  align-items: center;
`

export const TotalValue = styled.span`
  font-weight: bold;
`

// 詳細度を上げて Grid.Row のCSS指定に勝つために、自身のクラス名を連結する目的で &&&& としている
// 参考 : https://zenn.dev/tak_dcxi/articles/f464f90a24f754b15dd9
export const NoVerticalPaddingGridRow = styled(Grid.Row)`
  &&&& {
    padding-top: 0px;
    padding-bottom: 0px;
  }
`

export const ExpectedHoursTextBorderGridColumn = styled(Grid.Column)`
  border-left: solid 1.5px lightgray;

  &.aw-right-edge {
    border-right: solid 1.5px lightgray;
  }
`

export const ExpectedHoursTextValue = styled.div`
  padding-top: 1em;
  padding-bottom: 1em;
  font-weight: bold;
`

export const todayBackground = css`
  background-color: #f6ed00;
`

export const itemNameWeek = css`
  font-weight: bold;

  &.aw-non-working {
    font-size: x-small;
    color: palevioletred;
  }

  &.aw-working {
    font-size: x-small;
    color: darkgray;
  }
`

export const itemNameDay = css`
  font-weight: bold;

  &.aw-non-working {
    font-size: normal;
    color: palevioletred;
  }
  &.aw-working {
    font-size: normal;
    color: darkgray;
  }
`

export const DayOfWeekAndDayGridColumn = styled(Grid.Column)`
  border-left: solid 1.5px lightgray;
  padding-left: 0px;
  padding-right: 0px;
`

export const itemNameBorder = css`
  border-bottom: solid 1.5px lightgray;

  &.aw-right-edge {
    border-right: solid 1.5px lightgray;
  }
`

// styled(Button) を使うとポップアップメニューが意図しない位置に出てしまうようになったため css を使っている
export const popupButton = css`
  &&& {
    background-color: transparent;
  }

  &:hover {
    background-color: rgb(238, 238, 238);
  }
`

export const EditExpectedWorkingTimeInput = styled(Input)`
  &&& > input {
    text-align: center;
  }
`

export const CopyWeekInput = styled(Input)`
  &&& > input {
    text-align: right;
  }
`

export const PopupMenuRow = styled.div`
  width: 100px;
`

export const PopupMenuButton = styled(Button)`
  &&&&& {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: rgb(238, 238, 238);
      box-shadow: none;
    }
  }
`

const today = m.DateOnly.fromDateAsLocal(new Date())

export type ExpectedWorkingTimeOfDay = {
  date: m.DateOnly
  expectedWorkingTime: m.ExpectedWorkingTime | null
}

export const getExpectedWorkingTimesOfWeek = (
  expectedWorkingTimeMap: Map<string, m.ExpectedWorkingTime>,
  startDateOfWeek: m.DateOnly
): ExpectedWorkingTimeOfDay[] =>
  Array.from({ length: 7 }).map((_, i) => {
    const date = startDateOfWeek.add(i)
    const expectedWorkingTime = expectedWorkingTimeMap.get(date.toISOString()) ?? null
    return { date, expectedWorkingTime }
  })

export const hoursToString = (hours: number): string => {
  const hourOnly = Math.floor(hours)
  const minute = Math.round((hours - hourOnly) * 60)
  return `${hourOnly.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`
}

export const stringToHours = (value: string): number | null => {
  const matchResultOfFullPattern = value.match(/^(\d{1,2}):(\d{1,2})$/)
  if (matchResultOfFullPattern) {
    const hourPart = Number(matchResultOfFullPattern[1])
    const minutePart = Number(matchResultOfFullPattern[2])
    const hours = hourPart + Math.floor(minutePart / 60) + (minutePart % 60) / 60
    if (0 <= hours && hours <= 24) return hours
    else return null
  } else {
    const numberValue = Number(value)
    if (Number.isNaN(numberValue)) return null
    if (0 <= numberValue && numberValue <= 24) return numberValue
    if (100 <= numberValue && numberValue <= 2400) {
      const hours = Math.floor(numberValue / 100) + (numberValue % 100) / 60
      if (0 <= hours && hours <= 24) return hours
      else return null
    }
  }
  return null
}

export const formatDateWithSlashSeparater = (date: m.DateOnly): string =>
  moment(date.utcZeroHourDate).format("YYYY/M/D")

export const getDayClassNames = (date: m.DateOnly): string[] =>
  date.utcZeroHourDate.getTime() === today.utcZeroHourDate.getTime() ? [todayBackground] : []

export const getWorkingClassName = (date: m.DateOnly): string => {
  switch (date.utcZeroHourDate.getDay()) {
    case 0:
    case 6:
      return "aw-non-working"
    default:
      return "aw-working"
  }
}
