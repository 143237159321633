import React, { useState, useCallback, useEffect, useMemo } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { clients } from "api/clients"
import { LoadingDimmer, DatesRangePicker, getDatesRangeString, hasValidDatesRangeFormat } from "component/common"
import ReportsTable, { Report, roundReport } from "component/common/reports/reportsTable"
import * as m from "model"
import * as du from "utils/date"

type ReportsTabProps = {
  workspaceId: string
  workspaceMember: m.WorkspaceMember
  myWorkspaceMemberId: string
  jobMap: Map<m.JobId, m.Job>
}

const getTotalExpectedWorkingHours = (expectedWorkingTimes: m.ExpectedWorkingTime[]): number => {
  return expectedWorkingTimes.reduce((acc, expectedWorkingTime) => acc + expectedWorkingTime.expectedWorkingHours, 0)
}

const getTotalAssignedWorkingHoursByProject = (
  schedules: m.Schedule[],
  expectedWorkingTimes: m.ExpectedWorkingTime[],
  jobMap: Map<m.JobId, m.Job>,
  termStart: Date,
  termEnd: Date
): Map<m.ProjectId, number> => {
  const dailyExpectedWorkingHours = expectedWorkingTimes.reduce((acc, expectedWorkingTime) => {
    return acc.set(
      expectedWorkingTime.date.toZeroHourLocalDate().toLocaleDateString(),
      expectedWorkingTime.expectedWorkingHours
    )
  }, new Map<du.LocalDateString, number>())

  return schedules.reduce((acc, { jobId, startDate, endDate, type, value }) => {
    const job = jobMap.get(jobId)
    const projectId = job && m.getProjectId(job)
    const project = projectId && jobMap.get(projectId)
    if (!project) return acc

    const diffDays = endDate.diff(startDate) + 1
    const assignedWorkingHours = Array.from({ length: diffDays }).reduce((acc: number, _, index) => {
      const date = du.plusDays(startDate.toZeroHourLocalDate(), index)
      if (date < termStart || termEnd < date) {
        return acc
      }
      if (type === "hours") {
        return acc + value
      } else {
        const expectedWorkingHours = dailyExpectedWorkingHours.get(date.toLocaleDateString()) ?? 0
        return (expectedWorkingHours * value) / 100 + acc
      }
    }, 0)
    const totalAssignedWorkingHours = (acc.get(projectId) ?? 0) + assignedWorkingHours
    return acc.set(projectId, totalAssignedWorkingHours)
  }, new Map<m.ProjectId, number>())
}

const getTotalActualWorkingHoursByProject = (
  actualWorkingTimes: m.ActualWorkingTime[],
  jobMap: Map<m.JobId, m.Job>,
  termStart: Date,
  termEnd: Date
): Map<m.ProjectId, number> => {
  return actualWorkingTimes.reduce((acc, { jobId, startDatetime, endDatetime }) => {
    const job = jobMap.get(jobId)
    const projectId = job && m.getProjectId(job)
    const project = projectId && jobMap.get(projectId)
    if (!project) return acc

    const actualWorkingMs = ((startDatetime: Date, endDatetime: Date): number => {
      const isStartIncluded = termStart <= startDatetime && startDatetime <= termEnd
      const isEndIncluded = termStart <= endDatetime && endDatetime <= termEnd
      if (isStartIncluded && isEndIncluded) {
        return endDatetime.getTime() - startDatetime.getTime()
      } else if (isStartIncluded) {
        const nextDayOfStartDatetime = new Date(
          startDatetime.getFullYear(),
          startDatetime.getMonth(),
          startDatetime.getDate() + 1
        )
        return nextDayOfStartDatetime.getTime() - startDatetime.getTime()
      } else if (isEndIncluded) {
        const sameDayOfEndDatetime = new Date(endDatetime.getFullYear(), endDatetime.getMonth(), endDatetime.getDate())
        return endDatetime.getTime() - sameDayOfEndDatetime.getTime()
      } else {
        return 0
      }
    })(startDatetime, endDatetime)
    const actualWorkingHours = du.toHours(actualWorkingMs)
    const totalActualWorkingHours = (acc.get(projectId) ?? 0) + actualWorkingHours
    return acc.set(projectId, totalActualWorkingHours)
  }, new Map<m.ProjectId, number>())
}

const loadReport = async (
  workspaceId: string,
  workspaceMemberId: m.WorkspaceMemberId,
  myWorkspaceMemberId: string,
  jobMap: Map<m.JobId, m.Job>,
  term: string
): Promise<Report> => {
  const [termStart, termEnd] = term.split("-").map((x) => new Date(x.trim()))
  const [termStartISO8601Str, termEndISO8601Str] = term.split("-").map((x) => x.trim().replaceAll("/", "-"))
  const nextDayOfTermEnd = du.plusDays(termEnd, 1)

  const getExpectedWorkingTimes = clients.expectedWorkingTime.getExpectedWorkingTimesByWorkspaceMember(
    workspaceId,
    workspaceMemberId,
    termStartISO8601Str,
    termEndISO8601Str
  )
  const getSchedules =
    workspaceMemberId === myWorkspaceMemberId
      ? clients.myInfo.getMySchedules(workspaceId, termStartISO8601Str, termEndISO8601Str)
      : clients.schedule
          .list(workspaceId, termStartISO8601Str, termEndISO8601Str)
          .then((schedules) => schedules.filter((x) => x.workspaceMemberId === workspaceMemberId))
  const getActualWorkingTimes = clients.actualWorkingTime.listByMember(
    workspaceId,
    workspaceMemberId,
    termStart,
    nextDayOfTermEnd
  )
  const [expectedWorkingTimes, schedules, actualWorkingTimes] = await Promise.all([
    getExpectedWorkingTimes,
    getSchedules,
    getActualWorkingTimes,
  ])

  const totalExpectedWorkingHours: number = getTotalExpectedWorkingHours(expectedWorkingTimes)
  const totalAssignedWorkingHoursByProject: Map<m.ProjectId, number> = getTotalAssignedWorkingHoursByProject(
    schedules,
    expectedWorkingTimes,
    jobMap,
    termStart,
    termEnd
  )
  const totalActualWorkingHoursByProject: Map<m.ProjectId, number> = getTotalActualWorkingHoursByProject(
    actualWorkingTimes,
    jobMap,
    termStart,
    nextDayOfTermEnd
  )

  // 重複を弾くため、一旦Setに変換
  const uniqueProjectIds = new Set([
    ...totalAssignedWorkingHoursByProject.keys(),
    ...totalActualWorkingHoursByProject.keys(),
  ])
  const projectReports = Array.from(uniqueProjectIds).map((projectId) => {
    const projectName = jobMap.get(projectId)?.jobName ?? ""
    const assignedWorkignHours = totalAssignedWorkingHoursByProject.get(projectId) ?? 0
    const actualWorkingHours = totalActualWorkingHoursByProject.get(projectId) ?? 0

    return {
      projectId: projectId,
      projectName: projectName,
      assignedWorkingHours: roundReport(assignedWorkignHours),
      actualWorkingHours: roundReport(actualWorkingHours),
    }
  })

  return {
    expectedWorkingHours: roundReport(totalExpectedWorkingHours),
    projectReports: projectReports,
  }
}

// デフォルトの集計期間は「今月(画面を開いた月)の1日〜末日」とする。
const getDefaultTerm = (): string => {
  const now = new Date()
  const firstDayOfThisMonth = new Date(now.getFullYear(), now.getMonth(), 1)
  const lastDayOfThisMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0)
  return getDatesRangeString(firstDayOfThisMonth, lastDayOfThisMonth)
}

export const ReportsTab: React.FC<ReportsTabProps> = React.memo((props) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location])

  const [report, setReport] = useState<Report>({ expectedWorkingHours: 0, projectReports: [] })
  const [term, setTerm] = useState<string>(getDefaultTerm())
  const [isLoading, setIsLoading] = useState<boolean>(false)

  const onDatesRangeChange = useCallback(
    (event, { value }) => {
      if (hasValidDatesRangeFormat(value)) {
        history.push(`${location.pathname}?term=${value}${location.hash}`)
      } else {
        setTerm(value)
      }
    },
    [history, location, setTerm]
  )

  useEffect(() => {
    const termQuery = queryParams.get("term")
    const hasValidTerm = termQuery !== null && hasValidDatesRangeFormat(termQuery)

    setTerm(hasValidTerm ? termQuery : getDefaultTerm())
  }, [queryParams, setTerm])

  useEffect(() => {
    if (props.workspaceId && hasValidDatesRangeFormat(term)) {
      setIsLoading(true)
      loadReport(
        props.workspaceId,
        props.workspaceMember.workspaceMemberId,
        props.myWorkspaceMemberId,
        props.jobMap,
        term
      )
        .then((x) => setReport(x))
        .finally(() => setIsLoading(false))
    }
  }, [props, term, setReport, setIsLoading])

  return (
    <div className="ui tab basic active segment">
      <LoadingDimmer isLoading={isLoading}>
        <DatesRangePicker value={term} onChange={onDatesRangeChange} placeholder="集計期間"></DatesRangePicker>
      </LoadingDimmer>
      <ReportsTable report={report}></ReportsTable>
    </div>
  )
})

export default ReportsTab
