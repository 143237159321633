import { css } from "linaria"
import { styled } from "linaria/react"
import moment from "moment"
import "moment/locale/ja"
import QueryString from "qs"
import React, { useEffect, useMemo, useState, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Button, Dropdown, DropdownProps, Grid, Input, InputOnChangeData, Modal, Popup } from "semantic-ui-react"
import { v4 as uuidv4 } from "uuid"

import { ApiError } from "api/apiError"
import { clients } from "api/clients"
import DatePickerWithButtons from "component/common/DatePickerWithButtons"
import { HistoriesStore } from "dataStore/historiesStore"
import * as m from "model"
import { formatDate } from "utils/datePicker"
import { getEnvironment } from "utils/environment"
import * as time from "utils/time"

export type ActualWorkingTimeListProps = {
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
  jobs: m.Job[]
  readOnly?: boolean
  dateCount?: number
  requiresLoadActualWorkingTimes: boolean
  setRequiresLoadActualWorkingTimes: (value: boolean) => void
  historiesStore?: HistoriesStore
}

const LineDivider = styled.span`
  &:before {
    content: "-";
    padding: 3px;
  }
`
const TotalActual = styled.div`
  padding: 0px 15px;

  span {
    padding-left: 10px;
    vertical-align: -0.25em;
  }
`
const totalActualColumnClassName = css`
  &&&&&&& {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
`
const innerItemClassName = css`
  &&&& {
    padding: 0px 15px;
  }
`
const weekdayClassName = css`
  color: white;
`
const holidayClassName = css`
  color: #f77979;
`
const popupVerticalIconClassName = css`
  &&& {
    background-color: transparent;

    &:hover {
      background-color: rgb(238, 238, 238);
    }
  }
`
const editActualTimeClassName = css`
  width: 71px;
`
const editActualNoteClassName = css`
  width: 320px;
`
const editActualButtonClassName = css`
  width: 100px;
  margin: 3px;
`
const popupMenuClassName = css`
  width: 150px;
`
const popupMenuButtonClassName = css`
  &&&&&& {
    box-shadow: none;

    &:focus {
      box-shadow: none;
    }
    &:hover {
      background-color: rgb(238, 238, 238);
      box-shadow: none;
    }
  }
`

const dailyActualClassName = css`
  &&& {
    font-size: 1.07142857em;
  }
`

const inlineItemClassName = css`
  display: inline-block;
`

const now = new Date()
const today = m.DateOnly.fromDateAsLocal(now)
const language = (window.navigator.languages && window.navigator.languages[0]) || window.navigator.language

function toDateString(day: m.DateOnly): string {
  return moment(day.utcZeroHourDate).locale(language).format("YYYY/MM/DD")
}
function toDayOfWeekString(day: m.DateOnly): string {
  return moment(day.utcZeroHourDate).locale(language).format("ddd")
}
function getDateFromQuery(queryString: string): m.DateOnly | null {
  const { date } = QueryString.parse(queryString)
  const dateString = Array.isArray(date) ? date[0] : date
  if (typeof dateString !== "string") return null
  const parseResult = moment(dateString, "YYYY-MM-DD", true)
  return parseResult.isValid() ? m.DateOnly.fromDateAsLocal(parseResult.toDate()) : null
}
function sortJobs(jobs: m.Job[], histories: string[]): m.Job[] {
  const historyMap = histories.reduce((acc, jobId, i) => acc.set(jobId, i), new Map<string, number>())
  return jobs.sort((x, y) => {
    const history =
      (historyMap.get(x.jobId) ?? Number.MAX_SAFE_INTEGER) - (historyMap.get(y.jobId) ?? Number.MAX_SAFE_INTEGER)
    if (history === 0) return x.jobName.localeCompare(y.jobName)
    else return history
  })
}

type SeparatedActualWorkingTime = {
  original: m.ActualWorkingTime
  fixed: m.ActualWorkingTime
  separated?: m.DateOnly
}

type SeparatedActualWorkingTimesMap = Map<string, SeparatedActualWorkingTime[]>

function getSeparatedActualWorkingTimes(actualWorkingTimes: m.ActualWorkingTime[]): SeparatedActualWorkingTimesMap {
  return actualWorkingTimes
    .sort((x, y) => x.startDatetime.getTime() - y.startDatetime.getTime())
    .reduce((acc, actual) => {
      const startDay = m.DateOnly.fromDateAsLocal(actual.startDatetime)
      const nextDay = startDay.add(1)
      const nextDayDate = nextDay.toZeroHourLocalDate()
      if (nextDayDate < actual.endDatetime) {
        // 日またぎのデータの場合
        acc.set(startDay.toISOString(), [
          ...(acc.get(startDay.toISOString()) ?? []),
          {
            original: actual,
            fixed: { ...actual, endDatetime: new Date(nextDayDate.getTime() - 1) },
            separated: nextDay,
          },
        ])
        acc.set(nextDay.toISOString(), [
          ...(acc.get(nextDay.toISOString()) ?? []),
          { original: actual, fixed: { ...actual, startDatetime: nextDayDate }, separated: startDay },
        ])
        return acc
      } else {
        // 日またぎではないデータの場合
        return acc.set(startDay.toISOString(), [
          ...(acc.get(startDay.toISOString()) ?? []),
          { original: actual, fixed: actual },
        ])
      }
    }, new Map() as SeparatedActualWorkingTimesMap)
}

type DailyActualProps = {
  date: m.DateOnly
  jobs: m.Job[]
  project?: m.Job
  job?: m.Job
  visibleProjectName: boolean
  actualWorkingTimesMap: SeparatedActualWorkingTimesMap
  actualWorkingTime: SeparatedActualWorkingTime
  readOnly: boolean
  loadActualWorkingTimes: () => void
  historiesStore?: HistoriesStore
}

const DailyActual: React.FC<DailyActualProps> = (props) => {
  const [editable, setEditable] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [openRemoveModal, setOpenRemoveModal] = useState(false)
  const [project, setProject] = useState<m.Job | undefined>(undefined)
  const [job, setJob] = useState<m.Job | undefined>(undefined)
  const [jobDropdownList, setJobDropdownList] = useState<m.Job[]>([])
  const [actualStartValue, setActualStartValue] = useState<string>(
    time.dateToTimeString(props.actualWorkingTime.fixed.startDatetime)
  )
  const [actualEndValue, setActualEndValue] = useState<string>(
    time.dateToTimeString(props.actualWorkingTime.fixed.endDatetime)
  )
  const [actualIntervalValue, setActualIntervalValue] = useState<string>(
    time.msToTimeString(
      props.actualWorkingTime.fixed.endDatetime.getTime() - props.actualWorkingTime.fixed.startDatetime.getTime()
    )
  )
  const [actualNoteValue, setActualNoteValue] = useState<string>(props.actualWorkingTime.fixed.note ?? "")
  const [actualError, setActualError] = useState(false)
  const [updatingActual, setUpdatingActual] = useState(false)
  const [requiredLoadActualWorkingTimes, setRequiresLoadActualWorkingTime] = useState(false)

  const unarchivedJobs = useMemo(() => props.jobs.filter(m.isInProgress), [props.jobs])
  const projectDropdownList = useMemo(() => {
    return sortJobs(
      unarchivedJobs.filter((job) => m.isProject(job)),
      props.historiesStore?.projectHisotries ?? []
    )
  }, [props.historiesStore, unarchivedJobs])

  const buttonTextAlign = useMemo(() => (editable ? "center" : "right"), [editable])
  const defaultActualStartValue = useMemo(
    () => time.dateToTimeString(props.actualWorkingTime.fixed.startDatetime),
    [props.actualWorkingTime.fixed.startDatetime]
  )
  const defaultActualEndValue = useMemo(
    () => time.dateToTimeString(props.actualWorkingTime.fixed.endDatetime),
    [props.actualWorkingTime.fixed.endDatetime]
  )
  const defaultActualIntervalValue = useMemo(
    () =>
      time.msToTimeString(
        props.actualWorkingTime.fixed.endDatetime.getTime() - props.actualWorkingTime.fixed.startDatetime.getTime()
      ),
    [props.actualWorkingTime.fixed.endDatetime, props.actualWorkingTime.fixed.startDatetime]
  )
  const defaultActualNoteValue = useMemo(
    () => props.actualWorkingTime.fixed.note ?? "",
    [props.actualWorkingTime.fixed.note]
  )
  const defaultActualEndMs = useMemo(() => time.timeStringToMs(defaultActualEndValue), [defaultActualEndValue])
  const actualEndMsFromZeroHour = useMemo(
    () => props.actualWorkingTime.fixed.endDatetime.getTime() - props.date.toZeroHourLocalDate().getTime(),
    [props.actualWorkingTime.fixed.endDatetime, props.date]
  )
  const projectDropdownOptions = useMemo(
    () =>
      projectDropdownList.map((project) => ({
        key: project.jobId,
        text: project.jobName,
        value: project.jobId,
        content: <Popup content={project.jobName} mouseEnterDelay={500} trigger={<div>{project.jobName}</div>} />,
      })),
    [projectDropdownList]
  )

  const jobDropdownOptions = useMemo(
    () =>
      jobDropdownList.map((job) => ({
        key: job.jobId,
        text: job.jobName,
        value: job.jobId,
        content: <Popup content={job.jobName} mouseEnterDelay={500} trigger={<div>{job.jobName}</div>} />,
      })),
    [jobDropdownList]
  )

  const updateJobs = useCallback(
    (project: m.Job) => {
      const children = sortJobs(
        unarchivedJobs.filter((job) => m.getProjectId(job) === project.jobId && !m.isProject(job)),
        props.historiesStore?.jobHistories ?? []
      )
      setJobDropdownList(children)
    },
    [props.historiesStore, unarchivedJobs]
  )

  const onChangeProject = useCallback(
    (e, data: DropdownProps) => {
      if (data.value) {
        const projectId: string = data.value.toString()
        const project = unarchivedJobs.find((job) => job.jobId === projectId)
        setProject(project)
        setJob(undefined)
        if (project) updateJobs(project)
      }
    },
    [unarchivedJobs, setProject, setJob, updateJobs]
  )
  const onChangeJob = useCallback(
    (e, data: DropdownProps) => {
      if (data.value) {
        const jobId: string = data.value.toString()
        setJob(unarchivedJobs.find((job) => job.jobId === jobId))
      }
    },
    [unarchivedJobs]
  )
  const onChangeActualStart = useCallback((e, data: InputOnChangeData) => setActualStartValue(data.value), [])
  const onChangeActualEnd = useCallback((e, data: InputOnChangeData) => setActualEndValue(data.value), [])
  const onChangeActualInterval = useCallback((e, data: InputOnChangeData) => setActualIntervalValue(data.value), [])
  const onChangeActualNote = useCallback((e, data: InputOnChangeData) => setActualNoteValue(data.value), [])

  // 日またぎデータを分割した際、前日分のデータは内部的にendDatetimeが23:59:59.999となっており、終了時刻が変更されていない場合はそれを採用する
  const getEndMs = useCallback(() => {
    const actualEndMs = time.timeStringToMs(actualEndValue, true)
    return actualEndMs === defaultActualEndMs ? actualEndMsFromZeroHour : actualEndMs
  }, [actualEndMsFromZeroHour, actualEndValue, defaultActualEndMs])

  const onBlurActualStart = useCallback(() => {
    const startMs = time.timeStringToMs(actualStartValue, true)
    if (startMs != null) {
      setActualStartValue(time.msToTimeString(startMs))
      const endMs = getEndMs()
      if (endMs) {
        const interval = time.fixWithinDay(endMs - startMs)
        const hasNoInterval = interval === 0
        setActualIntervalValue(time.msToTimeString(interval))
        setActualError(hasNoInterval)
        return
      }
    }
    setActualError(true)
  }, [actualStartValue, getEndMs])

  const onBlurActualEnd = useCallback(() => {
    const endMs = time.timeStringToMs(actualEndValue, true)
    if (endMs) {
      if (endMs === defaultActualEndMs) return
      setActualEndValue(time.msToTimeString(endMs))
      const startMs = time.timeStringToMs(actualStartValue, true)
      if (startMs != null) {
        const interval = time.fixWithinDay(endMs - startMs)
        const hasNoInterval = interval === 0
        setActualIntervalValue(time.msToTimeString(interval))
        setActualError(hasNoInterval)
        return
      }
    }
    setActualError(true)
  }, [actualEndValue, actualStartValue, defaultActualEndMs])

  const onBlurActualInterval = useCallback(() => {
    const intervalMs = time.timeStringToMs(actualIntervalValue, true)
    if (intervalMs) {
      setActualIntervalValue(time.msToTimeString(intervalMs))
      const startMs = time.timeStringToMs(actualStartValue, true)
      if (startMs != null) {
        const endMs = time.fixWithinDay(startMs + intervalMs)
        const hasNoInterval = intervalMs === 0
        setActualEndValue(time.msToTimeString(endMs))
        setActualError(hasNoInterval)
        return
      }
    }
    setActualError(true)
  }, [actualIntervalValue, actualStartValue])

  const onClickEdit = useCallback(() => {
    setEditable(true)
    setOpenPopup(false)
  }, [])

  const updateSpearated = useCallback(() => {
    const updateSeparatedRequest: m.PutActualWorkingTimeRequest | undefined = (() => {
      if (!props.actualWorkingTime.separated) return undefined
      const separated = (props.actualWorkingTimesMap.get(props.actualWorkingTime.separated.toISOString()) ?? []).find(
        (x) => x.fixed.actualWorkingTimeId === props.actualWorkingTime.fixed.actualWorkingTimeId
      )
      return separated?.fixed
    })()
    const result: Promise<m.ActualWorkingTime | undefined> = updateSeparatedRequest
      ? clients.actualWorkingTime.put(updateSeparatedRequest)
      : Promise.resolve(undefined)
    return result
  }, [
    props.actualWorkingTime.fixed.actualWorkingTimeId,
    props.actualWorkingTime.separated,
    props.actualWorkingTimesMap,
  ])

  const onUpdate = useCallback(() => {
    if (updatingActual) return
    const actualStartMs = time.timeStringToMs(actualStartValue, true)
    const actualEndMs = time.timeStringToMs(actualEndValue, true)
    const actualIntervalMs = time.timeStringToMs(actualIntervalValue, true)
    const defaultActualEndMs = time.timeStringToMs(defaultActualEndValue, true)
    if (!job || actualStartMs == null || actualEndMs == null || actualIntervalMs == null || actualIntervalMs === 0)
      setActualError(true)
    else {
      const actualDateMs = props.date.toZeroHourLocalDate().getTime()
      // 日またぎデータを分割した際、前日分のデータは内部的にendDatetimeが23:59:59.999となっており、終了時刻が変更されていない場合はそれを維持する
      const actualEndDate =
        actualEndMs === defaultActualEndMs
          ? props.actualWorkingTime.fixed.endDatetime
          : new Date(actualDateMs + actualStartMs + actualIntervalMs)
      setEditable(false)
      setActualError(false)
      setUpdatingActual(true)
      updateSpearated()
        .then((updated) => {
          const request: m.PutActualWorkingTimeRequest = {
            actualWorkingTimeId: updated ? uuidv4() : props.actualWorkingTime.fixed.actualWorkingTimeId,
            workspaceId: props.actualWorkingTime.fixed.workspaceId,
            workspaceMemberId: props.actualWorkingTime.fixed.workspaceMemberId,
            jobId: job.jobId,
            startDatetime: new Date(actualDateMs + actualStartMs),
            endDatetime: actualEndDate,
            note: actualNoteValue,
            updatedDatetime: updated ? undefined : props.actualWorkingTime.fixed.updatedDatetime,
          }
          return clients.actualWorkingTime.put(request).catch((e) =>
            // 分割後の追加に失敗した場合はロールバック
            updated
              ? clients.actualWorkingTime
                  .put({
                    ...props.actualWorkingTime.original,
                    updatedDatetime: updated.updatedDatetime,
                  })
                  .catch((e) => {
                    const error = e as Error
                    // ロールバック時にエラーが発生した場合はログを送信だけする
                    clients.log.postLog({
                      clientDatetime: new Date(),
                      logLevel: "WARN",
                      message: error.message,
                      stacktrace: error.stack,
                      environment: getEnvironment(),
                    })
                  })
                  .then(() => Promise.reject(e))
              : Promise.reject(e)
          )
        })
        .then(() => new Promise((resolve) => setTimeout(resolve, 200)))
        .then(() => {
          setRequiresLoadActualWorkingTime(true)
          setUpdatingActual(false)
        })
    }
  }, [
    job,
    updatingActual,
    actualStartValue,
    actualEndValue,
    actualIntervalValue,
    defaultActualEndValue,
    props.date,
    props.actualWorkingTime,
    updateSpearated,
    actualNoteValue,
  ])

  const onCancelUpdate = useCallback(() => {
    setEditable(false)
    setProject(props.project)
    setJob(props.job)
    setActualStartValue(defaultActualStartValue)
    setActualEndValue(defaultActualEndValue)
    setActualIntervalValue(defaultActualIntervalValue)
    setActualNoteValue(defaultActualNoteValue)
    setActualError(false)
  }, [
    props.project,
    props.job,
    defaultActualEndValue,
    defaultActualIntervalValue,
    defaultActualNoteValue,
    defaultActualStartValue,
  ])

  const onRemove = useCallback(() => {
    setEditable(false)
    if (updatingActual) return
    setUpdatingActual(true)
    updateSpearated()
      .then((updated) =>
        updated
          ? Promise.resolve(undefined)
          : clients.actualWorkingTime.delete(
              props.actualWorkingTime.fixed.workspaceId,
              props.actualWorkingTime.fixed.workspaceMemberId,
              props.actualWorkingTime.fixed.actualWorkingTimeId
            )
      )
      .catch((e: Error) => {
        if (e instanceof ApiError && e.status === 404) return
        else throw e
      })
      .then(() => new Promise((resolve) => setTimeout(resolve, 200)))
      .then(() => {
        setRequiresLoadActualWorkingTime(true)
        setUpdatingActual(false)
      })
  }, [props, updateSpearated, updatingActual])

  const onOpenPopup = useCallback(() => setOpenPopup(true), [])
  const onClosePopup = useCallback(() => setOpenPopup(false), [])
  const onOpenRemoveModal = useCallback(() => {
    setOpenRemoveModal(true)
    setOpenPopup((x) => false)
  }, [])
  const onCloseRemoveModal = useCallback(() => {
    setOpenRemoveModal(false)
  }, [])

  const getValueElements = useCallback(
    (readOnly: boolean) =>
      readOnly ? (
        <>
          <Grid.Column width={5} className={`${innerItemClassName} ${dailyActualClassName}`}>
            {props.visibleProjectName ? project?.jobName ?? "" : ""}
          </Grid.Column>
          <Grid.Column width={5} className={`${innerItemClassName} ${dailyActualClassName}`}>
            {job?.jobName ?? ""}
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <div className={`${inlineItemClassName} ${dailyActualClassName}`}>{defaultActualStartValue}</div>
            <LineDivider />
            <div className={`${inlineItemClassName} ${dailyActualClassName}`}>{defaultActualEndValue}</div>
            <div className={`${inlineItemClassName} ${innerItemClassName} ${dailyActualClassName}`}>
              {defaultActualIntervalValue}
            </div>
            {defaultActualNoteValue ? (
              <div className={`${innerItemClassName} ${dailyActualClassName}`}>{defaultActualNoteValue}</div>
            ) : (
              <></>
            )}
          </Grid.Column>
        </>
      ) : (
        <>
          <Grid.Column width={5} className={`${innerItemClassName} ${dailyActualClassName}`}>
            <Dropdown
              placeholder="プロジェクト"
              fluid
              search
              selection
              options={projectDropdownOptions}
              value={project?.jobId ?? ""}
              onChange={onChangeProject}
              error={actualError}
            />
          </Grid.Column>
          <Grid.Column width={5} className={`${innerItemClassName} ${dailyActualClassName}`}>
            <Dropdown
              placeholder="ジョブ"
              fluid
              search
              selection
              options={jobDropdownOptions}
              value={job?.jobId ?? ""}
              onChange={onChangeJob}
              error={actualError}
            />
          </Grid.Column>
          <Grid.Column width={4} textAlign="right">
            <div className={`${inlineItemClassName} ${innerItemClassName}`}>
              <div className={inlineItemClassName}>
                <Input
                  className={editActualTimeClassName}
                  value={actualStartValue}
                  onChange={onChangeActualStart}
                  onBlur={onBlurActualStart}
                  error={actualError}
                />
              </div>
              <LineDivider />
              <div className={inlineItemClassName}>
                <Input
                  className={editActualTimeClassName}
                  value={actualEndValue}
                  onChange={onChangeActualEnd}
                  onBlur={onBlurActualEnd}
                  error={actualError}
                />
              </div>
            </div>
            <Input
              className={editActualTimeClassName}
              value={actualIntervalValue}
              onChange={onChangeActualInterval}
              onBlur={onBlurActualInterval}
              error={actualError}
            />
            <Input
              className={editActualNoteClassName}
              value={actualNoteValue}
              onChange={onChangeActualNote}
              error={actualError}
            />
          </Grid.Column>
        </>
      ),
    [
      props.visibleProjectName,
      project,
      job,
      projectDropdownOptions,
      jobDropdownOptions,
      actualEndValue,
      actualError,
      actualIntervalValue,
      actualNoteValue,
      actualStartValue,
      defaultActualEndValue,
      defaultActualIntervalValue,
      defaultActualNoteValue,
      defaultActualStartValue,
      onChangeProject,
      onChangeJob,
      onBlurActualEnd,
      onBlurActualInterval,
      onBlurActualStart,
      onChangeActualEnd,
      onChangeActualInterval,
      onChangeActualNote,
      onChangeActualStart,
    ]
  )

  const loadActualWorkingTimes = useMemo(() => props.loadActualWorkingTimes, [props.loadActualWorkingTimes])

  useEffect(() => {
    setProject(props.project)
    if (props.project) updateJobs(props.project)
    setJob(props.job)
  }, [props.project, props.job, updateJobs])

  useEffect(() => {
    if (requiredLoadActualWorkingTimes) {
      loadActualWorkingTimes()
      setRequiresLoadActualWorkingTime(false)
    }
  }, [loadActualWorkingTimes, requiredLoadActualWorkingTimes])

  return (
    <Grid.Row>
      {getValueElements(props.readOnly || !editable)}
      <Grid.Column width={2} textAlign={buttonTextAlign}>
        {props.readOnly ? (
          <></>
        ) : (
          <>
            {editable ? (
              <>
                <div>
                  <Button color="olive" size="tiny" className={editActualButtonClassName} onClick={onUpdate}>
                    更新
                  </Button>
                </div>
                <div>
                  <Button size="tiny" className={editActualButtonClassName} onClick={onCancelUpdate}>
                    キャンセル
                  </Button>
                </div>
              </>
            ) : (
              <>
                <Popup
                  on="click"
                  pinned
                  position="left center"
                  flowing
                  hoverable
                  open={openPopup}
                  onOpen={onOpenPopup}
                  onClose={onClosePopup}
                  trigger={<Button className={popupVerticalIconClassName} icon="ellipsis vertical" />}
                >
                  <div className={popupMenuClassName}>
                    <Button basic color="grey" className={popupMenuButtonClassName} onClick={onClickEdit}>
                      編集
                    </Button>
                  </div>
                  <div className={popupMenuClassName}>
                    <Button basic color="grey" className={popupMenuButtonClassName} onClick={onOpenRemoveModal}>
                      削除
                    </Button>
                  </div>
                </Popup>
                <Modal
                  size="mini"
                  header="確認"
                  content={
                    <div className="content">
                      <p>実績を削除しますか？</p>
                      <p>
                        プロジェクト:{project?.jobName ?? ""}
                        <br />
                        ジョブ:{job?.jobName}
                        <br />
                        {`${time.dateToTimeString(props.actualWorkingTime.fixed.startDatetime)} -
                        ${time.dateToTimeString(props.actualWorkingTime.fixed.endDatetime)}`}
                        <br />
                        {props.actualWorkingTime.fixed.note ? `備考:${props.actualWorkingTime.fixed.note}` : ""}
                      </p>
                    </div>
                  }
                  open={openRemoveModal}
                  onOpen={onOpenRemoveModal}
                  onClose={onCloseRemoveModal}
                  actions={[
                    {
                      key: "remove",
                      content: "削除",
                      color: "olive",
                      onClick: onRemove,
                    },
                    { key: "cancel", content: "キャンセル", color: "orange" },
                  ]}
                />
              </>
            )}
          </>
        )}
      </Grid.Column>
    </Grid.Row>
  )
}

type DailyDetailProps = {
  date: m.DateOnly
  jobs: m.Job[]
  actualWorkingTimesMap: SeparatedActualWorkingTimesMap
  readOnly: boolean
  loadActualWorkingTimes: () => void
  historiesStore?: HistoriesStore
}

const DailyDetail: React.FC<DailyDetailProps> = (props) => {
  const jobMap = useMemo(() => new Map(props.jobs.map((job) => [job.jobId, job])), [props.jobs])
  const dateString = useMemo(() => toDateString(props.date), [props.date])
  const dayOfWeekString = useMemo(() => toDayOfWeekString(props.date), [props.date])
  const actualWorkingTimes = useMemo(
    () => props.actualWorkingTimesMap.get(props.date.toISOString()) ?? [],
    [props.actualWorkingTimesMap, props.date]
  )
  const sum = useMemo(() => {
    const sumMs = actualWorkingTimes.reduce(
      (acc, actual) => acc + actual.fixed.endDatetime.getTime() - actual.fixed.startDatetime.getTime(),
      0
    )
    return time.msToTimeString(sumMs)
  }, [actualWorkingTimes])

  const dayOfWeekClassName = useMemo(() => {
    const dayOfWeek = props.date.utcZeroHourDate.getDay()
    return dayOfWeek === 0 || dayOfWeek === 6 ? holidayClassName : weekdayClassName
  }, [props.date])

  return (
    <div className="ui segments daily-actual">
      <div className="ui segment attached grey inverted header large">
        {`${dateString} (`}
        <span className={dayOfWeekClassName}>{dayOfWeekString}</span>
        {")"}
      </div>
      <Grid className="ui segment attached" verticalAlign="middle" divided="vertically">
        <Grid.Row>
          <Grid.Column width={14} textAlign="right" className={totalActualColumnClassName}>
            <TotalActual>
              <span className="ui menium text">合計</span>
              <span className="ui large text">{sum}</span>
            </TotalActual>
          </Grid.Column>
        </Grid.Row>
        {actualWorkingTimes.map((actual, i) => {
          const job = jobMap.get(actual.fixed.jobId)
          const projectId = job ? m.getProjectId(job) : undefined
          const project = projectId ? jobMap.get(projectId) : undefined
          const prevJob = 0 < i ? jobMap.get(actualWorkingTimes[i - 1].fixed.jobId) : undefined
          const prevProjectId = prevJob ? m.getProjectId(prevJob) : undefined
          const visibleProjectName =
            projectId != null && (!prevProjectId || prevProjectId !== projectId) && jobMap.has(projectId)
          return (
            <DailyActual
              key={actual.fixed.actualWorkingTimeId}
              date={props.date}
              jobs={props.jobs}
              project={project}
              job={job}
              visibleProjectName={visibleProjectName}
              actualWorkingTimesMap={props.actualWorkingTimesMap}
              actualWorkingTime={actual}
              readOnly={props.readOnly}
              loadActualWorkingTimes={props.loadActualWorkingTimes}
              historiesStore={props.historiesStore}
            />
          )
        })}
      </Grid>
    </div>
  )
}

export const ActualWorkingTimeList: React.FC<ActualWorkingTimeListProps> = (props) => {
  const history = useHistory()
  const location = useLocation()

  const [actualWorkingTimesMap, setActualWorkingTimesMap] = useState<SeparatedActualWorkingTimesMap>(new Map())
  const [listDate, setListDate] = useState<m.DateOnly | null>(null)
  const [listDateValue, setListDateValue] = useState<string>("")
  const [dateChanged, setDateChanged] = useState<boolean>(false)

  const dateCount = useMemo(() => props.dateCount ?? 7, [props.dateCount])
  const requiresLoadActualWorkingTime = useMemo(
    () => props.requiresLoadActualWorkingTimes || dateChanged,
    [props.requiresLoadActualWorkingTimes, dateChanged]
  )
  const endDate = useMemo(
    // 最後の日の日またぎデータを取得するために1日後までを検索範囲とする
    () => listDate?.add(1),
    [listDate]
  )
  const startDate = useMemo(() => endDate?.add(-dateCount) ?? null, [dateCount, endDate])
  const dates = useMemo(() => {
    if (!listDate || !startDate || !endDate) return []
    const date = listDate
    return Array.from({ length: date.diff(startDate) + 1 }).map((_, i) => date.add(-i))
  }, [endDate, listDate, startDate])

  const changeListDate = useCallback(
    (dateOnly: m.DateOnly | null) => {
      if (dateOnly) {
        setListDate(dateOnly)
        const queryString = location.search.slice(1)
        const queryObject = QueryString.parse(queryString)
        const newQueryString = QueryString.stringify({ ...queryObject, date: dateOnly.toISOString() })
        history.push(`${location.pathname}?${newQueryString}${location.hash}`)
      }
    },
    [history, location]
  )

  const addDate = useCallback(
    (days: number) => {
      if (listDate) {
        const addedDate = listDate.add(days)
        changeListDate(addedDate)
        setListDateValue(addedDate.toISOString().replaceAll("-", "/"))
        setDateChanged(true)
      }
    },
    [listDate, changeListDate]
  )
  const onNextClick = useCallback(() => addDate(dateCount), [dateCount, addDate])
  const onPrevClick = useCallback(() => addDate(-dateCount), [dateCount, addDate])

  const getActualWorkingTimes = useCallback(() => {
    if (!props.workspaceId || !props.workspaceMember || !startDate || !endDate) return
    clients.actualWorkingTime
      .listByMember(
        props.workspaceId,
        props.workspaceMember.workspaceMemberId,
        startDate.toZeroHourLocalDate(),
        endDate.toZeroHourLocalDate()
      )
      .then((actualWorkingTimes) => {
        setActualWorkingTimesMap(getSeparatedActualWorkingTimes(actualWorkingTimes))
      })
      .then(() => {
        props.setRequiresLoadActualWorkingTimes(false)
        setDateChanged(false)
      })
  }, [endDate, props, startDate])

  const loadActualWorkingTimes = useCallback(() => props.setRequiresLoadActualWorkingTimes(true), [props])

  useEffect(() => {
    const queryDate = getDateFromQuery(location.search.slice(1)) ?? today
    setListDate(queryDate)
    setListDateValue(queryDate.toISOString().replaceAll("-", "/"))
    setDateChanged(true)
  }, [location, setListDate, setListDateValue])

  useEffect(() => {
    if (requiresLoadActualWorkingTime) getActualWorkingTimes()
  }, [requiresLoadActualWorkingTime, listDate, getActualWorkingTimes])

  return (
    <>
      <DatePickerWithButtons
        value={listDateValue}
        placeholder="表示する日付"
        onCalendarChange={(e, { value }) => {
          formatDate(value, changeListDate)
          setListDateValue(value)
        }}
        onCalendarBlur={(e) => formatDate(listDateValue, changeListDate, setListDateValue)}
        onNextClick={onNextClick}
        onPrevClick={onPrevClick}
      ></DatePickerWithButtons>
      {dates.map((date) => (
        <DailyDetail
          key={date.toISOString()}
          date={date}
          jobs={props.jobs}
          actualWorkingTimesMap={actualWorkingTimesMap}
          readOnly={props.readOnly ?? false}
          loadActualWorkingTimes={loadActualWorkingTimes}
          historiesStore={props.historiesStore}
        />
      ))}
    </>
  )
}

export default ActualWorkingTimeList
