import { JobApiClient, JobSortKey } from "api"
import { ApiError } from "api/apiError"
import * as m from "model"

const orgId = "kurusugawa"
const pastTime = new Date("2020-01-01T12:00:00Z")
function createJob(
  id: string,
  name: string,
  status: m.Job["status"] = "unarchived",
  targetHours?: number,
  parentId?: string,
  note?: string
): m.Job {
  return {
    workspaceId: orgId,
    jobId: id,
    jobName: name,
    jobTree: parentId ? `${orgId}/${parentId}/${id}` : `${orgId}/${id}`,
    status: status,
    targetHours: targetHours,
    note: note,
    externalLinkageInfo: {},
    createdDatetime: pastTime,
    updatedDatetime: pastTime,
  }
}
const jobs: m.Job[] = [
  createJob("0", "天候 テスト 教師"),
  createJob("1", "天候 本番 教師"),
  createJob("2", "天候 本番 受入", "archived"),
  createJob("3", "天候2 本番 教師"),
  createJob("4", "天候2 本番 受入aaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaaa", "unarchived", 100),
  ...Array.from({ length: 11 }).map((x, i) =>
    createJob("j" + i, `道路${i + 1} 本番 教師`, "unarchived", undefined, undefined, `備考 ${i}`)
  ),
  ...Array.from({ length: 3 }).map((x, i) =>
    createJob("k" + i, `天候${i + 1} テスト 教師`, "unarchived", undefined, "0")
  ),
  ...Array.from({ length: 10 }).map((x, i) =>
    createJob(`0/${i}`, `天候テスト ジョブ${i}`, "unarchived", 10 * i, `備考 ${i}`)
  ),
]

export class JobApiClientMock implements JobApiClient {
  getJob = async (workspaceId: string, jobId: string): Promise<m.Job> => {
    const job = jobs.find((job) => job.workspaceId === workspaceId && job.jobId === jobId)
    if (!job) {
      throw new ApiError(new Error("Missing Resource"))
    }
    return job
  }

  putJob = async (workspaceId: string, jobId: string, request: m.PutJobRequest): Promise<m.Job> => {
    const now = new Date()
    const indexOfJob = jobs.findIndex((job) => job.workspaceId === workspaceId && job.jobId === jobId)
    if (indexOfJob === -1) {
      const newJob: m.Job = {
        workspaceId: workspaceId,
        jobId: jobId,
        jobName: request.jobName,
        jobTree: request.parentJobId ? `${workspaceId}/${request.parentJobId}/${jobId}` : `${workspaceId}/${jobId}`,
        status: request.status,
        targetHours: request.targetHours,
        note: request.note,
        externalLinkageInfo: request.externalLinkageInfo,
        createdDatetime: now,
        updatedDatetime: now,
      }
      jobs.push(newJob)
      return newJob
    } else {
      const oldJob = jobs[indexOfJob]
      const newJob: m.Job = Object.assign({}, oldJob, {
        jobName: request.jobName,
        jobTree: request.parentJobId ? `${workspaceId}/${request.parentJobId}/${jobId}` : `${workspaceId}/${jobId}`,
        status: request.status,
        targetHours: request.targetHours,
        note: request.note,
        externalLinkageInfo: request.externalLinkageInfo,
        updatedDatetime: now,
      })
      jobs[indexOfJob] = newJob
      return newJob
    }
  }

  deleteJob = async (workspaceId: string, jobId: string): Promise<m.Job> => {
    const indexOfJob = jobs.findIndex((job) => job.workspaceId === workspaceId && job.jobId === jobId)
    if (indexOfJob === -1) {
      throw new ApiError(new Error("Missing Resource"))
    }

    const job = jobs[indexOfJob]
    jobs.splice(indexOfJob, 1)

    return job
  }

  getChildren = async (workspaceId: string, jobId: string): Promise<m.Job[]> => {
    return jobs.filter((x) => x.jobTree.startsWith(`${workspaceId}/${jobId}/`))
  }

  list = async (workspaceId: string, sortKeys: JobSortKey[] = []): Promise<m.Job[]> => {
    console.log(`JobApiClientMock.list(${workspaceId})`)
    return jobs
  }
}
