import { HttpApiClient } from "api/httpApiClient"
import * as api from "api/index"

export class AccountApiClientImpl implements api.AccountApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  async postSignUp(request: api.SignUpRequest): Promise<void> {
    await this.client.post<api.SignUpRequest>("/api/v1/sign-up", request)
  }

  async postConfirmSignUp(request: api.ConfirmSignUpRequest): Promise<void> {
    await this.client.post<api.ConfirmSignUpRequest>("/api/v1/confirm-sign-up", request)
  }

  async postResetPassword(request: api.ResetPasswordRequest): Promise<void> {
    await this.client.post<api.ResetPasswordRequest>("/api/v1/reset-password", request)
  }

  async postConfirmResetPassword(request: api.ConfirmResetPasswordRequest): Promise<void> {
    await this.client.post<api.ConfirmResetPasswordRequest>("/api/v1/confirm-reset-password", request)
  }
}
