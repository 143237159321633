import { v4 as uuidv4 } from "uuid"

import { WorkspaceMemberApiClient } from "api"
import { WorkspaceMember, PutWorkspaceMemberRequest, Role, WorkspaceMemberStatus } from "model"

const orgId = "kurusugawa"
const pastTime = new Date("2020-01-01T12:00:00Z")
function createMember(
  id: string,
  name: string,
  role: Role = "worker",
  status: WorkspaceMemberStatus = "active"
): WorkspaceMember {
  return {
    workspaceMemberId: id,
    workspaceId: orgId,
    accountId: id,
    userId: id,
    username: name,
    role: role,
    status: status,
    createdDatetime: pastTime,
    updatedDatetime: pastTime,
  }
}
export let members: WorkspaceMember[] = [
  createMember("f", "フェネック", "owner"),
  createMember("k", "かばん", "manager"),
  createMember("a", "アライさん"),
  createMember("s", "サーバル"),
  createMember("t", "トキ"),
  ...Array.from({ length: 10 }).map((x, i) => createMember("u" + i, "名無しさん" + (i + 1), "worker", "inactive")),
]

export class WorkspaceMemberApiClientMock implements WorkspaceMemberApiClient {
  putWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    body: PutWorkspaceMemberRequest
  ): Promise<WorkspaceMember> => {
    const newMember: WorkspaceMember = {
      workspaceMemberId: workspaceMemberId,
      workspaceId: workspaceId,
      accountId: uuidv4(),
      userId: body.userId ?? "",
      username: uuidv4(),
      role: body.role,
      status: "active",
      inactivatedDatetime: undefined,
      createdDatetime: new Date(),
      updatedDatetime: new Date(),
    }
    members.push(newMember)
    return newMember
  }

  getWorkspaceMember = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceMember> => {
    const member = members.find((x) => x.workspaceId === workspaceId && x.workspaceMemberId === workspaceMemberId)
    if (!member) throw new Error("存在しないワークスペースメンバーです")
    return member
  }

  getWorkspaceMembers = async (workspaceId: string, includesInactiveMembers = false): Promise<WorkspaceMember[]> => {
    console.log(`WorkspaceMemberApiClientMock.list(${workspaceId})`)
    return members
  }

  getWorkspaceMembersByTagId = async (workspaceId: string, workspaceTagId: string): Promise<WorkspaceMember[]> => {
    return members
  }

  deleteWorkspaceMember = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceMember> => {
    const member = members.find((x) => x.workspaceId === workspaceId && x.workspaceMemberId === workspaceMemberId)
    if (!member) throw new Error("存在しないワークスペースメンバーです")

    members = members.map((x) => {
      if (x.workspaceId === workspaceId && x.workspaceMemberId === workspaceMemberId) {
        return { ...x, status: "inactive" }
      }
      return x
    })

    return member
  }
}
