import * as api from "api"
import * as mock from "api/mock"
import * as v1 from "api/v1"

class MockClients {
  static readonly login: api.LoginApiClient = new mock.LoginApiClientMock()
  static readonly account: api.AccountApiClient = new mock.AccountApiClientMock()
  static readonly actualWorkingTime: api.ActualWorkingTimeApiClient = new mock.ActualWorkingTimeApiClientMock()
  static readonly expectedWorkingTime: api.ExpectedWorkingTimeApiClient = new mock.ExpectedWorkingTimeApiClientMock()
  static readonly job: api.JobApiClient = new mock.JobApiClientMock()
  static readonly myInfo: api.MyInfoApiClient = new mock.MyInfoApiClientMock()
  static readonly workspace: api.WorkspaceApiClient = new mock.WorkspaceApiClientMock()
  static readonly workspaceMember: api.WorkspaceMemberApiClient = new mock.WorkspaceMemberApiClientMock()
  static readonly workspaceTag: api.WorkspaceTagApiClient = new mock.WorkspaceTagApiClientMock()
  static readonly schedule: api.ScheduleApiClient = new mock.ScheduleApiClientMock()
  static readonly log: api.LogApiClient = new mock.LogApiClientMock()
}

class HttpClients {
  static readonly login: api.LoginApiClient = new v1.LoginApiClientImpl()
  static readonly account: api.AccountApiClient = new v1.AccountApiClientImpl()
  static readonly actualWorkingTime: api.ActualWorkingTimeApiClient = new v1.ActualWorkingTimeApiClientImpl()
  static readonly expectedWorkingTime: api.ExpectedWorkingTimeApiClient = new v1.ExpectedWorkingTimeApiClientImpl()
  static readonly job: api.JobApiClient = new v1.JobApiClientImpl()
  static readonly myInfo: api.MyInfoApiClient = new v1.MyInfoApiClientImpl()
  static readonly workspace: api.WorkspaceApiClient = new v1.WorkspaceApiClientImpl()
  static readonly workspaceMember: api.WorkspaceMemberApiClient = new v1.WorkspaceMemberApiClientImpl()
  static readonly workspaceTag: api.WorkspaceTagApiClient = new v1.WorkspaceTagApiClientImpl()
  static readonly schedule: api.ScheduleApiClient = new v1.ScheduleApiClientImpl()
  static readonly log: api.LogApiClient = new v1.LogApiClientImpl()
}

// TODO 環境変数で動作を切り替える箇所が複数あるので1箇所に統一する。たぶんclientsをAppStoreに持たせるのがよさそう
export const clients = process.env.NODE_ENV === "production" ? HttpClients : MockClients
