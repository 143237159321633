import "react-calendar-timeline/lib/Timeline.css"
import "component/page/schedule/calendar.css"

import React, { useCallback, useEffect, useMemo, useState } from "react"
import Timeline, {
  DateHeader,
  GetItemsProps,
  IntervalRenderer,
  ReactCalendarGroupRendererProps,
  ReactCalendarItemRendererProps,
  SidebarHeader,
  TimelineGroupBase,
  TimelineHeaders,
  TimelineItemBase,
} from "react-calendar-timeline"
import * as ifa from "react-icons/fa"
import { Link } from "react-router-dom"
import ReactTooltip from "react-tooltip"
import { Popup } from "semantic-ui-react"

import { UnassignedHour, isUnassignedHourNearlyEqual0 } from "component/page/schedule/common"
import { timezoneOffset } from "component/page/schedule/timezone"
import * as m from "model"
import { getMemberDateKey } from "model/scheduleState"
import { dateToDateString, dayms, defaultTimezone, toDateOnlyUnixtimeMs, toHours } from "utils/date"

function assertNever(x: never): never {
  throw new Error("Unexpected object: " + x)
}

function groupBy<T, K>(xs: T[], f: (x: T) => K | undefined): Map<K, T[]> {
  const ret = new Map<K, T[]>()
  for (const x of xs) {
    const k = f(x)
    if (k === undefined) {
      continue
    }
    let ys = ret.get(k)
    if (!ys) {
      ys = []
      ret.set(k, ys)
    }
    ys.push(x)
  }
  return ret
}

export interface TimelineGroupJob extends TimelineGroupBase {
  kind: "job"
  id: string
  indent: number
  job: m.Job
  total: number | null
  parent: TimelineGroup | null
}
export interface TimelineGroupMember extends TimelineGroupBase {
  kind: "member"
  id: string
  indent: number
  member: m.WorkspaceMember
  total: number | null
  parent: TimelineGroup | null
}
export interface TimelineGroupUnassignedParent extends TimelineGroupBase {
  kind: "unassignedParent"
  id: string
  indent: number
  parent: TimelineGroup | null
}
export interface TimelineGroupUnassigned extends TimelineGroupBase {
  kind: "unassigned"
  id: string
  indent: number
  member: m.WorkspaceMember
  parent: TimelineGroup | null
}
export type TimelineGroup =
  | TimelineGroupJob
  | TimelineGroupMember
  | TimelineGroupUnassignedParent
  | TimelineGroupUnassigned
type ClickableTimelineGroup = TimelineGroupJob | TimelineGroupMember

function isClickableTimelineGroup(timelineGroup: TimelineGroup): timelineGroup is ClickableTimelineGroup {
  return timelineGroup.kind === "job" || timelineGroup.kind === "member"
}

function jobToGroup(job: m.Job, rootJobSum: m.RootJobSum, parent?: TimelineGroup): TimelineGroupJob {
  const id = `${parent == null ? "" : parent.id}/job-${job.jobId}`
  const parentResourceId = parent?.kind === "member" ? parent.member.workspaceMemberId : null
  const groupTotal = parentResourceId
    ? rootJobSum.get(parentResourceId)?.children?.get(job.jobId)
    : rootJobSum.get(job.jobId)?.total
  return {
    kind: "job",
    id: id,
    title: job.jobName,
    indent: parent == null ? 0 : parent.indent + 1,
    job: job,
    total: groupTotal ? Math.round(groupTotal) : null,
    parent: parent ?? null,
  }
}
function memberToGroup(
  member: m.WorkspaceMember,
  rootJobSum: m.RootJobSum,
  parent?: TimelineGroup
): TimelineGroupMember {
  const id = `${parent == null ? "" : parent.id}/member-${member.workspaceMemberId}`
  const parentResourceId = parent?.kind === "job" ? parent.job.jobId : null
  const groupTotal = parentResourceId
    ? rootJobSum.get(parentResourceId)?.children?.get(member.workspaceMemberId)
    : rootJobSum.get(member.workspaceMemberId)?.total
  return {
    kind: "member",
    id: id,
    title: member.username,
    indent: parent == null ? 0 : parent.indent + 1,
    member: member,
    total: groupTotal ? Math.round(groupTotal) : null,
    parent: parent ?? null,
  }
}
function createGroupUnassignedParent(): TimelineGroupUnassignedParent {
  return {
    kind: "unassignedParent",
    id: "unassignedParent",
    title: "未割当",
    indent: 0,
    parent: null,
  }
}
function memberToGroupUnassigned(
  member: m.WorkspaceMember,
  parent: TimelineGroupMember | TimelineGroupUnassignedParent
): TimelineGroupUnassigned {
  const id = toUnassignedGroupId(member)
  return {
    kind: "unassigned",
    id: id,
    title: parent.kind === "member" ? "未割当" : member.username,
    indent: parent.indent + 1,
    member: member,
    parent: parent,
  }
}
export interface JobMember {
  job: m.Job | null
  member: m.WorkspaceMember | null
}
export function groupToJobMember(group: TimelineGroup): JobMember {
  return groupToJobMemberInner(group, { job: null, member: null })
}
function groupToJobMemberInner(group: TimelineGroup, ret: JobMember): JobMember {
  if (ret.job != null && ret.member != null) {
    return ret
  }
  switch (group.kind) {
    case "job": {
      const current = ret.job ? ret : { ...ret, job: group.job }
      return group.parent == null ? current : groupToJobMemberInner(group.parent, current)
    }
    case "member": {
      const current = ret.member ? ret : { ...ret, member: group.member }
      return group.parent == null ? current : groupToJobMemberInner(group.parent, current)
    }
    case "unassigned":
    case "unassignedParent":
      return ret
    default:
      return assertNever(group)
  }
}

export interface TimelineItemSchedule extends TimelineItemBase<number> {
  kind: "schedule"
  schedule: m.Schedule
}
export interface TimelineItemExpected extends TimelineItemBase<number> {
  kind: "expected"
  schedule: m.Schedule
  expected: m.ExpectedWorkingTime | undefined
}
export interface TimelineItemExpectedTotalJob extends TimelineItemBase<number> {
  kind: "expected-total-job"
  expectedTotalHours: number
  sumOfWeekHours: number
}
export interface TimelineItemExpectedTotalMember extends TimelineItemBase<number> {
  kind: "expected-total-member"
}
export interface TimelineItemActual extends TimelineItemBase<number> {
  kind: "actual"
  actuals: m.ActualWorkingTime[]
}
export interface TimelineItemActualTotalJob extends TimelineItemBase<number> {
  kind: "actual-total-job"
  actualTotalMs: number
  sumOfWeekMs: number
}
export interface TimelineItemActualTotalMember extends TimelineItemBase<number> {
  kind: "actual-total-member"
}
export interface TimelineItemUnassignedHour extends TimelineItemBase<number> {
  kind: "unassigned-hour"
  unassignedHour: UnassignedHour
}
export interface TimelineItemUnassignedHourTotalJob extends TimelineItemBase<number> {
  kind: "unassigned-hour-total-job"
  sumOfUnassignedHours: number
}
export type TimelineItem =
  | TimelineItemSchedule
  | TimelineItemExpected
  | TimelineItemExpectedTotalJob
  | TimelineItemExpectedTotalMember
  | TimelineItemActual
  | TimelineItemActualTotalJob
  | TimelineItemActualTotalMember
  | TimelineItemUnassignedHour
  | TimelineItemUnassignedHourTotalJob

function toGroupId(jobId: string, memberId: string, mode: "job" | "member"): string {
  return mode === "job" ? `/job-${jobId}/member-${memberId}` : `/member-${memberId}/job-${jobId}`
}
function toUnassignedGroupId(member: m.WorkspaceMember): string {
  return `unassigned-${member.workspaceMemberId}`
}
const now = Date.now()
const utcToday = toDateOnlyUnixtimeMs(now)
// TODO 日本時間基準になっている
const timezoneToday = toDateOnlyUnixtimeMs(now - timezoneOffset)
const defaultTimeStart = new Date(timezoneToday - 8 * dayms)
const defaultTimeEnd = new Date(timezoneToday + 22 * dayms)
function scheduleToItem(schedule: m.Schedule, mode: "job" | "member"): TimelineItemSchedule {
  return {
    kind: "schedule",
    schedule: schedule,
    id: `schedule-${schedule.scheduleId}`,
    group: toGroupId(schedule.jobId, schedule.workspaceMemberId, mode),
    start_time: schedule.startDate.utcZeroHourDate.getTime() + timezoneOffset,
    end_time: schedule.endDate.utcZeroHourDate.getTime() + timezoneOffset + dayms,
    itemProps: {
      style: {
        backgroundColor: "#bcd6a4",
        borderColor: "#bcd6a4",
        borderRadius: "8px",
      },
    },
  }
}
function dateStringMemberKey(dateString: string, memberId: string): string {
  return `${dateString}-${memberId}`
}
function dateMemberKey(date: Date, memberId: string, timezone: string = defaultTimezone): string {
  return dateStringMemberKey(dateToDateString(date, timezone), memberId)
}

function fixExpectedWorkingHours(expected: m.ExpectedWorkingTime): number {
  return Math.round(expected.expectedWorkingHours * 100) / 100
}
function expectedWorkingHoursOf(schedule: m.Schedule, expected?: m.ExpectedWorkingTime): number {
  // 誤差を捨てるため、小数点第3位で四捨五入
  return Math.round(schedule.value * (schedule.type === "hours" ? 100 : expected?.expectedWorkingHours ?? 0)) / 100
}

function scheduleToExpectedItem(
  schedule: m.Schedule,
  expected: m.ExpectedWorkingTime | undefined,
  start: number,
  mode: "job" | "member",
  isWarn: boolean
): TimelineItemExpected {
  const startOffset = start + timezoneOffset
  const dateOnly = m.DateOnly.fromUnixtimeMs(start)
  return {
    kind: "expected",
    schedule: schedule,
    expected: expected,
    id: `expected-${schedule.scheduleId}/${dateOnly.toISOString()}`,
    group: toGroupId(schedule.jobId, schedule.workspaceMemberId, mode),
    title: expectedWorkingHoursOf(schedule, expected),
    start_time: startOffset,
    end_time: startOffset + dayms,
    itemProps: {
      style: {
        background: "none",
        border: "none",
        color: isWarn ? "red" : "black",
        paddingRight: "4px",
        paddingTop: "2px",
        textAlign: "right",
      },
    },
  }
}
function schedulesToItems(
  schedules: m.Schedule[],
  expecteds: m.ExpectedWorkingTime[],
  actualDaysByDayByJob: Map<string, (readonly [m.ActualWorkingTime, number])[]>,
  mode: "job" | "member"
): TimelineItem[] {
  const visibleSchedules = schedules.flatMap((schedule) => {
    if (schedule.endDate.utcZeroHourDate.getTime() < timezoneToday) {
      return []
    }
    if (timezoneToday <= schedule.startDate.utcZeroHourDate.getTime()) {
      return [schedule]
    }
    return [{ ...schedule, startDate: m.DateOnly.fromUnixtimeMs(timezoneToday) }]
  })
  const scheduleItems = visibleSchedules.map((x) => scheduleToItem(x, mode))

  const expectedMap = new Map(expecteds.map((x) => [dateStringMemberKey(x.date.toISOString(), x.workspaceMemberId), x]))
  const scheduleDays = visibleSchedules.flatMap((schedule) => {
    const start = schedule.startDate.utcZeroHourDate.getTime()
    const end = schedule.endDate.utcZeroHourDate.getTime() + dayms
    const dateNum = Math.ceil((end - start) / dayms)
    const itemStarts = Array.from({ length: dateNum }).map((x, i) => start + i * dayms)
    return itemStarts.flatMap((itemStart) => {
      const expected = expectedMap.get(dateMemberKey(new Date(itemStart), schedule.workspaceMemberId))
      if ((!expected || expected.expectedWorkingHours <= 0) && schedule.type === "percentage") {
        return []
      }
      return [[schedule, expected, itemStart] as const]
    })
  })
  const scheduleDaysByDayByJob = groupBy(mode === "job" ? scheduleDays : [], (x) =>
    dateMemberKey(new Date(x[2]), x[0].jobId)
  )
  const jobItems = [...scheduleDaysByDayByJob].map(([key, scheduleDays]) => {
    const [schedule, , startUtc] = scheduleDays[0]
    const dateOnly = m.DateOnly.fromUnixtimeMs(startUtc)
    const expectedTotal = scheduleDays.reduce((acc, x) => acc + expectedWorkingHoursOf(x[0], x[1]), 0)
    const sumOfWeek = Array.from({ length: 6 }).reduce((acc: number, _, i) => {
      const dateUtc = startUtc - (i + 1) * dayms
      const key = dateMemberKey(new Date(dateUtc), schedule.jobId)
      const total = (() => {
        if (utcToday <= dateUtc) {
          const scheduleDays = scheduleDaysByDayByJob.get(key) ?? []
          return scheduleDays.reduce((acc, x) => acc + expectedWorkingHoursOf(x[0], x[1]), 0)
        }
        // 過去については実績を加算
        const actualDays = actualDaysByDayByJob.get(key) ?? []
        const totalActualTimeMs = totalActualTime(
          actualDays.map((x) => x[0]),
          dateUtc + timezoneOffset
        )
        return totalActualTimeMs / 60 / 60 / 1000
      })()
      return acc + total
    }, expectedTotal)
    const item: TimelineItemExpectedTotalJob = {
      kind: "expected-total-job",
      id: `expected-total/job-${schedule.jobId}/${dateOnly.toISOString()}`,
      group: `/job-${schedule.jobId}`,
      title: roundAtThirdDecimalPoint(expectedTotal),
      start_time: startUtc + timezoneOffset,
      end_time: startUtc + timezoneOffset + dayms,
      expectedTotalHours: expectedTotal,
      sumOfWeekHours: sumOfWeek,
      itemProps: {
        style: {
          background: "none",
          border: "none",
          color: "black",
          paddingRight: "4px",
          paddingTop: "2px",
          textAlign: "right",
        },
      },
    }
    return item
  })

  const memberItems = expecteds.map((expected) => {
    const start = expected.date.utcZeroHourDate.getTime() + timezoneOffset
    const item: TimelineItemExpectedTotalMember = {
      kind: "expected-total-member",
      id: `expected-total/member-${expected.workspaceMemberId}/${expected.date.toISOString()}`,
      group: `/member-${expected.workspaceMemberId}`,
      title: roundAtThirdDecimalPoint(expected.expectedWorkingHours),
      start_time: start,
      end_time: start + dayms,
      itemProps: {
        style: {
          background: "none",
          border: "none",
          color: "black",
          paddingRight: "4px",
          paddingTop: "2px",
          textAlign: "right",
        },
      },
    }
    return item
  })
  const scheduleDaysByDayByMember = groupBy(scheduleDays, (x) => dateMemberKey(new Date(x[2]), x[0].workspaceMemberId))
  const jobMemberItems = [...scheduleDaysByDayByMember].flatMap(([key, scheduleDays]) => {
    const [, expected] = scheduleDays[0]
    const expectedTotal = scheduleDays.reduce((acc, x) => acc + expectedWorkingHoursOf(x[0], x[1]), 0)
    const isOverTime = (expected ? fixExpectedWorkingHours(expected) : 0) < expectedTotal
    return scheduleDays.map(([schedule, expected, start]) =>
      scheduleToExpectedItem(schedule, expected, start, mode, isOverTime)
    )
  })

  return [...scheduleItems, ...jobItems, ...memberItems, ...jobMemberItems]
}

function totalActualTime(actuals: m.ActualWorkingTime[], start: number): number {
  const end = start + dayms
  return actuals.reduce(
    (a, x) => a + Math.min(end, x.endDatetime.getTime()) - Math.max(start, x.startDatetime.getTime()),
    0
  )
}
function actualsToItem(actuals: m.ActualWorkingTime[], start: number, groupId: string): TimelineItem {
  const actual = actuals[0]
  const total = totalActualTime(actuals, start)
  return {
    kind: "actual",
    actuals: actuals,
    id: `actual-${actual.actualWorkingTimeId}/${dateToDateString(new Date(start))}`,
    group: groupId,
    title: Math.round(total / 60 / 60 / 1000),
    start_time: start,
    end_time: start + dayms,
    itemProps: {
      style: {
        background: "none",
        border: "none",
        color: "black",
        paddingRight: "4px",
        paddingTop: "2px",
        textAlign: "right",
      },
    },
  }
}
function getActualDays(actuals: m.ActualWorkingTime[]): (readonly [m.ActualWorkingTime, number])[] {
  return actuals.flatMap((actual) => {
    const start = actual.startDatetime.getTime()
    const end = actual.endDatetime.getTime()
    if (timezoneToday + timezoneOffset <= start) {
      return []
    }
    // TODO 日本時間で表示する前提のコードとなっている
    const startDate = toDateOnlyUnixtimeMs(start - timezoneOffset) + timezoneOffset
    const dateNum = Math.ceil((Math.min(end, timezoneToday + timezoneOffset) - startDate) / dayms)
    const itemStarts = Array.from({ length: dateNum }).map((x, i) => startDate + i * dayms)
    return itemStarts.map((itemStart) => [actual, itemStart] as const)
  })
}
function actualsToItems(
  actualDays: (readonly [m.ActualWorkingTime, number])[],
  actualDaysByDayByJob: Map<string, (readonly [m.ActualWorkingTime, number])[]>,
  mode: "job" | "member"
): TimelineItem[] {
  const actualDaysByDay = groupBy(actualDays, (x) => x[1])
  const items = [...actualDaysByDay].flatMap(([itemStart, actualDays]) => {
    if (utcToday <= itemStart - timezoneOffset) return []
    const actualJobMembersByDay = groupBy(actualDays, (x) => toGroupId(x[0].jobId, x[0].workspaceMemberId, mode))
    return [...actualJobMembersByDay].map(([groupId, actualDays]) =>
      actualsToItem(
        actualDays.map((x) => x[0]),
        itemStart,
        groupId
      )
    )
  })

  const actualDaysByDayByMember = groupBy(actualDays, (x) => dateMemberKey(new Date(x[1]), x[0].workspaceMemberId))
  const jobTotalItems = [...actualDaysByDayByJob].map(([_, actualDays]) => {
    const [actual, start] = actualDays[0]
    const actualTotal = totalActualTime(
      actualDays.map((x) => x[0]),
      start
    )
    const sumOfWeek = Array.from({ length: 6 }).reduce((acc: number, _, i) => {
      const date = start - (i + 1) * dayms
      const key = dateMemberKey(new Date(date), actual.jobId)
      const actualDays = actualDaysByDayByJob.get(key) ?? []
      const total = totalActualTime(
        actualDays.map((x) => x[0]),
        date
      )
      return acc + total
    }, actualTotal)
    const item: TimelineItemActualTotalJob = {
      kind: "actual-total-job",
      id: `actual-total/job-${actual.jobId}/${dateToDateString(new Date(start))}`,
      group: `/job-${actual.jobId}`,
      title: Math.round(actualTotal / 60 / 60 / 1000),
      start_time: start,
      end_time: start + dayms,
      actualTotalMs: actualTotal,
      sumOfWeekMs: sumOfWeek,
      itemProps: {
        style: {
          background: "none",
          border: "none",
          color: "black",
          paddingRight: "4px",
          paddingTop: "2px",
          textAlign: "right",
        },
      },
    }
    return item
  })
  const memberTotalItems = [...actualDaysByDayByMember].map(([key, actualDays]) => {
    const [actual, start] = actualDays[0]
    const total = totalActualTime(
      actualDays.map((x) => x[0]),
      start
    )
    const item: TimelineItemActualTotalMember = {
      kind: "actual-total-member",
      id: `actual-total/member-${actual.workspaceMemberId}/${dateToDateString(new Date(start))}`,
      group: `/member-${actual.workspaceMemberId}`,
      title: Math.round(total / 60 / 60 / 1000),
      start_time: start,
      end_time: start + dayms,
      itemProps: {
        style: {
          background: "none",
          border: "none",
          color: "black",
          paddingRight: "4px",
          paddingTop: "2px",
          textAlign: "right",
        },
      },
    }
    return item
  })
  return [...items, ...jobTotalItems, ...memberTotalItems]
}
export function itemToSchedule(item: TimelineItem): m.Schedule | null {
  if (item.kind === "schedule") {
    return item.schedule
  }
  if (item.kind === "expected") {
    return item.schedule
  }
  return null
}

function calcDaySumOfExpecteds(expecteds: m.ExpectedWorkingTime[]): Map<number, number> {
  return expecteds.reduce((acc, expected) => {
    const unixtimeMs = expected.date.utcZeroHourDate.getTime()
    return acc.set(unixtimeMs, (acc.get(unixtimeMs) ?? 0) + expected.expectedWorkingHours)
  }, new Map<number, number>())
}

function getSumOfUnassignedHoursItems(unassignedHours: UnassignedHour[], mode: "job" | "member"): TimelineItem[] {
  if (mode === "member") return []
  const unassignedHoursMap = unassignedHours.reduce((acc, unassignedHour) => {
    const unixtimeMs = unassignedHour.date.utcZeroHourDate.getTime()
    const [hour, hasUnassigned] = acc.get(unixtimeMs) ?? [0, false]
    return acc.set(unixtimeMs, [
      hour + unassignedHour.hour,
      hasUnassigned || !isUnassignedHourNearlyEqual0(unassignedHour.hour),
    ])
  }, new Map<number, [number, boolean]>())
  return [...unassignedHoursMap].map(([dateUnixtimeMs, [hour, hasUnassigned]]) => {
    const start = dateUnixtimeMs + timezoneOffset
    const fixedHour = isUnassignedHourNearlyEqual0(hour) ? 0 : hour
    return {
      kind: "unassigned-hour-total-job",
      sumOfUnassignedHours: fixedHour,
      id: `unassigned-hour-total-job-${dateUnixtimeMs}`,
      group: "unassignedParent",
      title: roundAtThirdDecimalPoint(fixedHour),
      start_time: start,
      end_time: start + dayms,
      itemProps: {
        style: {
          background: "none",
          border: "none",
          color: hasUnassigned ? "red" : "black",
          paddingRight: "4px",
          paddingTop: "2px",
          textAlign: "right",
        },
      },
    }
  })
}

function unassignedHourToItem(unassignedHour: UnassignedHour): TimelineItem {
  const start = unassignedHour.date.utcZeroHourDate.getTime() + timezoneOffset
  return {
    kind: "unassigned-hour",
    unassignedHour: unassignedHour,
    id: `unassigned-hour-${getMemberDateKey(unassignedHour.member.workspaceMemberId, unassignedHour.date)}`,
    group: toUnassignedGroupId(unassignedHour.member),
    title: roundAtThirdDecimalPoint(unassignedHour.hour),
    start_time: start,
    end_time: start + dayms,
    itemProps: {
      style: {
        background: "none",
        border: "none",
        color: isUnassignedHourNearlyEqual0(unassignedHour.hour) ? "black" : "red",
        paddingRight: "4px",
        paddingTop: "2px",
        textAlign: "right",
      },
    },
  }
}

const roundAtThirdDecimalPoint = (n: number): number => Math.round(n * 100) / 100

// tooltipの表示のためにデフォルトのitemRendererにdata-tip属性を追加している
// TimelineItemのitemPropsにdata-tip属性を入れられなかったのでitemRendererごと入れ替えて対応した
// デフォルトのitemRendererは https://github.com/namespace-ee/react-calendar-timeline/blob/master/src/lib/items/defaultItemRenderer.js
export const itemRenderer = (props: ReactCalendarItemRendererProps<TimelineItem>): React.ReactNode | undefined => {
  // TODO 営業日の日数で割るのを今は単純に土日除いた5で割っているが、将来的には祝日や会社など固有の休日設定に対応する
  const weekdayCount = 5
  const getDataTip = (item: TimelineItem): { "data-tip"?: string } => {
    switch (item.kind) {
      case "actual-total-job": {
        const total = roundAtThirdDecimalPoint(toHours(item.actualTotalMs))
        const sumOfWeek = roundAtThirdDecimalPoint(toHours(item.sumOfWeekMs))
        const average = roundAtThirdDecimalPoint(toHours(item.sumOfWeekMs) / weekdayCount)
        return {
          "data-tip": `稼働時間: ${total} 時間<br />稼働時間(週)<br />合計: ${sumOfWeek} 時/週<br />平均: ${average} 時/日`,
        }
      }
      case "expected-total-job": {
        const total = roundAtThirdDecimalPoint(item.expectedTotalHours)
        const sumOfWeek = roundAtThirdDecimalPoint(item.sumOfWeekHours)
        const average = roundAtThirdDecimalPoint(item.sumOfWeekHours / weekdayCount)
        return {
          "data-tip": `稼働時間: ${total} 時間<br />稼働時間(週)<br />合計: ${sumOfWeek} 時/週<br />平均: ${average} 時/日`,
        }
      }
      case "expected": {
        const scheduleValue = roundAtThirdDecimalPoint(item.schedule.value)
        const scheduleHours = expectedWorkingHoursOf(item.schedule, item.expected)
        const detail =
          item.schedule.type === "hours"
            ? `スケジュールの種類: 時間指定<br />時間: ${scheduleValue} 時間`
            : `スケジュールの種類: 割合指定<br />割合: ${scheduleValue} %`
        return {
          "data-tip": `予定稼働時間: ${
            item.expected ? fixExpectedWorkingHours(item.expected) : 0
          } 時間<br />スケジュール時間: ${scheduleHours} 時間<br />--------<br />${detail}`,
        }
      }
      default:
        return {}
    }
  }

  // 以降はdata-tipを追加した以外はdefaultItemRendererと同じコード
  const { left: leftResizeProps, right: rightResizeProps } = props.getResizeProps()
  return (
    <div {...props.getItemProps(props.item.itemProps as GetItemsProps)}>
      {props.itemContext.useResizeHandle ? <div {...leftResizeProps} /> : ""}

      <div
        className="rct-item-content"
        style={{ maxHeight: `${props.itemContext.dimensions.height}` }}
        {...getDataTip(props.item)}
      >
        {props.itemContext.title}
      </div>

      {props.itemContext.useResizeHandle ? <div {...rightResizeProps} /> : ""}
    </div>
  )
}

const isPastTime = (endTimeOffset: number): boolean => endTimeOffset <= timezoneToday
const isTodayTime = (startTimeOffset: number, endTimeOffset: number): boolean =>
  startTimeOffset <= timezoneToday && timezoneToday < endTimeOffset

export type CalendarProps = {
  members: m.WorkspaceMember[]
  jobs: m.Job[]
  expecteds: m.ExpectedWorkingTime[]
  actuals: m.ActualWorkingTime[]
  schedules: m.Schedule[]
  unassignedHours: UnassignedHour[]
  rootJobSum: m.RootJobSum
  mode: "job" | "member"
  defaultStartUnixtimeMs: number
  defaultEndUnixtimeMs: number
  onClick?: (time: number, group: TimelineGroup, item: TimelineItem | null) => void
  onBoundsChange?: (start: number, end: number) => void
}

export const Calendar: React.FC<CalendarProps> = (props) => {
  const [loading, setLoading] = useState(true)
  const [expandeds, setExpandeds] = useState(new Set<string>())
  const [requiresRebuildTooltip, setRequiresRebuildTooltip] = useState(true)

  const mode = props.mode
  const schedulesByJob = useMemo(() => groupBy(props.schedules, (x) => x.jobId), [props.schedules])
  const schedulesByMember = useMemo(() => groupBy(props.schedules, (x) => x.workspaceMemberId), [props.schedules])
  const actualsByJob = useMemo(() => groupBy(props.actuals, (x) => x.jobId), [props.actuals])
  const actualsByMember = useMemo(() => groupBy(props.actuals, (x) => x.workspaceMemberId), [props.actuals])
  const groups = useMemo(() => {
    switch (mode) {
      case "job": {
        const jobs = props.jobs.flatMap((job) => {
          const parent = jobToGroup(job, props.rootJobSum)
          const schedules = schedulesByJob.get(job.jobId)
          const actuals = actualsByJob.get(job.jobId)
          if (expandeds.has(parent.id) && (schedules != null || actuals != null)) {
            const memberSet = new Set([
              ...(schedules?.map((x) => x.workspaceMemberId) ?? []),
              ...(actuals?.map((x) => x.workspaceMemberId) ?? []),
            ])
            return [
              parent,
              ...props.members.flatMap((x) => {
                if (!memberSet.has(x.workspaceMemberId)) {
                  return []
                }
                return [memberToGroup(x, props.rootJobSum, parent)]
              }),
            ]
          }
          return [parent]
        })
        const unassignedParent = createGroupUnassignedParent()
        const isUnassignedParentExpanded = expandeds.has(unassignedParent.id)
        const unassignedSet = isUnassignedParentExpanded
          ? new Set(
              props.unassignedHours
                .filter((x) => !isUnassignedHourNearlyEqual0(x.hour))
                .map((x) => x.member.workspaceMemberId)
            )
          : new Set()
        const unassignedGroups = isUnassignedParentExpanded
          ? props.members
              .filter((x) => unassignedSet.has(x.workspaceMemberId))
              .map((member) => memberToGroupUnassigned(member, unassignedParent))
          : []
        return [unassignedParent, ...unassignedGroups, ...jobs]
      }
      case "member":
        return props.members.flatMap((member) => {
          const parent = memberToGroup(member, props.rootJobSum)
          const schedules = schedulesByMember.get(member.workspaceMemberId)
          const actuals = actualsByMember.get(member.workspaceMemberId)
          const unassigned = member.status === "active" ? [memberToGroupUnassigned(member, parent)] : []
          if (expandeds.has(parent.id) && (schedules != null || actuals != null || 0 < unassigned.length)) {
            const jobSet = new Set([...(schedules?.map((x) => x.jobId) ?? []), ...(actuals?.map((x) => x.jobId) ?? [])])
            return [
              parent,
              ...unassigned,
              ...props.jobs.flatMap((x) => {
                if (!jobSet.has(x.jobId)) {
                  return []
                }
                return [jobToGroup(x, props.rootJobSum, parent)]
              }),
            ]
          }
          return [parent]
        })
      default:
        return assertNever(mode)
    }
  }, [
    mode,
    props.members,
    props.jobs,
    props.unassignedHours,
    props.rootJobSum,
    expandeds,
    schedulesByJob,
    actualsByJob,
    schedulesByMember,
    actualsByMember,
  ])
  const clickableGroupMap = useMemo(
    () => new Map(groups.filter(isClickableTimelineGroup).map((x) => [x.id, x])),
    [groups]
  )
  const actualDays = useMemo(() => getActualDays(props.actuals), [props.actuals])
  const actualDaysByDayByJob = useMemo(
    () => groupBy(mode === "job" ? actualDays : [], (x) => dateMemberKey(new Date(x[1]), x[0].jobId)),
    [actualDays, mode]
  )
  const scheduleItems = useMemo(
    () => schedulesToItems(props.schedules, props.expecteds, actualDaysByDayByJob, mode),
    [actualDaysByDayByJob, mode, props.expecteds, props.schedules]
  )
  const actualsItems = useMemo(
    () => actualsToItems(actualDays, actualDaysByDayByJob, mode),
    [actualDays, actualDaysByDayByJob, mode]
  )
  const unassignedItems = useMemo(() => props.unassignedHours.map(unassignedHourToItem), [props.unassignedHours])
  const sumOfUnassignedHoursItems = useMemo(
    () => getSumOfUnassignedHoursItems(props.unassignedHours, mode),
    [props.unassignedHours, mode]
  )
  const items = useMemo(
    () => [...scheduleItems, ...actualsItems, ...unassignedItems, ...sumOfUnassignedHoursItems],
    [scheduleItems, actualsItems, unassignedItems, sumOfUnassignedHoursItems]
  )
  const itemMap = useMemo(() => new Map(items.map((x) => [x.id, x])), [items])

  const daySumOfExpecteds = useMemo(() => calcDaySumOfExpecteds(props.expecteds), [props.expecteds])

  const verticalLineClassNamesForTime = useCallback((startTime: number, endTime: number) => {
    const isPast = endTime - timezoneOffset <= timezoneToday
    return [isPast ? "af-past" : ""]
  }, [])

  const dayIntervalRenderer = useCallback((props?: IntervalRenderer<undefined>) => {
    if (props == null) {
      return null
    }
    const startTime = props.intervalContext.interval.startTime - timezoneOffset
    const endTime = props.intervalContext.interval.endTime - timezoneOffset
    const isPast = isPastTime(endTime)
    const isToday = isTodayTime(startTime, endTime)
    const extClsName = isToday ? " aw-today" : isPast ? " aw-past" : ""
    const [day, dayOfWeek] = props.intervalContext.intervalText.split(":")
    const isSundayOrSaturDay = dayOfWeek === "土" || dayOfWeek === "日"
    const redColorClsName = isSundayOrSaturDay ? " aw-red-color" : ""
    return (
      <div
        {...props.getIntervalProps()}
        className={"rct-dateHeader aw-day" + extClsName + redColorClsName}
        onClick={() => undefined}
      >
        <div>{day}</div>
        <div className="aw-day-of-week">&nbsp;({dayOfWeek})</div>
      </div>
    )
  }, [])
  const dayExpectedIntervalRenderer = useCallback(
    (props?: IntervalRenderer<undefined>) => {
      if (props == null) {
        return null
      }
      const startTime = props.intervalContext.interval.startTime - timezoneOffset
      const endTime = props.intervalContext.interval.endTime - timezoneOffset
      const dayUnixtimeMs = toDateOnlyUnixtimeMs(startTime)
      const isPast = isPastTime(endTime)
      const isToday = isTodayTime(startTime, endTime)
      const extClsName = isToday ? " aw-today" : isPast ? " aw-past" : ""
      // TODO 過去の日付でも予定と空き時間の合計を表示
      const originalValue = isPast ? undefined : daySumOfExpecteds.get(dayUnixtimeMs)
      const value = isPast ? "" : originalValue ? Math.round(originalValue) : 0
      const dataTip = originalValue ? { "data-tip": `${originalValue}` } : {}
      return (
        <div
          {...props.getIntervalProps()}
          className={"rct-dateHeader aw-expected" + extClsName}
          onClick={() => undefined}
        >
          <span {...dataTip}>{value}</span>
        </div>
      )
    },
    [daySumOfExpecteds]
  )
  const dayTotalIntervalRenderer = useCallback((props?: IntervalRenderer<undefined>) => {
    if (props == null) {
      return null
    }
    const startTime = props.intervalContext.interval.startTime - timezoneOffset
    const endTime = props.intervalContext.interval.endTime - timezoneOffset
    const isPast = isPastTime(endTime)
    const isToday = isTodayTime(startTime, endTime)
    const extClsName = isToday ? " aw-today" : isPast ? " aw-past" : ""
    return (
      <div
        {...props.getIntervalProps()}
        className={"rct-dateHeader aw-remain" + extClsName}
        onClick={() => undefined}
      ></div>
    )
  }, [])
  const toggleExpanded = useCallback(
    (id: string) => {
      const newExpandeds = new Set(expandeds)
      if (expandeds.has(id)) {
        newExpandeds.delete(id)
      } else {
        newExpandeds.add(id)
      }
      setExpandeds(newExpandeds)
    },
    [expandeds, setExpandeds]
  )
  const groupRenderer = useCallback(
    (rendererProps: ReactCalendarGroupRendererProps<TimelineGroup>) => {
      const schedules =
        rendererProps.group.kind === "job"
          ? schedulesByJob.get(rendererProps.group.job.jobId)
          : rendererProps.group.kind === "member"
          ? schedulesByMember.get(rendererProps.group.member.workspaceMemberId)
          : undefined
      const actuals =
        rendererProps.group.kind === "job"
          ? actualsByJob.get(rendererProps.group.job.jobId)
          : rendererProps.group.kind === "member"
          ? actualsByMember.get(rendererProps.group.member.workspaceMemberId)
          : undefined
      const memberId = rendererProps.group.kind === "member" ? rendererProps.group.member.workspaceMemberId : null
      const existsUnassignedHour =
        rendererProps.group.kind === "member" &&
        props.unassignedHours.some((x) => x.member.workspaceMemberId === memberId)

      // 合計のデータを取得
      const groupTotal =
        (rendererProps.group.kind === "job" || rendererProps.group.kind === "member") &&
        rendererProps.group.total != null
          ? rendererProps.group.total
          : ""
      const jobTargetHours =
        rendererProps.group.kind === "job" &&
        rendererProps.group.total != null &&
        !rendererProps.group.parent &&
        rendererProps.group.job.targetHours
          ? rendererProps.group.job.targetHours - rendererProps.group.total
          : ""

      const canExpand =
        (rendererProps.group.kind === mode &&
          ((schedules != null && 0 < schedules.length) ||
            (actuals != null && 0 < actuals.length) ||
            existsUnassignedHour)) ||
        (rendererProps.group.kind === "unassignedParent" &&
          props.unassignedHours.some((x) => !isUnassignedHourNearlyEqual0(x.hour)))

      const expandedKey = rendererProps.group.id
      const expanded = expandeds.has(expandedKey)
      const originalTitle = rendererProps.group.title?.toString() ?? ""
      // TODO 雑に文字列カットしているのをちゃんとした実装にする
      const title = originalTitle.length <= 16 ? originalTitle : `${originalTitle.slice(0, 16)}..`
      const url =
        rendererProps.group.kind === "job"
          ? `/workspaces/${rendererProps.group.job.workspaceId}/project?projectId=${rendererProps.group.job.jobId}`
          : rendererProps.group.kind === "member"
          ? `/workspaces/${rendererProps.group.member.workspaceId}/members/${rendererProps.group.member.workspaceMemberId}`
          : mode === "job" && rendererProps.group.kind === "unassigned"
          ? `/workspaces/${rendererProps.group.member.workspaceId}/members/${rendererProps.group.member.workspaceMemberId}`
          : null
      const link = url ? (
        <Link to={url} target="_blank" rel="noreferrer">
          <i className="external alternate icon"></i>
        </Link>
      ) : undefined
      const helpContent =
        rendererProps.group.kind === "unassignedParent" ? (
          <div>
            <p>
              「未割当」プロジェクトは、予定稼働時間に対するスケジュール時間の過不足の合計を表示する特別なプロジェクトです。
            </p>
            <ul>
              <li>正の値の場合 : 予定稼働時間に対してスケジュール時間が不足しています</li>
              <li>負の値の場合 : 予定稼働時間に対してスケジュール時間が超過しています</li>
              <li>黒文字の 0 の場合 : すべてのメンバーについて、予定稼働時間とスケジュール時間が一致しています</li>
              <li>
                赤文字の <span className="ui red text">0</span> の場合 :
                予定稼働時間とスケジュール時間の合計は0ですが、スケジュール時間が不足しているメンバーと超過しているメンバーの両方が存在します
              </li>
            </ul>
            <p>
              また、メンバーごとのスケジュール時間の過不足が「未割当」プロジェクトの子項目となっています。
              <br />
              過不足がないメンバーは子項目として表示されません。
            </p>
          </div>
        ) : rendererProps.group.kind === "unassigned" && rendererProps.group.parent?.kind !== "unassignedParent" ? (
          <div>
            <p>
              「未割当」プロジェクトは、予定稼働時間に対するスケジュール時間の過不足の合計を表示する特別なプロジェクトです。
            </p>
            <ul>
              <li>正の値の場合 : 予定稼働時間に対してスケジュール時間が不足しています</li>
              <li>負の値の場合 : 予定稼働時間に対してスケジュール時間が超過しています</li>
              <li>0 の場合 : 予定稼働時間とスケジュール時間が一致しています</li>
            </ul>
          </div>
        ) : undefined
      const help = helpContent ? (
        <div>
          <Popup trigger={<i className="green question circle link icon"></i>} flowing hoverable inverted>
            <Popup.Content>{helpContent}</Popup.Content>
          </Popup>
        </div>
      ) : undefined

      const onClick = (): void => {
        canExpand && toggleExpanded(expandedKey)
        setRequiresRebuildTooltip(true)
      }
      return (
        <div className="aw-group-row">
          <div className="aw-group-title-container">
            <div
              className={"aw-group-title" + (canExpand ? " aw-pointer" : "")}
              style={{ paddingLeft: rendererProps.group.indent * 30 }}
              onClick={onClick}
            >
              <span className="aw-group-title-expand-icon">
                {canExpand && (expanded ? <ifa.FaAngleDown /> : <ifa.FaAngleRight />)}
              </span>
              <span data-tip={rendererProps.group.title}>{title}</span>
            </div>
            {rendererProps.group.kind === "job" && rendererProps.group.job.status === "archived" && (
              <span data-tip="アーカイブ済み" className="aw-group-job-active-icon">
                <i className="folder icon"></i>
              </span>
            )}
            {link}
            {help}
          </div>
          {mode === "job" && <div className="aw-group-remain">{jobTargetHours}</div>}
          <div className="aw-group-total">{groupTotal}</div>
        </div>
      )
    },
    [
      schedulesByJob,
      schedulesByMember,
      actualsByJob,
      actualsByMember,
      props.unassignedHours,
      mode,
      expandeds,
      toggleExpanded,
    ]
  )
  const onClick = props.onClick
  const onItemSelect = useCallback(
    (itemId: string, e: React.SyntheticEvent, time: number) => {
      if (onClick == null) {
        return
      }
      const item = itemMap.get(itemId)
      if (item == null) {
        return
      }
      const group = clickableGroupMap.get(`${item.group}`)
      if (group == null) {
        return
      }
      onClick(time, group, item)
    },
    [itemMap, clickableGroupMap, onClick]
  )
  const onCanvasClick = useCallback(
    (groupId: string, time: number, e: React.SyntheticEvent) => {
      if (onClick == null) {
        return
      }
      const group = clickableGroupMap.get(groupId)
      if (group == null) {
        return
      }
      onClick(time, group, null)
    },
    [clickableGroupMap, onClick]
  )
  const selected = useMemo(() => [], [])
  const onBoundsChange = useCallback(
    (startUnixtimeMs: number, endUnixtimeMs: number): void => {
      if (props.onBoundsChange) props.onBoundsChange(startUnixtimeMs, endUnixtimeMs)
      setRequiresRebuildTooltip(true)
    },
    [props]
  )

  useEffect(() => {
    const handler = setTimeout(() => {
      if (loading && props.onBoundsChange) {
        props.onBoundsChange(props.defaultStartUnixtimeMs, props.defaultEndUnixtimeMs)
        setRequiresRebuildTooltip(true)
      } else if (requiresRebuildTooltip) {
        setRequiresRebuildTooltip(false)
        ReactTooltip.rebuild() // 動的に生成したDOMに対してtooltipの表示を行うために必要
      }
      setLoading(false)
    })
    return () => clearTimeout(handler)
  }, [
    loading,
    props.onBoundsChange,
    props.defaultStartUnixtimeMs,
    props.defaultEndUnixtimeMs,
    props,
    requiresRebuildTooltip,
  ])

  if (groups.length === 0) {
    return null
  }

  return (
    <Timeline
      groups={groups}
      items={items}
      canChangeGroup={false}
      canMove={false}
      canResize={false}
      defaultTimeStart={defaultTimeStart}
      defaultTimeEnd={defaultTimeEnd}
      itemRenderer={itemRenderer}
      groupRenderer={groupRenderer}
      maxZoom={80 * dayms}
      minZoom={30 * dayms}
      onBoundsChange={onBoundsChange}
      onCanvasClick={onCanvasClick}
      onItemSelect={onItemSelect}
      selected={selected}
      sidebarWidth={400}
      verticalLineClassNamesForTime={verticalLineClassNamesForTime}
    >
      {/* TODO classNameを指定すればtooltipの文字を左寄せにできると思ったがうまくいっていない */}
      <ReactTooltip multiline={true} delayShow={500} />
      <TimelineHeaders>
        <SidebarHeader>
          {(props) => {
            return (
              <div {...props.getRootProps()}>
                <div className="aw-sidebar-header"></div>
                <div className="aw-sidebar-header"></div>
                <div className="aw-sidebar-header-text">予定稼働時間</div>
                <div className="aw-sidebar-header-row">
                  <div className="aw-group-mode-header">{mode === "job" ? "プロジェクト" : "メンバー"}</div>
                  {mode === "job" && <div className="aw-group-remain-header">不足</div>}
                  <div className="aw-group-total-header">合計</div>
                </div>
              </div>
            )
          }}
        </SidebarHeader>
        <DateHeader unit="month" labelFormat="YYYY/MM" />
        <DateHeader unit="day" labelFormat="D:dd" intervalRenderer={dayIntervalRenderer} />
        <DateHeader unit="day" labelFormat="D" intervalRenderer={dayExpectedIntervalRenderer} />
        <DateHeader unit="day" labelFormat="D" intervalRenderer={dayTotalIntervalRenderer} />
      </TimelineHeaders>
    </Timeline>
  )
}

export default Calendar
