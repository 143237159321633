import { WorkspaceMemberApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { WorkspaceMember, PutWorkspaceMemberRequest } from "model"

export interface WorkspaceMemberResponse {
  workspaceMemberId: string
  workspaceId: string
  accountId: string
  userId: string
  username: string
  role: "worker" | "manager" | "owner"
  status: "active" | "inactive" | "waiting_response"
  inactivatedDatetime: string
  createdDatetime: string
  updatedDatetime: string
}

export const toWorkspaceMember = (data: WorkspaceMemberResponse): WorkspaceMember =>
  Object.assign(data, {
    inactivatedDatetime: data.inactivatedDatetime ? new Date(data.inactivatedDatetime) : undefined,
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as WorkspaceMember

export class WorkspaceMemberApiClientImpl implements WorkspaceMemberApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  putWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    body: PutWorkspaceMemberRequest
  ): Promise<WorkspaceMember> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}`
    const res = await this.client.putWithAuth<WorkspaceMemberResponse>(path, body)
    return toWorkspaceMember(res.data)
  }

  getWorkspaceMember = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceMember> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}`
    const res = await this.client.getWithAuth<WorkspaceMemberResponse>(path)
    return toWorkspaceMember(res.data)
  }

  getWorkspaceMembers = async (workspaceId: string, includesInactiveMembers = false): Promise<WorkspaceMember[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/members`
    const param = new Map<string, string>([["includes_inactive_members", includesInactiveMembers.toString()]])
    const res = await this.client.getWithAuth<WorkspaceMemberResponse[]>(path, param)
    return res.data.map(toWorkspaceMember)
  }

  getWorkspaceMembersByTagId = async (workspaceId: string, workspaceTagId: string): Promise<WorkspaceMember[]> => {
    const res = await this.client.getWithAuth<WorkspaceMemberResponse[]>(
      `/api/v1/workspaces/${workspaceId}/tags/${workspaceTagId}/members`
    )
    return res.data.map(toWorkspaceMember)
  }

  deleteWorkspaceMember = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceMember> => {
    const res = await this.client.deleteWithAuth<WorkspaceMemberResponse>(
      `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}`
    )
    return toWorkspaceMember(res.data)
  }
}
