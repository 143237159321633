import React from "react"
import { Dimmer, Loader } from "semantic-ui-react"

type LoadingDimmerProps = {
  isLoading: boolean
  style?: React.CSSProperties
}

export const LoadingDimmer: React.FC<LoadingDimmerProps> = React.memo((props) => {
  return (
    <div>
      <Dimmer.Dimmable style={props.style} dimmed={props.isLoading}>
        <Dimmer active={props.isLoading}>
          {/* "active" is a workaround for https://github.com/Semantic-Org/Semantic-UI-React/issues/3910 */}
          <Loader active></Loader>
        </Dimmer>
        {props.children}
      </Dimmer.Dimmable>
    </div>
  )
})

export default LoadingDimmer
