import { ActualWorkingTimeApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { ActualWorkingTime, PutActualWorkingTimeRequest } from "model"

interface ActualWorkingTimeResponse {
  workspaceId: string
  actualWorkingTimeId: string
  jobId: string
  workspaceMemberId: string
  startDatetime: string
  endDatetime: string
  note: string
  createdDatetime: string
  updatedDatetime: string
}

const toActualWorkingTime = (data: ActualWorkingTimeResponse): ActualWorkingTime =>
  Object.assign(data, {
    startDatetime: data.startDatetime ? new Date(data.startDatetime) : undefined,
    endDatetime: data.endDatetime ? new Date(data.endDatetime) : undefined,
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as ActualWorkingTime

export class ActualWorkingTimeApiClientImpl implements ActualWorkingTimeApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  list = async (workspaceId: string, termStart: Date, termEnd: Date, jobId?: string): Promise<ActualWorkingTime[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/actual-working-times`
    const params = new Map<string, string>()
    params.set("term_start", termStart.toISOString())
    params.set("term_end", termEnd.toISOString())
    if (jobId) params.set("job_id", jobId)
    const res = await this.client.getWithAuth<ActualWorkingTimeResponse[]>(path, params)
    return res.data.map(toActualWorkingTime)
  }

  listSum = async (workspaceId: string, jobId?: string): Promise<Record<string, Record<string, number>>> => {
    const path = `/api/v1/workspaces/${workspaceId}/sum-of-actual-working-times`
    const params = new Map<string, string>()
    if (jobId) params.set("job_id", jobId)
    const res = await this.client.getWithAuth<Record<string, Record<string, number>>>(path, params, undefined, "keep")
    return res.data
  }

  listByMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    termStart?: Date,
    termEnd?: Date
  ): Promise<ActualWorkingTime[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}/actual-working-times`
    const params = new Map<string, string>()
    if (termStart) params.set("term_start", termStart.toISOString())
    if (termEnd) params.set("term_end", termEnd.toISOString())
    const res = await this.client.getWithAuth<ActualWorkingTimeResponse[]>(path, params)
    return res.data.map(toActualWorkingTime)
  }

  put = async (request: PutActualWorkingTimeRequest): Promise<ActualWorkingTime> => {
    const path = `/api/v1/workspaces/${request.workspaceId}/members/${request.workspaceMemberId}/actual-working-times/${request.actualWorkingTimeId}`
    const body = {
      ...request,
      actualWorkingTimeId: undefined,
      workspaceId: undefined,
      workspaceMemberId: undefined,
      startDatetime: request.startDatetime.toISOString(),
      endDatetime: request.endDatetime.toISOString(),
      createdDatetime: undefined,
      updatedDatetime: undefined,
      lastUpdatedDatetime: request.updatedDatetime?.toISOString(),
    }
    const res = await this.client.putWithAuth<ActualWorkingTimeResponse>(path, body)
    return toActualWorkingTime(res.data)
  }

  delete = async (
    workspaceId: string,
    workspaceMemberId: string,
    actualWorkingTimeId: string
  ): Promise<ActualWorkingTime> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}/actual-working-times/${actualWorkingTimeId}`
    const res = await this.client.deleteWithAuth<ActualWorkingTimeResponse>(path)
    return toActualWorkingTime(res.data)
  }
}
