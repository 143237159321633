import { WorkspaceTagApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { WorkspaceTag } from "model"

export class WorkspaceTagApiClientImpl implements WorkspaceTagApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  getWorkspaceTags = async (workspaceId: string): Promise<WorkspaceTag[]> => {
    return this.client.getWithAuth<WorkspaceTag[]>(`/api/v1/workspaces/${workspaceId}/tags`).then((x) => x.data)
  }

  getWorkspaceMemberTags = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceTag[]> => {
    return this.client
      .getWithAuth<WorkspaceTag[]>(`/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}/tags`)
      .then((x) => x.data)
  }
}
