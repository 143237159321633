import { styled } from "linaria/react"
import React, { useState, useEffect, useCallback, useMemo } from "react"
import ReactSelect, { StylesConfig } from "react-select"

import { clients } from "api/clients"
import MemberInvitationModal from "component/page/workspaceMembers/memberInvitationModal"
import { MembersTable } from "component/page/workspaceMembers/membersTable"
import * as m from "model"
import { AppStore, ModalState } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"
import * as workspaceTagService from "service/workspaceTagService"

export type WorkspaceMembersPageProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  modal: ModalState
  workspaceId: string | null
  myMember: m.WorkspaceMember | null
}

export interface WorkspaceMember {
  workspaceMemberId: string
  userId: string
  username: string
  role: m.Role
  company: string
  status: m.WorkspaceMemberStatus
  updatedDatetime: string
}

export type Role = m.Role | "all"
export type RoleOption = {
  label: string
  value: Role
}
const stylesConfig: StylesConfig<RoleOption, false> = {
  container: (styles) => ({ ...styles, width: 170 }),
}
export const filterRoles: RoleOption[] = [
  { value: "all", label: "役割 : すべて" },
  { value: "worker", label: "役割 : ワーカー" },
  { value: "manager", label: "役割 : 管理者" },
  { value: "owner", label: "役割 : オーナー" },
]
export const assignableRoles: RoleOption[] = [
  { value: "worker", label: "ワーカー" },
  { value: "manager", label: "管理者" },
  { value: "owner", label: "オーナー" },
]

const FlexContainer = styled.div`
  display: flex;
  margin-top: 20px;
`
const TextFilter = styled.div`
  margin-right: 15px;
`
const MemberInvitationButton = styled.button`
  &.ui.button {
    margin-left: auto;
  }
`

export const WorkspaceMembersPage: React.FC<WorkspaceMembersPageProps> = React.memo((props) => {
  const [members, setMembers] = useState<WorkspaceMember[]>([])
  const [textFilter, setTextFilter] = useState<string>("")
  const [roleFilter, setRoleFilter] = useState<Role>("all")
  const loadMembers = async (workspaceId: string): Promise<WorkspaceMember[]> => {
    const getMembers = clients.workspaceMember.getWorkspaceMembers(workspaceId, true)
    const getTags = clients.workspaceTag.getWorkspaceTags(workspaceId)
    const [members, tags] = await Promise.all([getMembers, getTags])
    const companyMap = await workspaceTagService.getCompanyMap(tags)

    return members.map((member) => {
      return {
        workspaceMemberId: member.workspaceMemberId,
        userId: member.userId,
        username: member.username,
        role: member.role,
        company: companyMap.get(member.workspaceMemberId) ?? "",
        status: member.status,
        updatedDatetime: member.updatedDatetime.toISOString(),
      }
    })
  }

  const showModal = useCallback(() => {
    const modal: ModalState = {
      kind: "workspace-member-invitation",
      userId: "",
      role: "worker",
      errorMessage: undefined,
    }
    props.dispatch.apply(props.reducer.setModal(modal))
  }, [props.dispatch, props.reducer])

  const isOwner = useMemo<boolean>(() => props.myMember?.role === "owner", [props.myMember])

  useEffect(() => {
    if (props.workspaceId !== null) {
      const workspaceId = props.workspaceId
      const timerId = setTimeout(() => {
        loadMembers(workspaceId).then((members) => setMembers(members))
      })
      return () => clearTimeout(timerId)
    }
  }, [props.workspaceId])

  return (
    <div className="ui container">
      <FlexContainer>
        <TextFilter className="ui icon input">
          <input
            type="text"
            placeholder="ユーザー名/会社名"
            value={textFilter}
            onChange={(e) => setTextFilter(e.target.value)}
          ></input>
          <i className="search icon"></i>
        </TextFilter>
        <ReactSelect
          options={filterRoles}
          onChange={(x) => {
            if (x) setRoleFilter(x.value)
          }}
          value={filterRoles.find((role) => role.value === roleFilter)}
          styles={stylesConfig}
        />
        {props.myMember?.role === "owner" && (
          <MemberInvitationButton className="ui olive ok button rounded-pill" onClick={showModal}>
            メンバーを追加
          </MemberInvitationButton>
        )}
      </FlexContainer>
      <MembersTable
        workspaceId={props.workspaceId}
        myWorkspaceMemberId={props.myMember?.workspaceMemberId ?? null}
        members={members}
        isOwner={isOwner}
        setMembers={setMembers}
        assignableRoles={assignableRoles}
        textFilter={textFilter}
        roleFilter={roleFilter}
      />
      <MemberInvitationModal
        dispatch={props.dispatch}
        reducer={props.reducer}
        workspaceId={props.workspaceId}
        assignableRoles={assignableRoles}
        modal={props.modal !== null && props.modal.kind === "workspace-member-invitation" ? props.modal : null}
        members={members}
        setMembers={setMembers}
      />
    </div>
  )
})

export default WorkspaceMembersPage
