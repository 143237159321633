import { css } from "linaria"
import { styled } from "linaria/react"
import React, { useEffect, useState } from "react"
import { DatesRangeInput } from "semantic-ui-calendar-react"
import { v4 as uuidv4 } from "uuid"

import * as du from "utils/date"

import "moment/locale/ja"

const InputStyle = css`
  .ui.input {
    width: 220px;
  }
`
const PickerContainer = styled.div`
  .ui.table > thead > tr:nth-child(3) {
    th:first-of-type {
      color: red;
    }
    th:last-of-type {
      color: red;
    }
  }
`

export const hasValidDatesRangeFormat = (value: string): boolean =>
  /^\d{4}\/\d{2}\/\d{2} - \d{4}\/\d{2}\/\d{2}$/.test(value)
export const getDatesRangeString = (start: Date, end: Date): string => {
  const startStr = du.toZeroPaddedLocalDateString(start)
  const endStr = du.toZeroPaddedLocalDateString(end)

  return `${startStr} - ${endStr}`
}

type DatesRangePickerProps = {
  value: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onChange: (e: React.SyntheticEvent<HTMLElement>, data: any) => void
  placeholder?: string
}

export const DatesRangePicker: React.FC<DatesRangePickerProps> = React.memo((props) => {
  const [pickerId, setPickerId] = useState<string | undefined>(undefined)
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setPickerId(uuidv4())
  }, [])

  useEffect(() => {
    if (pickerId) {
      setMountNode(document.getElementById(pickerId))
    }
  }, [pickerId])

  return (
    <>
      <DatesRangeInput
        className={InputStyle}
        icon="calendar"
        placeholder={props.placeholder}
        localization="ja"
        dateFormat="YYYY/MM/DD"
        value={props.value}
        onChange={props.onChange}
        clearable={true}
        closable={true}
        closeOnMouseLeave={false}
        allowSameEndDate={true}
        mountNode={mountNode}
      />
      <PickerContainer id={pickerId}></PickerContainer>
    </>
  )
})

export default DatesRangePicker
