import { styled } from "linaria/react"
import React, { useCallback, useState, useEffect } from "react"
import ReactModal from "react-modal"
import { v4 as uuidv4 } from "uuid"

import { clients } from "api/clients"
import * as m from "model"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "340px",
    height: "460px",
    padding: 0,
  },
}

const PutJobForm = styled.form`
  height: 100%;
  width: 100%;
`
const PutJobFormHeader = styled.h1`
  padding: 1rem 1rem 0 1rem;
`
const PutJobFormContent = styled.div`
  padding: 0 1rem;
`
const PutJobFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  .ui.button {
    margin-left: 0.75em;
  }
`
const PutJobFormButtons = styled.div`
  display: flex;
  justify-content: right;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
`

type PutJobModalProps = {
  isOpen: boolean
  project: m.Job
  job?: m.Job
  setIsOpen: (value: boolean) => void
  setJobs: (f: (prev: m.Job[]) => m.Job[]) => void
}

const PutJobModal: React.FC<PutJobModalProps> = React.memo((props) => {
  const [jobId, setJobId] = useState<string>("")
  const [jobName, setJobName] = useState<string>("")
  const [externalLinkageUrl, setExternalLinkageUrl] = useState<string>("")

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const putJob = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      setIsLoading(true)

      const body: m.PutJobRequest = {
        jobName: jobName,
        parentJobId: props.project.jobId,
        status: props.job ? props.job.status : "unarchived",
        externalLinkageInfo: externalLinkageUrl ? { url: externalLinkageUrl } : {},
        lastUpdatedDatetime: props.job ? props.job.updatedDatetime : undefined,
      }
      clients.job
        .putJob(props.project.workspaceId, jobId, body)
        .then((newJob) => {
          setJobId(uuidv4())
          setJobName("")
          setExternalLinkageUrl("")
          setErrorMessage("")
          props.setIsOpen(false)
          props.setJobs((jobs) => {
            if (props.job) {
              return jobs.map((x) => (x.jobId === newJob.jobId ? newJob : x))
            } else {
              return jobs.concat(newJob)
            }
          })
        })
        .catch((error: Error) => setErrorMessage(error.message))
        .finally(() => setIsLoading(false))
    },
    [
      props,
      jobId,
      jobName,
      externalLinkageUrl,
      setJobId,
      setJobName,
      setExternalLinkageUrl,
      setIsLoading,
      setErrorMessage,
    ]
  )

  useEffect(() => {
    if (props.job) {
      setJobId(props.job.jobId)
      setJobName(props.job.jobName)
      setExternalLinkageUrl(props.job.externalLinkageInfo.url ?? "")
    } else {
      setJobId(uuidv4())
      setJobName("")
      setExternalLinkageUrl("")
    }
  }, [props.job, setJobId, setJobName, setExternalLinkageUrl])

  return (
    <ReactModal isOpen={props.isOpen} style={modalStyle} onRequestClose={() => props.setIsOpen(false)}>
      <PutJobForm
        className={"ui form" + (isLoading ? " loading" : "") + (errorMessage ? " error" : "")}
        onSubmit={putJob}
      >
        <PutJobFormHeader>ジョブ</PutJobFormHeader>
        <div className="ui divider"></div>
        <PutJobFormContent>
          <div className="required field">
            <label>ジョブID</label>
            <input
              type="text"
              disabled={!!props.job}
              placeholder="例) ed4d8b8a-6cce-4d04-8ea8-058f727ed2af"
              defaultValue={jobId}
              onChange={(e) => setJobId(e.target.value)}
              required
            />
          </div>
          <div className="required field">
            <label>ジョブ名</label>
            <input
              type="text"
              placeholder="例) JOB NAME"
              defaultValue={jobName}
              onChange={(e) => setJobName(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <label>URL</label>
            <input
              type="text"
              placeholder="例) https://annofab.com/porjects/foo"
              defaultValue={externalLinkageUrl}
              onChange={(e) => setExternalLinkageUrl(e.target.value)}
            />
          </div>
        </PutJobFormContent>
        <PutJobFormFooter>
          <div className="ui error message">{errorMessage ?? undefined}</div>
          <PutJobFormButtons>
            <button className="ui olive ok button rounded-pill" type="submit">
              {props.job ? "更新" : "追加"}
            </button>
            <button className="ui orange cancel delete button rounded-pill" onClick={() => props.setIsOpen(false)}>
              キャンセル
            </button>
          </PutJobFormButtons>
        </PutJobFormFooter>
      </PutJobForm>
    </ReactModal>
  )
})

export default PutJobModal
