import { css } from "linaria"
import { styled } from "linaria/react"
import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import { Button, Form, Grid, Header, Message, Segment } from "semantic-ui-react"

import { LoginRequest } from "api"
import { clients } from "api/clients"
import { WorkspaceStore } from "dataStore/workspaceStore"
import { AppStore } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"

export type LoginProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
}

const LoginPage: React.FC<LoginProps> = (props) => {
  const [userId, setUserId] = useState("")
  const [password, setPassword] = useState("")
  const [errorMessage, setErrorMessage] = useState()
  const history = useHistory()

  const login = async (): Promise<void> => {
    const request: LoginRequest = {
      userId: userId,
      password: password,
    }
    const tokens = await clients.login.postLogin(request).catch((e) => {
      setErrorMessage(e.message)
      return undefined
    })
    if (!tokens) return
    props.dispatch.apply(props.reducer.setToken(tokens.idToken))
    props.dispatch.apply(props.reducer.setUserId(userId))

    const lastWorkspaceId = WorkspaceStore.getWorkspaceId()
    const workspaceMembers = await clients.myInfo.getMyWorkspaceMembers()
    const workspaceMember = workspaceMembers.find((x) => x.workspaceId === lastWorkspaceId) ?? workspaceMembers[0]

    props.dispatch.apply(props.reducer.setWorkspaceId(workspaceMember.workspaceId))
    props.dispatch.apply(props.reducer.setWorkspaceMember(workspaceMember))

    WorkspaceStore.setWorkspaceId(workspaceMember.workspaceId)

    const page = workspaceMember.role === "worker" ? "my" : "schedule"
    history.push(`/workspaces/${workspaceMember.workspaceId}/${page}`)
  }

  const signUp = (): void => history.push("sign-up")
  const restPassword = (): void => history.push("reset-password")

  const loginPage = css`
    height: 100vh;
  `
  const formArea = css`
    max-width: 450px;
  `
  const loginForm = css`
    margin: 0 0 1em;
  `
  const ForgetPasswordLink = styled.div`
    font-size: 12px;
    color: #4183c4;
    cursor: pointer;
    text-align: right;
  `

  return (
    <Grid className={loginPage} textAlign="center" verticalAlign="middle">
      <Grid.Column className={formArea}>
        <Segment>
          <Header as="h2" content="ログイン" textAlign="center" />
          {errorMessage && <Message error size="tiny" content={errorMessage} />}
          <Form className={loginForm} size="large" onSubmit={login}>
            <Form.Field>
              <Form.Input
                fluid
                required
                icon="user"
                iconPosition="left"
                placeholder="ユーザーID"
                value={userId}
                onChange={(e) => setUserId(e.target.value)}
              />
            </Form.Field>
            <Form.Field>
              <Form.Input
                fluid
                required
                icon="lock"
                iconPosition="left"
                placeholder="パスワード"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <ForgetPasswordLink onClick={() => restPassword()}>パスワードを忘れた方</ForgetPasswordLink>
            </Form.Field>
            <Button fluid color="olive" type="submit" content="ログイン" />
          </Form>
          <Button fluid basic color="olive" content="新規アカウント登録" onClick={signUp} />
        </Segment>
      </Grid.Column>
    </Grid>
  )
}

export default LoginPage
