import * as m from "model"

export interface UnassignedHour {
  member: m.WorkspaceMember
  date: m.DateOnly
  hour: number
}

// 未割り当て計算の時に未割り当てとみなすしきい値(四捨五入して0.01になる値)
const unassignedHourThreshold = 0.005

export const isUnassignedHourNearlyEqual0 = (hour: number): boolean =>
  -unassignedHourThreshold < hour && hour < unassignedHourThreshold
