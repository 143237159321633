export class HistoriesStore {
  private static keyBases = {
    project: "project-history",
    job: "job-history",
  }

  private projectHistoriesCache: string[] = []
  private jobHistoriesCache: string[] = []

  private static capacity = 100

  constructor(readonly workspaceid: string) {
    this.refresh()
  }

  refresh(): void {
    this.projectHistoriesCache = this.getStorageByKey(HistoriesStore.keyBases.project)
    this.jobHistoriesCache = this.getStorageByKey(HistoriesStore.keyBases.job)
  }

  private getKey(keyBase: string): string {
    return `${keyBase}/${this.workspaceid}`
  }

  private getStorageByKey(keyBase: string): string[] {
    const value = localStorage.getItem(this.getKey(keyBase))
    if (!value) return []
    const items = JSON.parse(value)
    return (Array.isArray(items) ? items : Object.values(items)).map((item) => item.toString())
  }

  get projectHisotries(): string[] {
    return this.projectHistoriesCache
  }
  set projectHisotries(value: string[]) {
    const slicedValue = value.slice(0, HistoriesStore.capacity)
    this.projectHistoriesCache = slicedValue
    localStorage.setItem(this.getKey(HistoriesStore.keyBases.project), JSON.stringify(slicedValue))
  }

  addProjectHisotry(projectId: string): string[] {
    const history = [projectId, ...this.projectHisotries]
    this.projectHisotries = history
    return this.projectHisotries
  }

  get jobHistories(): string[] {
    return this.jobHistoriesCache
  }
  set jobHistories(value: string[]) {
    const slicedValue = value.slice(0, HistoriesStore.capacity)
    this.jobHistoriesCache = slicedValue
    localStorage.setItem(this.getKey(HistoriesStore.keyBases.job), JSON.stringify(slicedValue))
  }

  addJobHisotry(jobId: string): string[] {
    const history = [jobId, ...this.jobHistories]
    this.jobHistories = history
    return this.jobHistories
  }
}
