import moment from "moment"
import QueryString from "qs"
import React, { useEffect, useMemo, useState, useCallback } from "react"
import { useHistory } from "react-router-dom"
import { Grid, Container } from "semantic-ui-react"

import { clients } from "api/clients"
import DatePicker from "component/common/DatePicker"
import * as c from "component/common/expectedWorkingTime/common"
import { WeekSummaryColumn, WeekHeaderColumn, WeekHoursColumn } from "component/common/expectedWorkingTime/weekColumn"
import { WeekMenuColumn } from "component/common/expectedWorkingTime/weekMenu"
import * as m from "model"
import { formatDate } from "utils/datePicker"

type ExpectedWorkingTimeCalendarProps = {
  workspaceId: string
  workspaceMember: m.WorkspaceMember
  weeks?: number
  isReadOnly: boolean
}

const today = m.DateOnly.fromDateAsLocal(new Date())
const startDateQueryParameter = "start_date"

function getDateFromQuery(queryString: string): m.DateOnly | null {
  const queries = QueryString.parse(queryString)
  const date = queries[startDateQueryParameter]
  const dateString = Array.isArray(date) ? date[0] : date
  if (typeof dateString !== "string") return null
  const parseResult = moment(dateString, "YYYY-MM-DD", true)
  return parseResult.isValid() ? m.DateOnly.fromDateAsLocal(parseResult.toDate()) : null
}

export const ExpectedWorkingTimeCalendar: React.FC<ExpectedWorkingTimeCalendarProps> = (props) => {
  const history = useHistory()

  const [expectedWorkingTimeMap, setExpectedWorkingTimeMap] = useState(new Map<string, m.ExpectedWorkingTime>())
  const [requiresLoadingExpectedWorkingTime, setRequiresLoadingExpectedWorkingTime] = useState(true)
  const [loadingExpectedWorkingTime, setLoadingExpectedWorkingTime] = useState(false)
  const [startDate, setStartDate] = useState<m.DateOnly>(today)
  const [startDateValue, setStartDateValue] = useState(startDate.toISOString().replaceAll("-", "/"))

  const startDateOfList = useMemo(() => startDate.add(-startDate.utcZeroHourDate.getDay()), [startDate])
  const startDateOfWeeks = useMemo(() => {
    const weeks = !props.weeks || props.weeks < 0 ? 12 : props.weeks
    return Array.from({ length: weeks }).map((_, i) => startDateOfList.add(i * 7))
  }, [props.weeks, startDateOfList])
  const endDateOfList = useMemo(() => startDateOfWeeks[startDateOfWeeks.length - 1].add(6), [startDateOfWeeks])

  const setStartDateAndQuery = (date: m.DateOnly | null): void => {
    if (date) {
      setStartDate(startDate)
      history.push(
        `${history.location.pathname}?${startDateQueryParameter}=${date.toISOString()}${history.location.hash}`
      )
    }
  }

  const updateExpectedWorkingTimesOfWeek = async (
    startDateOfWeek: m.DateOnly,
    expectedWorkingHoursOfWeek: number[]
  ): Promise<void> => {
    const promises = expectedWorkingHoursOfWeek.map(async (expectedWorkingHours, dayOfWeek) => {
      const date = startDateOfWeek.add(dayOfWeek)
      const oldExpectedWorkingTime = expectedWorkingTimeMap.get(date.toISOString())
      if (oldExpectedWorkingTime) {
        if (oldExpectedWorkingTime.expectedWorkingHours === expectedWorkingHours) {
          return
        } else if (expectedWorkingHours > 0) {
          const updateExpectedWorkingTimeRequest = {
            ...oldExpectedWorkingTime,
            expectedWorkingHours: expectedWorkingHours,
          }
          await clients.expectedWorkingTime.putExpectedWorkingTimeByWorkspaceMember(updateExpectedWorkingTimeRequest)
        } else {
          await clients.expectedWorkingTime.deleteExpectedWorkingTimeByWorkspaceMember(
            props.workspaceId,
            props.workspaceMember.workspaceMemberId,
            date.toISOString()
          )
        }
      } else {
        if (expectedWorkingHours > 0) {
          const createExpectedWorkingTimeRequest = {
            workspaceId: props.workspaceId,
            workspaceMemberId: props.workspaceMember.workspaceMemberId,
            date: date,
            expectedWorkingHours: expectedWorkingHours,
          }
          await clients.expectedWorkingTime.putExpectedWorkingTimeByWorkspaceMember(createExpectedWorkingTimeRequest)
        }
      }
    })
    await Promise.all(promises)
    setRequiresLoadingExpectedWorkingTime(true)
  }

  const copyWeeks = useCallback(
    async (sourceStartDateOfWeek: m.DateOnly, copyWeeksNumber: number): Promise<void> => {
      const sourceExpectedWorkingTimes = Array.from({ length: 7 }).map((_, dayOfWeek) =>
        expectedWorkingTimeMap.get(sourceStartDateOfWeek.add(dayOfWeek).toISOString())
      )
      for (let week = 1; week <= copyWeeksNumber; week++) {
        const promises = Array.from({ length: 7 }).map(async (_, dayOfWeek) => {
          const sourceExpectedWorkingTime = sourceExpectedWorkingTimes[dayOfWeek]
          const date = sourceStartDateOfWeek.add(week * 7 + dayOfWeek)
          const oldExpectedWorkingTime = expectedWorkingTimeMap.get(date.toISOString())
          if (oldExpectedWorkingTime) {
            if (sourceExpectedWorkingTime) {
              if (oldExpectedWorkingTime.expectedWorkingHours === sourceExpectedWorkingTime.expectedWorkingHours) return
              const updateExpectedWorkingTimeRequest = {
                ...oldExpectedWorkingTime,
                expectedWorkingHours: sourceExpectedWorkingTime.expectedWorkingHours,
                createdDatetime: undefined,
              }
              await clients.expectedWorkingTime.putExpectedWorkingTimeByWorkspaceMember(
                updateExpectedWorkingTimeRequest
              )
            } else
              await clients.expectedWorkingTime.deleteExpectedWorkingTimeByWorkspaceMember(
                props.workspaceId,
                props.workspaceMember.workspaceMemberId,
                date.toISOString()
              )
          } else if (sourceExpectedWorkingTime) {
            const createExpectedWorkingTimeRequest = {
              ...sourceExpectedWorkingTime,
              date,
              createdDatetime: undefined,
              updatedDatetime: undefined,
            }
            await clients.expectedWorkingTime.putExpectedWorkingTimeByWorkspaceMember(createExpectedWorkingTimeRequest)
          }
        })
        await Promise.all(promises)
        setRequiresLoadingExpectedWorkingTime(true)
      }
    },
    [expectedWorkingTimeMap, props.workspaceId, props.workspaceMember]
  )

  useEffect(() => {
    const queryStartDate = getDateFromQuery(history.location.search.slice(1)) ?? today
    setStartDate(queryStartDate)
  }, [history.location.search, props.workspaceId, props.workspaceMember])

  useEffect(() => {
    if (props.workspaceId && props.workspaceMember) setRequiresLoadingExpectedWorkingTime(true)
  }, [props.workspaceId, props.workspaceMember, startDate, props.weeks])

  useEffect(() => {
    const asyncEffect = async (): Promise<void> => {
      if (
        props.workspaceId &&
        props.workspaceMember &&
        requiresLoadingExpectedWorkingTime &&
        !loadingExpectedWorkingTime
      ) {
        setLoadingExpectedWorkingTime(true)
        const expectedWorkingTimes = await clients.expectedWorkingTime.getExpectedWorkingTimesByWorkspaceMember(
          props.workspaceId,
          props.workspaceMember.workspaceMemberId,
          startDateOfList.toISOString(),
          endDateOfList.toISOString()
        )
        const expectedWorkingTimeMap = expectedWorkingTimes.reduce(
          (acc, expectedWorkingTime) => acc.set(expectedWorkingTime.date.toISOString(), expectedWorkingTime),
          new Map<string, m.ExpectedWorkingTime>()
        )
        setExpectedWorkingTimeMap(expectedWorkingTimeMap)
        setRequiresLoadingExpectedWorkingTime(false)
        setLoadingExpectedWorkingTime(false)
      }
    }
    asyncEffect()
  }, [
    endDateOfList,
    loadingExpectedWorkingTime,
    props.workspaceId,
    props.workspaceMember,
    requiresLoadingExpectedWorkingTime,
    startDateOfList,
  ])

  return (
    <Container>
      <Grid>
        <Grid.Column floated="left" width={2}>
          <DatePicker
            value={startDateValue}
            onChange={(e, { value }) => {
              formatDate(value, setStartDateAndQuery)
              setStartDateValue(value)
            }}
            placeholder="日付"
          />
        </Grid.Column>
      </Grid>
      <Grid verticalAlign="middle">
        {startDateOfWeeks.map((startDateOfWeek, i) => {
          const rowClassName = i === 0 ? "aw-top-row" : undefined
          const expectedWorkingTimesOfWeek = c.getExpectedWorkingTimesOfWeek(expectedWorkingTimeMap, startDateOfWeek)
          return (
            <c.OuterRowGridRow className={rowClassName} key={i}>
              <WeekSummaryColumn startDateOfWeek={startDateOfWeek} expectedWorkingTimeMap={expectedWorkingTimeMap} />
              <c.OuterRowGridColumn width={12}>
                <Grid textAlign="center" doubling>
                  <c.NoVerticalPaddingGridRow columns={7}>
                    {expectedWorkingTimesOfWeek.map((expectedWorkingTimeOfDay) => (
                      <WeekHeaderColumn
                        date={expectedWorkingTimeOfDay.date}
                        key={expectedWorkingTimeOfDay.date.toISOString()}
                      />
                    ))}
                  </c.NoVerticalPaddingGridRow>
                  <c.NoVerticalPaddingGridRow columns={7}>
                    {expectedWorkingTimesOfWeek.map((expectedWorkingTimeOfDay) => (
                      <WeekHoursColumn
                        expectedWorkingTimeOfDay={expectedWorkingTimeOfDay}
                        key={expectedWorkingTimeOfDay.date.toISOString()}
                      />
                    ))}
                  </c.NoVerticalPaddingGridRow>
                </Grid>
              </c.OuterRowGridColumn>
              <c.OuterRowGridColumn width={1} textAlign="center">
                {props.isReadOnly ? (
                  <></>
                ) : (
                  <WeekMenuColumn
                    startDateOfWeek={startDateOfWeek}
                    expectedWorkingTimesOfWeek={expectedWorkingTimesOfWeek}
                    updateExpectedWorkingTimesOfWeek={updateExpectedWorkingTimesOfWeek}
                    copyWeeks={copyWeeks}
                  />
                )}
              </c.OuterRowGridColumn>
            </c.OuterRowGridRow>
          )
        })}
      </Grid>
    </Container>
  )
}
