import axios, { AxiosError, AxiosResponseHeaders, Method } from "axios"
import camelcaseKeys from "camelcase-keys"
import snakecaseKeys from "snakecase-keys"

import { ApiError, ErrorResponse } from "api/apiError"
import { TokenStore } from "dataStore/tokenStore"

export interface HttpApiResponse<T = unknown> {
  data: T
  status: number
  statusText: string
  headers: any // eslint-disable-line @typescript-eslint/no-explicit-any
  request?: any // eslint-disable-line @typescript-eslint/no-explicit-any
}

export class HttpApiClient {
  get<T>(
    path: string,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.request("get", path, undefined, parameters, headers, responseCase)
  }

  getWithAuth<T>(
    path: string,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.requestWithAuth("get", path, undefined, parameters, headers, responseCase)
  }

  post<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.request("post", path, body, parameters, headers, responseCase)
  }

  postWithAuth<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.requestWithAuth("post", path, body, parameters, headers, responseCase)
  }

  put<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.request("put", path, body, parameters, headers, responseCase)
  }

  putWithAuth<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.requestWithAuth("put", path, body, parameters, headers, responseCase)
  }

  patch<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.request("patch", path, body, parameters, headers, responseCase)
  }

  patchWithAuth<T>(
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.requestWithAuth("patch", path, body, parameters, headers, responseCase)
  }

  delete<T>(
    path: string,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.request("delete", path, undefined, parameters, headers, responseCase)
  }

  deleteWithAuth<T>(
    path: string,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return this.requestWithAuth("delete", path, undefined, parameters, headers, responseCase)
  }

  private requestWithAuth<T>(
    method: Method,
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    // TODO TokenStoreではなくpropsを使うようにする
    headers.set("Authorization", TokenStore.getToken() ?? "")
    return this.request(method, path, body, parameters, headers, responseCase)
  }

  private request<T>(
    method: Method,
    path: string,
    body?: unknown,
    parameters: Map<string, string> = new Map<string, string>(),
    headers: Map<string, string> = new Map<string, string>(),
    responseCase: "sharrow" | "deep" | "keep" = "sharrow"
  ): Promise<HttpApiResponse<T>> {
    return axios
      .request<T>({
        method: method,
        url: path,
        data: body && typeof body === "object" ? snakecaseKeys(body) : body,
        params: Object.fromEntries(parameters),
        headers: Object.fromEntries(headers),
        //eslint-disable-next-line @typescript-eslint/no-explicit-any
        transformResponse: (data: any, header?: AxiosResponseHeaders) => {
          const contentType = header && header["content-type"]
          if ((contentType ?? "").includes("application/json")) {
            switch (responseCase) {
              case "sharrow":
                return camelcaseKeys(JSON.parse(data))
              case "deep":
                return camelcaseKeys(JSON.parse(data), { deep: true })
              case "keep":
                return JSON.parse(data)
            }
          }
          // todo contentTypeがjson以外のapiができたら対応する。
          return data
        },
      })
      .catch((e) => {
        const err = e as AxiosError<ErrorResponse>
        throw new ApiError(err, err.response)
      })
  }
}
