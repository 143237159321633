import "component/unhandledErrorModal.css"

import React, { useCallback, useMemo, useEffect } from "react"
import ReactModal from "react-modal"

import { clients } from "api/clients"
import { AppStore, UnhandledErrorModalState } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"
import { getEnvironment } from "utils/environment"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    minWidth: "540px",
    width: "50%",
    height: "320px",
    padding: 0,
  },
}

type UnhandledErrorModalProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  state: UnhandledErrorModalState | null
}

const defaultTitleMessage = "申し訳ありません。不明なエラーが発生しました。"
const defaultActionMessage = "問題が続く場合、以下のエラー詳細と行っていた操作を、システム管理者にご連絡ください。"

export const UnhandledErrorModal: React.FC<UnhandledErrorModalProps> = (props) => {
  const errorMessage = useMemo(
    () => (props.state ? (props.state.error instanceof Error ? props.state.error.message : props.state.error) : ""),
    [props.state]
  )
  const titleMessage = useMemo(
    () => (props.state?.titleMessage ? props.state.titleMessage : defaultTitleMessage),
    [props.state]
  )
  const actionMessage = useMemo(
    () => (props.state?.actionMessage ? props.state.actionMessage : defaultActionMessage),
    [props.state]
  )

  const onRequestClose = useCallback(
    () => props.dispatch.apply(props.reducer.setModal(null)),
    [props.dispatch, props.reducer]
  )

  useEffect(() => {
    const handle = setTimeout(() => {
      if (props.state && !(props.state.suppressesPostingLog ?? false)) {
        clients.log.postLog({
          clientDatetime: new Date(),
          logLevel: props.state.logLevel,
          message: errorMessage,
          stacktrace: props.state.error instanceof Error ? props.state.error.stack : undefined,
          environment: getEnvironment(),
        })
      }
    })
    return () => clearTimeout(handle)
  }, [props.state, errorMessage])

  return (
    <ReactModal isOpen={props.state != null} style={modalStyle} onRequestClose={onRequestClose}>
      <h1 className="ui icon header">{titleMessage}</h1>
      <div className="error-content aw-unhandled-error-modal-content">
        <p className="error-text">{actionMessage}</p>
        <textarea className="aw-modal-error-message" value={errorMessage} readOnly={true} />
      </div>
      <div className="aw-unhandled-error-modal-footer">
        <button className="ui olive cancel button rounded-pill" onClick={onRequestClose}>
          戻る
        </button>
      </div>
    </ReactModal>
  )
}

export default UnhandledErrorModal
