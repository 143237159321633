import React from "react"

export const InProgressLabel: React.FC<Record<string, never>> = (_) => {
  return (
    <span className="ui teal label">
      <i className="play icon"></i>
      進行中
    </span>
  )
}

export default InProgressLabel
