export const getEnvironment = (): Record<string, string> => ({
  "document.location.href": `${document.location.href}`,
  "navigator.language": `${navigator.language}`,
  "window.screen.width": `${window.screen.width}`,
  "window.screen.height": `${window.screen.height}`,
  "window.screen.colorDepth": `${window.screen.colorDepth}`,
  "window.innerWidth": `${window.innerWidth}`,
  "window.innerHeight": `${window.innerHeight}`,
  "window.devicePixelRatio": `${window.devicePixelRatio}`,
})
