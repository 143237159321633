import React, { useCallback, useState } from "react"
import { useLocation } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import "component/page/confirmSignUp/index.css"
import { clients } from "api/clients"
import { AppStore } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"

export type ConfirmSignUpPageProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
}

export const ConfirmSignUpPage: React.FC<ConfirmSignUpPageProps> = (props) => {
  const search = useLocation().search
  const [userName, setUserName] = useState("")
  const [userId, setUserId] = useState("")
  const [password, setPassword] = useState("")
  const [passwordShown, setPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")

  const confirmSignUpThenPutWorkspace = useCallback(async (): Promise<void> => {
    const params = new URLSearchParams(search)
    await clients.account.postConfirmSignUp({
      accountId: params.get("accountId") ?? "",
      confirmationCode: params.get("code") ?? "",
      username: userName,
      userId: userId,
      password: password,
      locale: "ja-JP", //初期サインアップ時はja-JP固定
    })
    const token = await clients.login.postLogin({
      userId: userId,
      password: password,
    })
    props.dispatch.apply(props.reducer.setToken(token.idToken))
    props.dispatch.apply(props.reducer.setUserId(userId))
    const myAccount = await clients.myInfo.getMyAccount()
    await clients.workspace.putWorkspace(uuidv4(), {
      workspaceName: "My-Workspace",
      email: myAccount.email,
    })
  }, [search, userName, userId, password, props.reducer, props.dispatch])

  const onConfirmSignUpClick = useCallback((): void => {
    setErrorMessage("")
    confirmSignUpThenPutWorkspace()
      .then(() => (window.location.href = "/"))
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }, [confirmSignUpThenPutWorkspace])

  const handleChangeUserName = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserName(e.target.value)
  }, [])
  const handleChangeUserId = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserId(e.target.value)
  }, [])
  const handleChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
  }, [])
  const handleChangeShowPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPasswordShown((passwordShown) => !passwordShown)
  }, [])

  const isValidField = useCallback((): boolean => {
    return (
      userName.length > 0 && userName.length <= 60 && userId.length >= 3 && userId.length <= 30 && password.length >= 8
    )
  }, [userName, userId, password])

  return (
    <div className="ui container">
      <h1 className="ui header" style={{ marginTop: "0.5em", textAlign: "center" }}>
        アカウント登録
      </h1>
      <div className="ui grid segment">
        <div className="sixteen wide column">
          <form className="ui form" onSubmit={(e) => e.preventDefault()}>
            <div className="required field">
              <label>ユーザー名</label>
              <div className="ui left icon input">
                <i className="user icon" />
                <input
                  type="text"
                  name="userName"
                  placeholder="例) 山田花子"
                  value={userName}
                  onChange={handleChangeUserName}
                />
              </div>
            </div>
            <div className="ui bulleted list">
              <div className="item">表示用の名前で、任意の文字が使えます(最大60文字)</div>
              <div className="item">
                同名ユーザーが複数いても区別できるように、フルネームやニックネームなどを推奨します。
              </div>
            </div>
            <div className="ui divider"></div>
            <div className="required field">
              <label>ユーザーID</label>
              <div className="ui left icon input">
                <i className="user icon" />
                <input
                  type="text"
                  name="userId"
                  placeholder="例) hanako_123"
                  value={userId}
                  onChange={handleChangeUserId}
                />
              </div>
            </div>
            <div className="ui bulleted list">
              <div className="item">
                ログイン時に使用するIDで、半角英数字とアンダースコア(_)が使用できます(3文字～30)
              </div>
              <div className="item">他の人と同じユーザーIDは取得できません。</div>
            </div>
            <div className="ui divider"></div>
            <div className="required field">
              <label>パスワード</label>
              <div className="ui left icon input">
                <i className="lock icon" />
                <input
                  type={passwordShown ? "text" : "password"}
                  name="password"
                  value={password}
                  onChange={handleChangePassword}
                />
              </div>
            </div>
            <div className="ui list">
              <div className="item">
                <div className="ui checkbox">
                  <input type="checkbox" id="show-new-password-id" onChange={handleChangeShowPassword} />
                  <label htmlFor="show-new-password-id">パスワードを表示する</label>
                </div>
              </div>
            </div>
            <div className="ui bulleted list">
              <div className="item">パスワードは8文字以上必要です。</div>
              <div className="item">
                <strong>imaomoituitasorenarinopasuwa-do</strong>
                のように自分だけが分かる文にすると、安全で覚えやすいパスワードになります。
              </div>
            </div>
            <div className="ui divider"></div>

            <button
              className="ui fluid large olive button"
              type="submit"
              onClick={onConfirmSignUpClick}
              disabled={!isValidField()}
            >
              アカウントを登録
            </button>
          </form>
          <div className="aw-confirm-sign-up-error">
            <span className="ui red text">{errorMessage}</span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmSignUpPage
