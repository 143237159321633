import React from "react"
import { CookiesProvider } from "react-cookie"
import ReactDOM from "react-dom"
import ReactModal from "react-modal"

import { App, AppProps } from "App"
import { initialAppStore } from "model/appStore"
import { reducerImpl } from "reducer/impl"
import { mockReducer } from "reducer/mock"

const appProps: AppProps = {
  initialStore: initialAppStore,
  // TODO 環境変数で動作を切り替える箇所を1箇所に統合する
  reducer: process.env.NODE_ENV === "production" ? reducerImpl : mockReducer,
}
// eslint-disable-next-line @typescript-eslint/no-non-null-assertion
const root = document.getElementById("root")!
ReactModal.setAppElement(root)
ReactDOM.render(
  <CookiesProvider>
    <App {...appProps} />
  </CookiesProvider>,
  root
)
