import { styled } from "linaria/react"
import React, { useState, useCallback, useEffect } from "react"

import { clients } from "api/clients"
import { ArchivedLabel, InProgressLabel } from "component/common"
import * as m from "model"

const Radio = styled.label`
  &.ui.radio.checkbox {
    label::before {
      top: 6px;
    }
    label::after {
      top: 6px;
    }
  }
`
const Divider = styled.div`
  padding-top: 10px;
`

export type BasicSettingTabProps = {
  project: m.Job
  setProject: (value: m.Job) => void
}

export const BasicSettingTab: React.FC<BasicSettingTabProps> = React.memo((props) => {
  const [projectName, setProjectName] = useState<string>("")
  const [projectStatus, setProjectStatus] = useState<string>("unarchived")
  const [projectTargetHour, setProjectTargetHour] = useState<string>("")
  const [projectNote, setProjectNote] = useState<string>("")

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isProjectUpdated, setIsProjectUpdated] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const putProject = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()

      setIsLoading(true)
      const body: m.PutJobRequest = {
        jobName: projectName,
        status: projectStatus as m.JobStatus,
        targetHours: projectTargetHour ? Number(projectTargetHour) : undefined,
        note: projectNote || undefined,
        externalLinkageInfo: props.project.externalLinkageInfo,
        lastUpdatedDatetime: props.project.updatedDatetime,
      }
      clients.job
        .putJob(props.project.workspaceId, props.project.jobId, body)
        .then((project) => {
          props.setProject(project)
          setIsProjectUpdated(true)
        })
        .catch((error: Error) => setErrorMessage(error.message))
        .finally(() => setIsLoading(false))
    },
    [
      props,
      projectName,
      projectStatus,
      projectTargetHour,
      projectNote,
      setIsProjectUpdated,
      setErrorMessage,
      setIsLoading,
    ]
  )

  useEffect(() => {
    setProjectName(props.project.jobName)
    setProjectStatus(props.project.status)
    setProjectTargetHour(props.project.targetHours?.toString() ?? "")
    setProjectNote(props.project.note ?? "")
  }, [props.project, setProjectName, setProjectStatus, setProjectTargetHour, setProjectNote])

  return (
    <div className="ui tab basic active segment">
      <form
        className={"ui form" + (isLoading ? " loading" : "") + (errorMessage ? " error" : "")}
        onSubmit={putProject}
      >
        <table className="ui definition table">
          <tbody>
            <tr>
              <td className="required field">
                <label>プロジェクト名</label>
              </td>
              <td className="single line">
                <input
                  className="ui input"
                  type="text"
                  placeholder="例）PROJECT NAME"
                  value={projectName}
                  onChange={(e) => setProjectName(e.target.value)}
                  required
                />
              </td>
            </tr>
            <tr>
              <td className="required field">
                <label>状態</label>
              </td>
              <td className="single line">
                <Radio className="ui radio checkbox">
                  <input
                    type="radio"
                    id="project_status_in_progress"
                    name="project_status"
                    value="unarchived"
                    checked={projectStatus === "unarchived"}
                    onChange={(e) => setProjectStatus(e.target.value)}
                  />
                  <label htmlFor="project_status_in_progress">
                    <InProgressLabel></InProgressLabel>
                  </label>
                </Radio>
                <Divider></Divider>
                <Radio className="ui radio checkbox">
                  <input
                    type="radio"
                    id="project_status_archived"
                    name="project_status"
                    value="archived"
                    checked={projectStatus === "archived"}
                    onChange={(e) => setProjectStatus(e.target.value)}
                  />
                  <label htmlFor="project_status_archived">
                    <ArchivedLabel></ArchivedLabel>
                  </label>
                </Radio>
              </td>
            </tr>
            <tr>
              <td className="field">
                <label>目標時間 (h)</label>
              </td>
              <td className="single line">
                <input
                  className="ui input"
                  type="number"
                  placeholder="例) 160"
                  min={0.01}
                  step={0.01}
                  value={projectTargetHour}
                  onChange={(e) => setProjectTargetHour(e.target.value)}
                />
              </td>
            </tr>
            <tr>
              <td className="field">
                <label>備考</label>
              </td>
              <td className="single line">
                <textarea
                  placeholder="メモ"
                  value={projectNote}
                  onChange={(e) => setProjectNote(e.target.value)}
                ></textarea>
              </td>
            </tr>
            <tr>
              <td className="field"></td>
              <td className="single line">
                <div className="ui right aligned">
                  <button className="ui olive ok button rounded-pill" type="submit">
                    プロジェクト設定を更新
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        {isProjectUpdated ? (
          <div className="ui positive message">
            <i className="check icon"></i>
            プロジェクト設定を更新しました。
          </div>
        ) : errorMessage ? (
          <div className="ui error message">{errorMessage}</div>
        ) : (
          <></>
        )}
      </form>
    </div>
  )
})

export default BasicSettingTab
