import * as api from "api/index"
import { PostLogRequest } from "model"

export class LogApiClientMock implements api.LogApiClient {
  postLog = (request: PostLogRequest): Promise<void> => {
    console.log(
      `LogApiClientMock.postLog ${request.clientDatetime.toISOString()} ${request.logLevel} ${request.message} ${
        request.environment ?? ""
      }`
    )
    return Promise.resolve()
  }
}
