import { clients } from "api/clients"
import * as m from "model"

export const getCompanyMap = async (tags: m.WorkspaceTag[]): Promise<Map<m.WorkspaceMemberId, string>> => {
  const companyTags = tags.filter((tag) => tag.workspaceTagName.startsWith(m.companyTagPrefix))
  const getMembersHavingCompanyTag = companyTags.map(async (companyTag) => {
    const members = await clients.workspaceMember.getWorkspaceMembersByTagId(
      companyTag.workspaceId,
      companyTag.workspaceTagId
    )
    return {
      tagName: companyTag.workspaceTagName,
      members: members,
    }
  })
  const membersHavingCompanyTag = await Promise.all(getMembersHavingCompanyTag)
  return new Map(
    membersHavingCompanyTag.flatMap((x) =>
      x.members.map((member) => [member.workspaceMemberId, x.tagName.replace(m.companyTagPrefix, "")])
    )
  )
}
