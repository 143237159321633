import React, { useCallback, useState } from "react"

import "component/page/resetPassword/index.css"
import { ResetPasswordRequest } from "api"
import { clients } from "api/clients"

export type ResetPasswordPageProps = {
  // no prop
}

export const ResetPasswordPage: React.FC<ResetPasswordPageProps> = (props) => {
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [successMessage, setSuccessMessage] = useState("")

  const resetPassword = useCallback(async (): Promise<void> => {
    setErrorMessage("")

    const request: ResetPasswordRequest = { email: email }
    await clients.account
      .postResetPassword(request)
      .then(() => {
        setSuccessMessage(`「${email}」にメールを送信しました。`)
        setIsSuccess(true)
      })
      .catch((error) => {
        //エラーメッセージを画面に表示
        setErrorMessage(error.message)
      })
  }, [email])

  const handleChange = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setEmail(e.target.value)
  }, [])

  const isValidField = useCallback((): boolean => {
    return email.length > 0
  }, [email])

  return (
    <div className="ui container">
      <h1 className="ui header" style={{ marginTop: "2em", textAlign: "center" }}>
        パスワードリセット
      </h1>
      <div className="aw-reset-password-form">
        <div className="ui grid segment">
          <div className="one column">
            <form className="ui form" onSubmit={(e) => e.preventDefault()}>
              <p>
                操作手順をお送りするメールアドレスをご入力ください。お客様のアカウントを保護するため、アカウントに登録済みのアドレスが入力された場合のみ、メールが送られます。メールの送信には数分かかる場合があります。
              </p>
              <div className="required field">
                <label>メールアドレス</label>
                <div className="ui left icon input">
                  <i className="mail icon" />
                  <input
                    type="email"
                    name="email"
                    placeholder="例) johndoe@example.com"
                    value={email}
                    onChange={handleChange}
                    required={true}
                  />
                </div>
              </div>
              <div className="ui divider"></div>

              <button
                className="ui fluid large olive button"
                type="submit"
                onClick={resetPassword}
                disabled={!isValidField()}
              >
                パスワードリセットメールを受けとる
              </button>
            </form>
            <div className="aw-reset-password-error">
              <span className="ui red text">{errorMessage}</span>
            </div>
            <div className="aw-reset-password-success" style={{ display: isSuccess ? "" : "none" }}>
              <span className="ui text">{successMessage}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ResetPasswordPage
