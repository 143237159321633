// TODO 画面を全部reactに統一したらUserIdStoreを消す
export class UserIdStore {
  private static key = "user_id"
  static set(userId: string): void {
    localStorage.setItem(UserIdStore.key, userId)
  }
  static get(): string | undefined {
    return localStorage.getItem(UserIdStore.key) ?? undefined
  }
  static clear(): void {
    localStorage.removeItem(UserIdStore.key)
  }
}
