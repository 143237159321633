import { styled } from "linaria/react"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory, useLocation } from "react-router-dom"

import { clients } from "api/clients"
import ReportsTab from "component/common/reports/reportsTab"
import ScheduleTab from "component/common/schedule/scheduleTab"
import * as m from "model"

const TabularMenu = styled.div`
  &.ui.tabular.menu {
    margin-top: 40px;
  }
`

const scheduleTab = "schedule"
const reportsTab = "reports"
const defaultTab = scheduleTab
const tabs = [scheduleTab, reportsTab] as const
type Tab = typeof tabs[number]

export type MyPageProps = {
  workspaceId: string | null
  myMember: m.WorkspaceMember | null
}

export const MyPage: React.FC<MyPageProps> = React.memo((props) => {
  const location = useLocation()
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState<Tab>(defaultTab)
  const [jobMap, setJobMap] = useState<Map<m.JobId, m.Job>>(new Map())

  const currenttTabComponent = useMemo(() => {
    if (!props.workspaceId || !props.myMember) {
      return <></>
    }
    switch (currentTab) {
      case scheduleTab:
        return (
          <ScheduleTab
            workspaceId={props.workspaceId}
            workspaceMember={props.myMember}
            myWorkspaceMemberId={props.myMember.workspaceMemberId}
            jobMap={jobMap}
          ></ScheduleTab>
        )
      case reportsTab:
        return (
          <ReportsTab
            workspaceId={props.workspaceId}
            workspaceMember={props.myMember}
            myWorkspaceMemberId={props.myMember.workspaceMemberId}
            jobMap={jobMap}
          ></ReportsTab>
        )
      default:
        return <></>
    }
  }, [props.workspaceId, props.myMember, jobMap, currentTab])

  useEffect(() => {
    const hash = location.hash.substring(1)
    const tab = tabs.find((x) => x === hash) ?? defaultTab
    setCurrentTab(tab as Tab)
  }, [location.hash, setCurrentTab])

  useEffect(() => {
    if (props.workspaceId) {
      clients.job.list(props.workspaceId, []).then((jobs) => setJobMap(new Map(jobs.map((x) => [x.jobId, x]))))
    }
  }, [props.workspaceId, setJobMap])

  if (!props.workspaceId || !props.myMember) {
    return <></>
  }

  return (
    <div className="ui container">
      <TabularMenu className="ui tabular menu">
        <button
          className={"item" + (currentTab === scheduleTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${scheduleTab}`)}
        >
          スケジュール
        </button>
        <button
          className={"item" + (currentTab === reportsTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${reportsTab}`)}
        >
          レポート
        </button>
      </TabularMenu>
      {currenttTabComponent}
    </div>
  )
})

export default MyPage
