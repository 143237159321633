import { styled } from "linaria/react"
import React, { useState, useEffect } from "react"
import { useHistory, useLocation } from "react-router-dom"
import { Popup } from "semantic-ui-react"

import { clients } from "api/clients"
import { BasicSettingTab } from "component/page/project/basicSettingTab"
import DeleteConfirmationModal from "component/page/project/deleteConfirmationModal"
import { JobsTab } from "component/page/project/jobsTab"
import * as m from "model"
import { notFoundPagePath } from "model/page"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  margin-top: 20px;
`

type Tab = "basic-setting-tab" | "jobs-tab"

export type ProjectPageProps = {
  workspaceId: string | null
}

export const ProjectPage: React.FC<ProjectPageProps> = React.memo((props) => {
  const history = useHistory()
  const params = new URLSearchParams(useLocation().search)
  if (!params.has("projectId")) history.push(notFoundPagePath)
  const projectId = params.get("projectId") as string

  const [project, setProject] = useState<m.Job>()
  const [currentTab, setCurrentTab] = useState<Tab>("basic-setting-tab")

  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)

  useEffect(() => {
    if (props.workspaceId !== null) {
      clients.job
        .getJob(props.workspaceId, projectId)
        .then((project) => setProject(project))
        .catch((error: Error) => history.push(notFoundPagePath))
    }
  }, [props.workspaceId, projectId, history])

  if (!project) {
    return <></>
  }

  return (
    <div className="ui container">
      <FlexContainer>
        {project.status === "archived" ? (
          <button className="ui red ok button rounded-pill" onClick={() => setIsDeleteConfirmationModalOpen(true)}>
            プロジェクトを削除
          </button>
        ) : (
          <Popup
            content="進行中のプロジェクトは削除できません。"
            trigger={
              <span>
                <button className="ui red ok button rounded-pill" disabled>
                  プロジェクトを削除
                </button>
              </span>
            }
          />
        )}
      </FlexContainer>
      <div className="ui tabular menu">
        <button
          className={"item" + (currentTab === "basic-setting-tab" ? " active" : "")}
          onClick={() => setCurrentTab("basic-setting-tab")}
        >
          基本設定
        </button>
        <button
          className={"item" + (currentTab === "jobs-tab" ? " active" : "")}
          onClick={() => setCurrentTab("jobs-tab")}
        >
          ジョブ
        </button>
      </div>
      {currentTab === "basic-setting-tab" ? (
        <BasicSettingTab project={project} setProject={setProject}></BasicSettingTab>
      ) : currentTab === "jobs-tab" ? (
        <JobsTab project={project}></JobsTab>
      ) : (
        <></>
      )}
      <DeleteConfirmationModal
        job={project}
        isOpen={isDeleteConfirmationModalOpen}
        setIsOpen={setIsDeleteConfirmationModalOpen}
      ></DeleteConfirmationModal>
    </div>
  )
})

export default ProjectPage
