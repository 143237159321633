import { ExpectedWorkingTimeApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { ExpectedWorkingTime, DateOnly, PutExpectedWorkingTimeRequest } from "model"

interface ExpectedWorkingTimeResponse {
  workspaceId: string
  workspaceMemberId: string
  date: string
  expectedWorkingHours: number
  createdDatetime: string
  updatedDatetime: string
}

const toExpectedWorkingTime = (data: ExpectedWorkingTimeResponse): ExpectedWorkingTime =>
  Object.assign(data, {
    date: DateOnly.fromString(data.date),
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as ExpectedWorkingTime

export class ExpectedWorkingTimeApiClientImpl implements ExpectedWorkingTimeApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  list = async (workspaceId: string, termStart: string, termEnd: string): Promise<ExpectedWorkingTime[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/expected-working-times`
    const params = new Map<string, string>()
    params.set("term_start", termStart)
    params.set("term_end", termEnd)
    const res = await this.client.getWithAuth<ExpectedWorkingTimeResponse[]>(path, params)
    return res.data.map(toExpectedWorkingTime)
  }

  getExpectedWorkingTimesByWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    termStart?: string,
    termEnd?: string
  ): Promise<ExpectedWorkingTime[]> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}/expected-working-times`
    const params = new Map<string, string>()
    if (termStart) params.set("term_start", termStart)
    if (termEnd) params.set("term_end", termEnd)
    const res = await this.client.getWithAuth<ExpectedWorkingTimeResponse[]>(path, params)
    return res.data.map(toExpectedWorkingTime)
  }

  putExpectedWorkingTimeByWorkspaceMember = async (
    request: PutExpectedWorkingTimeRequest
  ): Promise<ExpectedWorkingTime> => {
    const path = `/api/v1/workspaces/${request.workspaceId}/members/${
      request.workspaceMemberId
    }/expected-working-times/${request.date.toISOString()}`
    const body = {
      expectedWorkingHours: request.expectedWorkingHours,
      lastUpdatedDatetime: request.updatedDatetime?.toISOString(),
    }
    const res = await this.client.putWithAuth<ExpectedWorkingTimeResponse>(path, body)
    return toExpectedWorkingTime(res.data)
  }

  deleteExpectedWorkingTimeByWorkspaceMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    date: string
  ): Promise<ExpectedWorkingTime> => {
    const path = `/api/v1/workspaces/${workspaceId}/members/${workspaceMemberId}/expected-working-times/${date}`
    const res = await this.client.deleteWithAuth<ExpectedWorkingTimeResponse>(path)
    return toExpectedWorkingTime(res.data)
  }
}
