import React from "react"

export const ArchivedLabel: React.FC<Record<string, never>> = (_) => {
  return (
    <span className="ui gray label">
      <i className="folder icon"></i>
      アーカイブ済み
    </span>
  )
}

export default ArchivedLabel
