import { ScheduleApiClient } from "api"
import { Schedule, PutScheduleRequest, DateOnly } from "model"
import { dayms } from "utils/date"

const orgId = "kurusugawa"
const pastTime = new Date("2020-01-01T12:00:00Z")
const today = DateOnly.fromDateAsUTC(new Date())
let scheduleCount = 0
function createSchedule(
  jobId: string,
  memberId: string,
  deltaDay: number,
  durationDay: number,
  type: Schedule["type"],
  date: DateOnly
): Schedule {
  const startDate = Math.floor((date.utcZeroHourDate.getTime() + deltaDay * dayms) / dayms) * dayms
  const endDate = Math.floor((date.utcZeroHourDate.getTime() + (deltaDay + durationDay) * dayms) / dayms) * dayms
  return {
    scheduleId: (++scheduleCount).toString(),
    workspaceId: orgId,
    jobId: jobId,
    workspaceMemberId: memberId,
    startDate: DateOnly.fromUnixtimeMs(startDate),
    endDate: DateOnly.fromUnixtimeMs(endDate),
    type: type,
    value: type === "percentage" ? 100 : 4,
    createdDatetime: pastTime,
    updatedDatetime: pastTime,
  }
}

export class ScheduleApiClientMock implements ScheduleApiClient {
  get = async (workspaceId: string, scheduleId: string): Promise<Schedule> => {
    throw new Error("呼び出してはダメ")
  }

  list = async (workspaceId: string, termStart: string, termEnd: string, jobId?: string): Promise<Schedule[]> => {
    console.log(`ScheduleApiClientMock.list(${workspaceId}, ${termStart}, ${termEnd}, ${jobId})`)
    const startDate = DateOnly.fromString(termStart)
    const schedules: Schedule[] = [
      createSchedule("2", "f", 0, 5, "hours", startDate),
      createSchedule("2", "f", 5, 14, "percentage", startDate),
      createSchedule("2", "f", 19, 14, "hours", startDate),
      createSchedule("2", "k", 0, 5, "hours", startDate),
      createSchedule("2", "k", 5, 14, "percentage", startDate),
      createSchedule("2", "k", 19, 14, "hours", startDate),
      createSchedule("2", "a", 0, 5, "hours", startDate),
      createSchedule("2", "a", 5, 14, "percentage", startDate),
      createSchedule("2", "a", 19, 14, "hours", startDate),
      createSchedule("3", "f", 0, 5, "hours", startDate),
      createSchedule("3", "f", 5, 14, "percentage", startDate),
      createSchedule("3", "f", 19, 14, "hours", startDate),
      createSchedule("4", "s", 0, 5, "hours", startDate),
      createSchedule("4", "s", 5, 14, "percentage", startDate),
      createSchedule("4", "s", 19, 14, "hours", startDate),
      createSchedule("4", "t", 0, 5, "hours", startDate),
      createSchedule("4", "t", 5, 14, "percentage", startDate),
      createSchedule("4", "t", 19, 14, "hours", startDate),
      createSchedule("0", "k", 0, 2, "percentage", startDate),
    ]
    return schedules
  }

  listSum = async (workspaceId: string, jobId?: string): Promise<Record<string, Record<string, number>>> => {
    console.log(`ScheduleApiClientMock.listSum(${workspaceId}, ${jobId})`)
    return {
      "2": {
        f: 1,
        k: 2,
        a: 3,
      },
      "3": {
        f: 4,
      },
      "4": {
        s: 5,
        t: 6,
      },
    }
  }

  put = async (schedule: PutScheduleRequest): Promise<Schedule> => {
    console.log(`ScheduleApiClientMock.put(scheduleId=${schedule.scheduleId})`)
    return {
      ...schedule,
      createdDatetime: new Date(),
      updatedDatetime: new Date(),
    }
  }

  delete = async (workspaceId: string, scheduleId: string): Promise<Schedule> => {
    console.log(`ScheduleApiClientMock.delete(${workspaceId}, ${scheduleId})`)
    return createSchedule("2", "f", 0, 5, "hours", today)
  }
}
