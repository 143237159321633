import { css } from "linaria"
import { styled } from "linaria/react"
import React, { useEffect, useState } from "react"
import { DateInput } from "semantic-ui-calendar-react"
import { v4 as uuidv4 } from "uuid"

import "moment/locale/ja"

const InputStyle = css`
  .ui.input {
    width: 160px;
  }
`
const PickerContainer = styled.div`
  .ui.table > thead > tr:nth-child(2) {
    th:first-of-type {
      color: red;
    }
    th:last-of-type {
      color: red;
    }
  }
`

export const hasValidDateFormat = (value: string): boolean => /^\d{4}\/\d{2}\/\d{2}$/.test(value)

type DatePickerProps = {
  value: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onChange: (e: React.SyntheticEvent<HTMLElement>, data: any) => void
  placeholder?: string
  onBlur?: (e: React.SyntheticEvent<HTMLElement>) => void
  readOnly?: boolean
}

export const DatePicker: React.FC<DatePickerProps> = React.memo((props) => {
  const [pickerId, setPickerId] = useState<string | undefined>(undefined)
  const [inputId, setInputId] = useState<string | undefined>(undefined)
  const [mountNode, setMountNode] = useState<HTMLElement | null>(null)

  useEffect(() => {
    setPickerId(uuidv4())
    setInputId(uuidv4())
  }, [])

  useEffect(() => {
    if (pickerId) {
      setMountNode(document.getElementById(pickerId))
    }
  }, [pickerId])

  useEffect(() => {
    if (props.readOnly && inputId) {
      // DateInput#readOnly を指定すると直接入力はできなくなるが、カレンダーの表示もされなくなるため入力要素に独自にreadOnly属性を指定してこれを回避する
      // See for detail https://github.com/arfedulov/semantic-ui-calendar-react/issues/107
      document.getElementById(inputId)?.setAttribute("readonly", "true")
    }
  }, [props.readOnly, inputId])

  return (
    <>
      <DateInput
        id={inputId}
        className={InputStyle}
        icon="calendar"
        placeholder={props.placeholder}
        localization="ja"
        dateFormat="YYYY/MM/DD"
        value={props.value}
        onChange={props.onChange}
        closable={true}
        closeOnMouseLeave={false}
        mountNode={mountNode}
        onBlur={props.onBlur}
      />
      <PickerContainer id={pickerId}></PickerContainer>
    </>
  )
})

export default DatePicker
