import { styled } from "linaria/react"
import React, { useEffect, useMemo, useState } from "react"
import { useHistory, useLocation, useParams } from "react-router-dom"

import { clients } from "api/clients"
import ActualWorkingTimeList from "component/common/actualWorkingTimeList"
import { ExpectedWorkingTimeCalendar } from "component/common/expectedWorkingTime/calendar"
import ReportsTab from "component/common/reports/reportsTab"
import ScheduleTab from "component/common/schedule/scheduleTab"
import ConfirmationModal from "component/page/workspaceMember/confirmationModal"
import * as m from "model"
import { notFoundPagePath } from "model/page"

const FlexContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-top: 20px;
  gap: 10px;
`
const Tags = styled.div`
  display: flex;
  gap: 10px;
`
const InactivateButton = styled.div`
  &.ui.button {
    margin-left: auto;
  }
`
const Username = styled.div`
  font-size: 20px;
  font-weight: bold;
  align-self: center;
  padding-bottom: 7px;
`

const UserId = styled.div`
  font-size: 14px;
  margin-top: 2px;
  margin-bottom: 18px;
`

const scheduleTab = "schedule"
const reportsTab = "reports"
const actualWorkingTimesTab = "actual-working-times"
const expectedWorkingTimesTab = "expected-working-times"
const defaultTab = scheduleTab
const tabs = [scheduleTab, reportsTab, actualWorkingTimesTab, expectedWorkingTimesTab] as const
type Tab = typeof tabs[number]

interface PathParameter {
  memberId: string
}

export type WorkspaceMemberProps = {
  workspaceId: string | null
  myMember: m.WorkspaceMember | null
}

export const WorkspaceMember: React.FC<WorkspaceMemberProps> = React.memo((props) => {
  const location = useLocation()
  const history = useHistory()
  const { memberId } = useParams<PathParameter>()

  const [currentTab, setCurrentTab] = useState<Tab>(defaultTab)
  const [workspaceMember, setWorkspaceMember] = useState<m.WorkspaceMember>()
  const [tags, setTags] = useState<m.WorkspaceTag[]>([])
  const [jobs, setJobs] = useState<m.Job[]>([])
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false)
  const [requiresLoadActualWorkingTimes, setRequiresLoadActualWorkingTimes] = useState(true)

  const jobMap = useMemo(() => new Map(jobs.map((x) => [x.jobId, x])), [jobs])
  const confirmationModalType = useMemo(
    () => (workspaceMember?.status === "active" ? "delete" : "invitation"),
    [workspaceMember]
  )
  const currenttTabComponent = useMemo(() => {
    if (!props.workspaceId || !props.myMember || !workspaceMember) {
      return <></>
    }
    switch (currentTab) {
      case scheduleTab:
        return (
          <ScheduleTab
            workspaceId={props.workspaceId}
            workspaceMember={workspaceMember}
            myWorkspaceMemberId={props.myMember.workspaceMemberId}
            jobMap={jobMap}
          ></ScheduleTab>
        )
      case reportsTab:
        return (
          <ReportsTab
            workspaceId={props.workspaceId}
            workspaceMember={workspaceMember}
            myWorkspaceMemberId={props.myMember.workspaceMemberId}
            jobMap={jobMap}
          ></ReportsTab>
        )
      case actualWorkingTimesTab:
        return (
          <div className="ui tab basic active segment">
            <ActualWorkingTimeList
              workspaceId={props.workspaceId}
              workspaceMember={workspaceMember}
              readOnly={true}
              jobs={jobs}
              requiresLoadActualWorkingTimes={requiresLoadActualWorkingTimes}
              setRequiresLoadActualWorkingTimes={setRequiresLoadActualWorkingTimes}
            />
          </div>
        )
      case expectedWorkingTimesTab:
        return (
          <div className="ui tab basic active segment">
            <ExpectedWorkingTimeCalendar
              workspaceId={props.workspaceId}
              workspaceMember={workspaceMember}
              isReadOnly={true}
            />
          </div>
        )
      default:
        return <></>
    }
  }, [
    props,
    workspaceMember,
    jobs,
    jobMap,
    currentTab,
    requiresLoadActualWorkingTimes,
    setRequiresLoadActualWorkingTimes,
  ])

  const roleElement = useMemo(() => {
    if (!workspaceMember) {
      return <></>
    } else if (workspaceMember.role === "worker") {
      return <span className="ui large teal label">ワーカー</span>
    } else if (workspaceMember.role === "manager") {
      return <span className="ui large teal label">管理者</span>
    } else {
      return <span className="ui large teal label">オーナー</span>
    }
  }, [workspaceMember])

  const tagElements = useMemo(
    () => tags.map((tag) => <div className="ui teal large tag label">{tag.workspaceTagName}</div>),
    [tags]
  )

  useEffect(() => {
    const hash = location.hash.substring(1)
    const tab = tabs.find((x) => x === hash) ?? defaultTab
    setCurrentTab(tab as Tab)
  }, [location.hash, setCurrentTab])

  useEffect(() => {
    if (props.workspaceId) {
      const getMember = clients.workspaceMember.getWorkspaceMember(props.workspaceId, memberId)
      const getTags = clients.workspaceTag.getWorkspaceMemberTags(props.workspaceId, memberId)
      const getJobs = clients.job.list(props.workspaceId, [])
      Promise.all([getMember, getTags, getJobs])
        .then(([member, tags, jobs]) => {
          setWorkspaceMember(member)
          setTags(tags.sort((a, b) => a.workspaceTagName.localeCompare(b.workspaceTagName, "ja-JP")))
          setJobs(jobs)
        })
        .catch((error) => {
          history.push(notFoundPagePath)
          return undefined
        })
    }
  }, [props.workspaceId, memberId, setJobs, history])

  if (!props.workspaceId || !props.myMember || !workspaceMember) {
    return <></>
  }

  return (
    <div className="ui container">
      <FlexContainer>
        <Username>{workspaceMember.username}</Username>
        <div>
          {workspaceMember.status === "active" ? (
            <span className="ui large teal label">活動中</span>
          ) : (
            <span className="ui large gray label">脱退済み</span>
          )}
        </div>
        <div>{roleElement}</div>
        {workspaceMember.status === "active" ? (
          <InactivateButton className="ui red ok button rounded-pill" onClick={() => setIsConfirmationModalOpen(true)}>
            ワークスペースから脱退
          </InactivateButton>
        ) : (
          <></>
        )}
      </FlexContainer>
      <UserId>ID：{workspaceMember.userId}</UserId>
      <Tags>{tagElements}</Tags>
      <div className="ui tabular menu">
        <button
          className={"item" + (currentTab === scheduleTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${scheduleTab}`)}
        >
          スケジュール
        </button>
        <button
          className={"item" + (currentTab === reportsTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${reportsTab}`)}
        >
          レポート
        </button>
        <button
          className={"item" + (currentTab === actualWorkingTimesTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${actualWorkingTimesTab}`)}
        >
          実績
        </button>
        <button
          className={"item" + (currentTab === expectedWorkingTimesTab ? " active" : "")}
          onClick={() => history.push(`${location.pathname}#${expectedWorkingTimesTab}`)}
        >
          予定稼働
        </button>
      </div>
      {currenttTabComponent}
      <ConfirmationModal
        modalType={confirmationModalType}
        workspaceMember={workspaceMember}
        isOpen={isConfirmationModalOpen}
        setIsOpen={setIsConfirmationModalOpen}
        setWorkspaceMember={setWorkspaceMember}
      ></ConfirmationModal>
    </div>
  )
})

export default WorkspaceMember
