import * as m from "model"
import { initialScheduleState, initialScheduleSumState, ScheduleState, ScheduleSumState } from "model/scheduleState"

export interface UnhandledErrorModalState {
  kind: "unhandledError"
  error: Error | string
  logLevel: m.LogLevel
  titleMessage?: string
  actionMessage?: string
  suppressesPostingLog?: boolean
}

export const createUnhandledErrorModalState = (
  error: Error | string,
  logLevel: m.LogLevel,
  titleMessage?: string,
  actionMessage?: string,
  suppressesPostingLog?: boolean
): UnhandledErrorModalState => ({
  kind: "unhandledError",
  error: error,
  logLevel: logLevel,
  titleMessage: titleMessage,
  actionMessage: actionMessage,
  suppressesPostingLog: suppressesPostingLog,
})

export interface WorkspaceMemberInvitationModalState {
  kind: "workspace-member-invitation"
  userId: string
  role: m.Role
  errorMessage: string | undefined
}

export interface AssignModalState {
  kind: "assign"
  schedule: m.Schedule | null
  selectedJobs: ReadonlyArray<m.Job>
  selectedMembers: ReadonlyArray<m.WorkspaceMember>
  startDate: m.DateOnly
  endDate: m.DateOnly
  assignType: "hours" | "percentage"
  hours: number | null
  percentage: number | null
  errorMessage: string | undefined
}

export type ModalState = AssignModalState | UnhandledErrorModalState | WorkspaceMemberInvitationModalState | null

export interface AppStore {
  token: string | null
  userId: string | null
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
  modal: ModalState
  schedule: ScheduleState
  scheduleSum: ScheduleSumState
}

export const initialAppStore = {
  token: null,
  userId: null,
  workspaceId: null,
  workspaceMember: null,
  modal: null,
  schedule: initialScheduleState,
  scheduleSum: initialScheduleSumState,
}
