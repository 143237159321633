import { HttpApiClient } from "api/httpApiClient"
import * as api from "api/index"
import { PostLogRequest } from "model"

export class LogApiClientImpl implements api.LogApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  postLog = async (request: PostLogRequest): Promise<void> => {
    const requestBody = {
      ...request,
      clientDatetime: request.clientDatetime.toISOString(),
    }
    try {
      await this.client.post("/api/v1/logs/log", requestBody)
    } catch (e) {
      const message = e instanceof Error ? e.message : typeof e === "string" ? e : "不明"
      console.warn(`ログ出力APIで予期せぬエラーが発生しました。処理を続行します。理由 : ${message}`)
    }
  }
}
