import { styled } from "linaria/react"
import React, { useMemo } from "react"

import Report from "component/page/reports/index"
import * as m from "model"

const SummaryTr = styled.tr`
  font-weight: bold;
`

export interface Report {
  expectedWorkingHours: number
  projectReports: ProjectReport[]
}

export interface ProjectReport {
  projectId: m.ProjectId
  projectName: string
  assignedWorkingHours: number
  actualWorkingHours: number
}

interface SummaryReport {
  totalExpectedWorkingHours: number
  totalAssignedWorkingHours: number
  totalActualWorkingHours: number
}

type ReportsTableProps = {
  report: Report
}

export const roundReport = (x: number): number => {
  // レポートは小数第二位まで表示する
  return Math.round(x * 100) / 100
}

export const ReportsTable: React.FC<ReportsTableProps> = React.memo((props) => {
  const sortedProjectReports = useMemo(() => {
    return props.report.projectReports.sort((a, b) => a.projectName.localeCompare(b.projectName, "ja-JP"))
  }, [props.report.projectReports])

  const projectReportsElements = useMemo(() => {
    return sortedProjectReports.map((projectReport) => {
      return (
        <tr key={projectReport.projectId}>
          <td className="single line">{projectReport.projectName}</td>
          <td className="right aligned single line">-</td>
          <td className="right aligned single line">{projectReport.assignedWorkingHours}</td>
          <td className="right aligned single line">{projectReport.actualWorkingHours}</td>
        </tr>
      )
    })
  }, [sortedProjectReports])

  const summaryReport: SummaryReport = useMemo(() => {
    const [totalAssignedWorkingHours, totalActualWorkingHours] = props.report.projectReports.reduce(
      ([totalAssignedWorkingHours, totalActualWorkingHours], projectReport) => {
        return [
          totalAssignedWorkingHours + projectReport.assignedWorkingHours,
          totalActualWorkingHours + projectReport.actualWorkingHours,
        ]
      },
      [0, 0]
    )
    return {
      totalExpectedWorkingHours: roundReport(props.report.expectedWorkingHours),
      totalAssignedWorkingHours: roundReport(totalAssignedWorkingHours),
      totalActualWorkingHours: roundReport(totalActualWorkingHours),
    }
  }, [props.report])

  return (
    <table className="ui celled striped table">
      <thead>
        <tr>
          <th className="seven wide">プロジェクト名</th>
          <th className="right aligned single line three wide">予定稼働時間</th>
          <th className="right aligned single line three wide">アサイン時間</th>
          <th className="right aligned single line three wide">実績時間</th>
        </tr>
      </thead>
      <tbody>
        <SummaryTr>
          <td>合計</td>
          <td className="right aligned single line">{summaryReport.totalExpectedWorkingHours}</td>
          <td className="right aligned single line">{summaryReport.totalAssignedWorkingHours}</td>
          <td className="right aligned single line">{summaryReport.totalActualWorkingHours}</td>
        </SummaryTr>
        {projectReportsElements}
      </tbody>
    </table>
  )
})

export default ReportsTable
