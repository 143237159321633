import { WorkspaceTagApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { WorkspaceTag } from "model"

export class WorkspaceTagApiClientMock implements WorkspaceTagApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  getWorkspaceTags = async (workspaceId: string): Promise<WorkspaceTag[]> => {
    return [
      {
        workspaceTagId: "foo",
        workspaceId: workspaceId,
        workspaceTagName: "company:けものかんぱにー",
        createdDatetime: "2020-01-01T12:00:00Z",
        updatedDatetime: "2020-01-01T12:00:00Z",
      },
    ]
  }

  getWorkspaceMemberTags = async (workspaceId: string, workspaceMemberId: string): Promise<WorkspaceTag[]> => {
    return []
  }
}
