import { styled } from "linaria/react"
import React, { useCallback } from "react"
import ReactModal from "react-modal"
import { useHistory } from "react-router-dom"

import { clients } from "api/clients"
import * as m from "model"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "340px",
    height: "200px",
    padding: 0,
  },
}

const ModalHeader = styled.h2`
  padding: 1rem 1rem 0 1rem;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  height: 100%;
  width: 100%;
`
const ModalFooter = styled.div`
  display: flex;
  justify-content: right;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
  .ui.button {
    margin-left: 0.75em;
  }
`
const Divider = styled.div`
  &.ui.divider {
    margin: 0;
  }
`

type DeleteConfirmationModalProps = {
  job: m.Job
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  setJobs?: (f: (prev: m.Job[]) => m.Job[]) => void
}

const DeleteConfirmationModal: React.FC<DeleteConfirmationModalProps> = React.memo((props) => {
  const history = useHistory()
  const deleteJob = useCallback(() => {
    clients.job
      .deleteJob(props.job.workspaceId, props.job.jobId)
      .then((deletedJob) => {
        if (m.isProject(deletedJob)) {
          history.push(`/workspaces/${deletedJob.workspaceId}/projects`)
        } else {
          if (props.setJobs) {
            props.setJobs((jobs) =>
              jobs.filter((x) => x.workspaceId !== deletedJob.workspaceId || x.jobId !== deletedJob.jobId)
            )
          }
          props.setIsOpen(false)
        }
      })
      .catch((error: Error) => window.alert(error.message))
  }, [props, history])

  return (
    <ReactModal isOpen={props.isOpen} style={modalStyle} onRequestClose={() => props.setIsOpen(false)}>
      <ModalHeader>{m.isProject(props.job) ? "プロジェクト" : "ジョブ"}の削除</ModalHeader>
      <Divider className="ui divider"></Divider>
      <ModalContent>
        <span>
          本当に<b>{`「${props.job.jobName}」`}</b>を削除しますか？
        </span>
      </ModalContent>
      <ModalFooter>
        <button className="ui olive ok button rounded-pill" onClick={deleteJob}>
          削除
        </button>
        <button className="ui orange cancel delete button rounded-pill" onClick={() => props.setIsOpen(false)}>
          キャンセル
        </button>
      </ModalFooter>
    </ReactModal>
  )
})

export default DeleteConfirmationModal
