import moment from "moment"
import React, { useState, useCallback } from "react"
import { Button, Grid, Popup, Modal, Message } from "semantic-ui-react"

import * as c from "component/common/expectedWorkingTime/common"
import * as m from "model"

type WeekMenuColumnProps = {
  startDateOfWeek: m.DateOnly
  expectedWorkingTimesOfWeek: c.ExpectedWorkingTimeOfDay[]
  updateExpectedWorkingTimesOfWeek: (startDateOfWeek: m.DateOnly, expectedWorkingTimesOfWeek: number[]) => Promise<void>
  copyWeeks: (sourceStartDateOfWeek: m.DateOnly, copyWeeksNumber: number) => Promise<void>
}

export const WeekMenuColumn: React.FC<WeekMenuColumnProps> = (props) => {
  const [openPopup, setOpenPopup] = useState(false)
  const [openCopyModal, setOpenCopyModal] = useState(false)
  const [openEditModal, setOpenEditModal] = useState(false)
  const [copyWeeks, setCopyWeeks] = useState<number | null>(1)
  const [isValidCopyWeeksValue, setIsValidCopyWeeksValue] = useState(true)
  const [editExpectedWorkingTimeValues, setEditExpectedWorkingTimeValues] = useState<string[]>([])
  const [editExpectedWorkingTimes, setEditExpectedWorkingTimes] = useState<number[]>([])

  const validateCopyWeeks = (): number | null => {
    const copyWeeksNumber = Number(copyWeeks)
    if (!Number.isNaN(copyWeeksNumber) && 1 <= copyWeeksNumber && copyWeeksNumber <= 12) {
      setIsValidCopyWeeksValue(true)
      return copyWeeksNumber
    } else {
      setIsValidCopyWeeksValue(false)
      return null
    }
  }
  const onClickEdit = (): void => {
    setEditExpectedWorkingTimes(
      props.expectedWorkingTimesOfWeek.map(
        (expectedOfWeek) => expectedOfWeek.expectedWorkingTime?.expectedWorkingHours ?? 0
      )
    )
    setEditExpectedWorkingTimeValues(
      props.expectedWorkingTimesOfWeek.map((expectedOfWeek) =>
        c.hoursToString(expectedOfWeek.expectedWorkingTime?.expectedWorkingHours ?? 0)
      )
    )
    setOpenEditModal(true)
    setOpenPopup(false)
  }
  const onClickCopy = (): void => {
    setOpenCopyModal(true)
    setOpenPopup(false)
  }
  const onChangeCopyWeeks = useCallback((e: React.ChangeEvent<HTMLInputElement>, data) => {
    setCopyWeeks(data.value)
  }, [])

  const onSubmitCopy = async (): Promise<void> => {
    const copyWeeksNumber = validateCopyWeeks()
    if (copyWeeksNumber) await props.copyWeeks(props.startDateOfWeek, copyWeeksNumber)
  }

  const onChangeEditExpectedWorkingTime = (index: number, value: string): void => {
    setEditExpectedWorkingTimeValues(editExpectedWorkingTimeValues.map((x, i) => (i === index ? value : x)))
  }
  const onBlurEditExpectedWorkingTime = (index: number): void => {
    const inputHours = c.stringToHours(editExpectedWorkingTimeValues[index])
    const hoursString = c.hoursToString(inputHours ?? editExpectedWorkingTimes[index])
    setEditExpectedWorkingTimeValues(editExpectedWorkingTimeValues.map((x, i) => (i === index ? hoursString : x)))
    if (inputHours !== null) {
      setEditExpectedWorkingTimes(editExpectedWorkingTimes.map((x, i) => (i === index ? inputHours : x)))
    }
  }

  const onSubmitEdit = async (): Promise<void> => {
    await props.updateExpectedWorkingTimesOfWeek(props.startDateOfWeek, editExpectedWorkingTimes)
  }

  return (
    <>
      <Popup
        on="click"
        pinned
        position="left center"
        flowing
        hoverable
        open={openPopup}
        onOpen={() => setOpenPopup(true)}
        onClose={() => setOpenPopup(false)}
        trigger={<Button icon="ellipsis vertical" className={c.popupButton} />}
      >
        <c.PopupMenuRow>
          <c.PopupMenuButton basic color="grey" onClick={onClickEdit}>
            編集
          </c.PopupMenuButton>
        </c.PopupMenuRow>
        <c.PopupMenuRow>
          <c.PopupMenuButton basic color="grey" onClick={onClickCopy}>
            コピー
          </c.PopupMenuButton>
        </c.PopupMenuRow>
      </Popup>
      <Modal
        header="編集"
        content={
          <div className="content">
            <Grid textAlign="center">
              <Grid.Row>
                {editExpectedWorkingTimes.map((editExpectedWorkingTime, i) => {
                  const date = props.startDateOfWeek.add(i)
                  const workingClassName = c.getWorkingClassName(date)
                  return (
                    <Grid.Column key={i} width={2}>
                      <div className={`${c.itemNameWeek} ${workingClassName}`}>
                        {moment(date.utcZeroHourDate).format("ddd")}
                      </div>
                      <div className={`${c.itemNameDay} ${workingClassName}`}>
                        {moment(date.utcZeroHourDate).format("D")}
                      </div>
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
              <Grid.Row>
                {editExpectedWorkingTimeValues.map((editExpectedWorkingTimeValue, i) => {
                  return (
                    <Grid.Column key={i} width={2}>
                      <c.EditExpectedWorkingTimeInput
                        value={editExpectedWorkingTimeValue}
                        onChange={(e, data) => onChangeEditExpectedWorkingTime(i, data.value)}
                        onBlur={() => onBlurEditExpectedWorkingTime(i)}
                        fluid
                      />
                    </Grid.Column>
                  )
                })}
              </Grid.Row>
            </Grid>
          </div>
        }
        open={openEditModal}
        onOpen={() => setOpenEditModal(true)}
        onClose={() => setOpenEditModal(false)}
        actions={[{ key: "submit", content: "決定", positive: true, onClick: onSubmitEdit }]}
      />
      <Modal
        size="mini"
        header="以降の週へのコピー"
        content={
          <div className="content">
            <Grid verticalAlign="middle">
              <Grid.Row>
                <Grid.Column width={6} className="label-text">
                  <span className="item-text">選択した週から</span>
                </Grid.Column>
                <Grid.Column width={6}>
                  <c.CopyWeekInput
                    focus
                    onChange={onChangeCopyWeeks}
                    onBlur={validateCopyWeeks}
                    value={copyWeeks}
                    label={{ basic: true, content: "週" }}
                    labelPosition="right"
                    fluid
                  />
                </Grid.Column>
                <Grid.Column width={3}>
                  <span className="item-text">コピー</span>
                </Grid.Column>
              </Grid.Row>
              <Grid.Row>
                <Grid.Column textAlign="center">
                  <Message error hidden={isValidCopyWeeksValue}>
                    1～12の数値を指定してください
                  </Message>
                </Grid.Column>
              </Grid.Row>
            </Grid>
          </div>
        }
        open={openCopyModal}
        onOpen={() => setOpenCopyModal(true)}
        onClose={() => setOpenCopyModal(false)}
        actions={[
          { key: "submit", content: "決定", positive: true, onClick: onSubmitCopy, disabled: !isValidCopyWeeksValue },
        ]}
      />
    </>
  )
}
