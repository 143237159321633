import { styled } from "linaria/react"
import React, { useEffect, useState, useMemo, useCallback } from "react"
import { useHistory, useLocation } from "react-router-dom"
import ReactSelect, { StylesConfig } from "react-select"

import { clients } from "api/clients"
import { LoadingDimmer, DatesRangePicker, getDatesRangeString, hasValidDatesRangeFormat } from "component/common"
import { ReportsTable, Report, MemberStatusFilter, roundReport } from "component/page/reports/reportsTable"
import * as m from "model"
import * as workspaceTagService from "service/workspaceTagService"
import * as du from "utils/date"

const FlexContainer = styled.div`
  display: flex;
  gap: 20px;
`

const DimmerStyle = {
  marginTop: 20,
  marginBottom: 20,
}

type MemberStatusFilterOption = {
  label: string
  value: MemberStatusFilter
}
const stylesConfig: StylesConfig<MemberStatusFilterOption, false> = {
  container: (styles) => ({ ...styles, width: 200 }),
}
const membersStatusFilterOptions: MemberStatusFilterOption[] = [
  { value: "all", label: "状態 : すべて" },
  { value: "active", label: "状態 : 活動中" },
  { value: "inactive", label: "状態 : 脱退済み" },
]

const getTotalExpectedWorkingHoursByMember = (
  expectedWorkingTimes: m.ExpectedWorkingTime[]
): Map<m.WorkspaceMemberId, number> => {
  return expectedWorkingTimes.reduce((acc, expectedWorkingTime) => {
    const totalExpectedWorkingHours =
      (acc.get(expectedWorkingTime.workspaceMemberId) ?? 0) + expectedWorkingTime.expectedWorkingHours
    return acc.set(expectedWorkingTime.workspaceMemberId, totalExpectedWorkingHours)
  }, new Map<m.WorkspaceMemberId, number>())
}

const getTotalAssignedWorkingHoursByMember = (
  schedules: m.Schedule[],
  expectedWorkingTimes: m.ExpectedWorkingTime[],
  termStart: Date,
  termEnd: Date
): Map<m.WorkspaceMemberId, number> => {
  const dailyExpectedWorkingHoursByMember = expectedWorkingTimes.reduce((acc, expectedWorkingTime) => {
    const dailyExpectedWorkingHours =
      acc.get(expectedWorkingTime.workspaceMemberId) ?? new Map<du.LocalDateString, number>()
    const updated = dailyExpectedWorkingHours.set(
      expectedWorkingTime.date.toZeroHourLocalDate().toLocaleDateString(),
      expectedWorkingTime.expectedWorkingHours
    )
    return acc.set(expectedWorkingTime.workspaceMemberId, updated)
  }, new Map<m.WorkspaceMemberId, Map<du.LocalDateString, number>>())

  return schedules.reduce((acc, schedule) => {
    const diffDays =
      du.toDays(schedule.endDate.utcZeroHourDate.getTime() - schedule.startDate.utcZeroHourDate.getTime()) + 1
    const dailyExpectedWorkingHours =
      dailyExpectedWorkingHoursByMember.get(schedule.workspaceMemberId) ?? new Map<du.LocalDateString, number>()
    const assignedWorkingHours = Array.from({ length: diffDays }).reduce((acc: number, _, index) => {
      const date = du.plusDays(schedule.startDate.toZeroHourLocalDate(), index)
      if (date < termStart || termEnd < date) {
        return acc
      }
      if (schedule.type === "hours") {
        return acc + schedule.value
      } else {
        const expectedWorkingHours = dailyExpectedWorkingHours.get(date.toLocaleDateString()) ?? 0
        return (expectedWorkingHours * schedule.value) / 100 + acc
      }
    }, 0)
    const totalAssignedWorkingHours = (acc.get(schedule.workspaceMemberId) ?? 0) + assignedWorkingHours
    return acc.set(schedule.workspaceMemberId, totalAssignedWorkingHours)
  }, new Map<m.WorkspaceMemberId, number>())
}

const getTotalActualWorkingHoursByMember = (
  actualWorkingTimes: m.ActualWorkingTime[],
  termStart: Date,
  termEnd: Date
): Map<m.WorkspaceMemberId, number> => {
  return actualWorkingTimes.reduce((acc, actualWorkingTime) => {
    const actualWorkingMs = ((startDatetime: Date, endDatetime: Date): number => {
      const isStartIncluded = termStart <= startDatetime && startDatetime <= termEnd
      const isEndIncluded = termStart <= endDatetime && endDatetime <= termEnd
      if (isStartIncluded && isEndIncluded) {
        return endDatetime.getTime() - startDatetime.getTime()
      } else if (isStartIncluded) {
        const nextDayOfStartDatetime = new Date(
          startDatetime.getFullYear(),
          startDatetime.getMonth(),
          startDatetime.getDate() + 1
        )
        return nextDayOfStartDatetime.getTime() - startDatetime.getTime()
      } else if (isEndIncluded) {
        const sameDayOfEndDatetime = new Date(endDatetime.getFullYear(), endDatetime.getMonth(), endDatetime.getDate())
        return endDatetime.getTime() - sameDayOfEndDatetime.getTime()
      } else {
        return 0
      }
    })(actualWorkingTime.startDatetime, actualWorkingTime.endDatetime)
    const actualWorkingHours = du.toHours(actualWorkingMs)
    const totalActualWorkingHours = (acc.get(actualWorkingTime.workspaceMemberId) ?? 0) + actualWorkingHours
    return acc.set(actualWorkingTime.workspaceMemberId, totalActualWorkingHours)
  }, new Map<m.WorkspaceMemberId, number>())
}

const loadReports = async (workspaceId: string, term: string): Promise<Report[]> => {
  const [termStart, termEnd] = term.split("-").map((x) => new Date(x.trim()))
  const [termStartISO8601Str, termEndISO8601Str] = term.split("-").map((x) => x.trim().replaceAll("/", "-"))
  const nextDayOfTermEnd = du.plusDays(termEnd, 1)

  const getMembers = clients.workspaceMember.getWorkspaceMembers(workspaceId, true)
  const getTags = clients.workspaceTag.getWorkspaceTags(workspaceId)
  const getExpectedWorkingTimes = clients.expectedWorkingTime.list(workspaceId, termStartISO8601Str, termEndISO8601Str)
  const getSchedules = clients.schedule.list(workspaceId, termStartISO8601Str, termEndISO8601Str)
  const getActualWorkingTimes = clients.actualWorkingTime.list(workspaceId, termStart, nextDayOfTermEnd)
  const [members, tags, expectedWorkingTimes, schedules, actualWorkingTimes] = await Promise.all([
    getMembers,
    getTags,
    getExpectedWorkingTimes,
    getSchedules,
    getActualWorkingTimes,
  ])

  const companyMap: Map<m.WorkspaceMemberId, string> = await workspaceTagService.getCompanyMap(tags)
  const totalExpectedWorkingHoursByMember: Map<m.WorkspaceMemberId, number> =
    getTotalExpectedWorkingHoursByMember(expectedWorkingTimes)
  const totalAssignedWorkingHoursByMember: Map<m.WorkspaceMemberId, number> = getTotalAssignedWorkingHoursByMember(
    schedules,
    expectedWorkingTimes,
    termStart,
    termEnd
  )
  const totalActualWorkingHoursByMember: Map<m.WorkspaceMemberId, number> = getTotalActualWorkingHoursByMember(
    actualWorkingTimes,
    termStart,
    nextDayOfTermEnd
  )
  return members.map((member) => {
    const expectedWorkingHours = totalExpectedWorkingHoursByMember.get(member.workspaceMemberId) ?? 0
    const assignedWorkignHours = totalAssignedWorkingHoursByMember.get(member.workspaceMemberId) ?? 0
    const actualWorkingHours = totalActualWorkingHoursByMember.get(member.workspaceMemberId) ?? 0
    const notWorkedHours = expectedWorkingHours - actualWorkingHours
    const assignableWorkingHours = expectedWorkingHours - assignedWorkignHours

    return {
      workspaceMemberId: member.workspaceMemberId,
      workspaceId: member.workspaceId,
      username: member.username,
      status: member.status,
      company: companyMap.get(member.workspaceMemberId) ?? "",
      expectedWorkingHours: roundReport(expectedWorkingHours),
      assignedWorkingHours: roundReport(assignedWorkignHours),
      actualWorkingHours: roundReport(actualWorkingHours),
      notWorkedHours: roundReport(notWorkedHours),
      assignableWorkingHours: roundReport(assignableWorkingHours),
    }
  })
}

const getNewSearch = (currentSearch: string, key: string, value: string): string => {
  if (currentSearch === "") {
    return `?${key}=${value}`
  }
  if (currentSearch.includes(`${key}=`)) {
    return currentSearch.replace(new RegExp(`${key}=[^&]*`), `${key}=${value}`)
  }
  return `${currentSearch}&${key}=${value}`
}

// 集計期間は4週間をデフォルトとする
const defaultTermDays = 7 * 4 - 1
const getDefaultTerm = (): string => {
  const now = new Date()
  return getDatesRangeString(now, du.plusDays(now, defaultTermDays))
}
type ReportsPageProps = {
  workspaceId: string | null
}

export const ReportsPage: React.FC<ReportsPageProps> = React.memo((props) => {
  const history = useHistory()
  const location = useLocation()
  const queryParams = useMemo(() => new URLSearchParams(location.search), [location])

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [reports, setReports] = useState<Report[]>([])
  const [memberStatusFilter, setMemberStatusFilter] = useState<MemberStatusFilter>("active")
  const [term, setTerm] = useState<string>("")

  const onMemberStatusFilterChange = useCallback(
    (x) => {
      if (x) {
        const newSearch = getNewSearch(location.search, "status", x.value)
        history.push(`${location.pathname}${newSearch}`)
      }
    },
    [history, location]
  )
  const onDatesRangeChange = useCallback(
    (value) => {
      if (hasValidDatesRangeFormat(value)) {
        const newSearch = getNewSearch(location.search, "term", value)
        history.push(`${location.pathname}${newSearch}`)
      } else {
        setTerm(value)
      }
    },
    [history, location]
  )

  useEffect(() => {
    const statusQuery = queryParams.get("status")
    const termQuery = queryParams.get("term")

    const hasValidStatus = statusQuery === "active" || statusQuery === "inactive" || statusQuery === "all"
    const hasValidTerm = termQuery !== null && hasValidDatesRangeFormat(termQuery)

    if (hasValidStatus) {
      setMemberStatusFilter(statusQuery)
    }
    setTerm(hasValidTerm ? termQuery : getDefaultTerm())
  }, [queryParams, setMemberStatusFilter, setTerm])

  useEffect(() => {
    if (props.workspaceId && hasValidDatesRangeFormat(term)) {
      setIsLoading(true)
      loadReports(props.workspaceId, term)
        .then((x) => setReports(x))
        .finally(() => setIsLoading(false))
    }
  }, [props.workspaceId, term, setReports, setIsLoading])

  return (
    <div className="ui container">
      <LoadingDimmer isLoading={isLoading} style={DimmerStyle}>
        <FlexContainer>
          <ReactSelect
            options={membersStatusFilterOptions}
            onChange={onMemberStatusFilterChange}
            value={membersStatusFilterOptions.find((option) => option.value === memberStatusFilter)}
            styles={stylesConfig}
          />
          <DatesRangePicker
            value={term}
            onChange={(event, { value }) => onDatesRangeChange(value)}
            placeholder="集計期間"
          />
        </FlexContainer>
      </LoadingDimmer>
      <ReportsTable reports={reports} memberStatusFilter={memberStatusFilter}></ReportsTable>
    </div>
  )
})

export default ReportsPage
