import * as m from "model"

const allUser = new Set<m.Role>(["worker", "manager", "owner"])
const manager = new Set<m.Role>(["manager", "owner"])

export interface Page {
  name: string
  pattern: RegExp
  allowedRoles: Set<m.Role>
  isNoLoginPage?: boolean
  isProjectPage?: boolean
  isMemberPage?: boolean
}

export const pages: Array<Page> = [
  {
    name: "login",
    pattern: /^\/login$/,
    allowedRoles: allUser,
    isNoLoginPage: true,
  },
  {
    name: "sign-up",
    pattern: /^\/sign-up$/,
    allowedRoles: allUser,
    isNoLoginPage: true,
  },
  {
    name: "confirm-sign-up",
    pattern: /^\/confirm-sign-up$/,
    allowedRoles: allUser,
    isNoLoginPage: true,
  },
  {
    name: "reset-password",
    pattern: /^\/reset-password$/,
    allowedRoles: allUser,
    isNoLoginPage: true,
  },
  {
    name: "confirm-reset-password",
    pattern: /^\/confirm-reset-password$/,
    allowedRoles: allUser,
    isNoLoginPage: true,
  },
  {
    name: "my",
    pattern: /^\/workspaces\/[^/]+\/my$/,
    allowedRoles: allUser,
  },
  {
    name: "availability",
    pattern: /^\/workspaces\/[^/]+\/availability$/,
    allowedRoles: allUser,
  },
  {
    name: "actual",
    pattern: /^\/workspaces\/[^/]+\/actual$/,
    allowedRoles: allUser,
  },
  {
    name: "schedule",
    pattern: /^\/workspaces\/[^/]+\/schedule$/,
    allowedRoles: manager,
  },
  {
    name: "reports",
    pattern: /^\/workspaces\/[^/]+\/reports$/,
    allowedRoles: manager,
  },
  {
    name: "projects",
    pattern: /^\/workspaces\/[^/]+\/projects$/,
    allowedRoles: manager,
    isProjectPage: true,
  },
  {
    name: "project",
    pattern: /^\/workspaces\/[^/]+\/project$/,
    allowedRoles: manager,
    isProjectPage: true,
  },
  {
    name: "members",
    pattern: /^\/workspaces\/[^/]+\/members$/,
    allowedRoles: manager,
    isMemberPage: true,
  },
  {
    name: "member",
    pattern: /^\/workspaces\/[^/]+\/members\/[^/]+$/,
    allowedRoles: manager,
    isMemberPage: true,
  },
  {
    name: "settings",
    pattern: /^\/settings\/account$/,
    allowedRoles: allUser,
  },
]

// React-Routerの "No-Match Route" を使用して、未定義のパスならば全て Not-Foundページが表示される。
// そのため、Not-Foundページのパス名はある程度なんでもよいが、わかりやすさを考慮して "/not-found" とする
// https://reactrouter.com/docs/en/v6/getting-started/tutorial#adding-a-no-match-route
export const notFoundPagePath = "/not-found"
