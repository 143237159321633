import React, { useCallback, useState } from "react"
import { useLocation } from "react-router-dom"

import "component/page/confirmResetPassword/index.css"

import { ConfirmResetPasswordRequest } from "api"
import { clients } from "api/clients"

export type ConfirmResetPasswordPageProps = {
  // no prop
}

export const ConfirmResetPasswordPage: React.FC<ConfirmResetPasswordPageProps> = (props) => {
  const search = useLocation().search
  const [userId, setUserId] = useState("")
  const [password, setPassword] = useState("")
  const [password2, setPassword2] = useState("")
  const [errorMessage, setErrorMessage] = useState("")

  const confirmResetPassword = useCallback(async (): Promise<void> => {
    const params = new URLSearchParams(search)

    setErrorMessage("")

    if (password !== password2) {
      setErrorMessage("新しいパスワードと新しいパスワード（再入力）が異なっています")
      return
    }

    const request: ConfirmResetPasswordRequest = {
      userId: userId,
      confirmationCode: params.get("code") ?? "",
      newPassword: password,
    }
    await clients.account
      .postConfirmResetPassword(request)
      .then(() => {
        //ログイン画面へ遷移
        window.location.href = "/login"
      })
      .catch((error) => {
        //エラーメッセージを画面に表示
        setErrorMessage(error.message)
      })
  }, [search, password, password2, userId])

  const handleChangeUserId = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserId(e.target.value)
  }, [])
  const handleChangePassword = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword(e.target.value)
  }, [])
  const handleChangePassword2 = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setPassword2(e.target.value)
  }, [])

  const isValidField = useCallback((): boolean => {
    return password.length > 0 && password2.length > 0 && userId.length > 0
  }, [password, password2, userId])

  return (
    <div className="ui container">
      <h1 className="ui header" style={{ marginTop: "2em", textAlign: "center" }}>
        新しいパスワード設定
      </h1>
      <div className="aw-confirm-reset-password-form">
        <div className="ui grid segment">
          <div className="one column">
            <form className="ui form" onSubmit={(e) => e.preventDefault()}>
              <div className="required field">
                <label>ユーザーID</label>
                <div className="ui left icon input">
                  <i className="user icon" />
                  <input
                    type="text"
                    name="userId"
                    placeholder="例) Abc_123"
                    value={userId}
                    onChange={handleChangeUserId}
                    required={true}
                  />
                </div>
              </div>
              <div className="ui divider"></div>
              <div className="required field">
                <label>新しいパスワード</label>
                <div className="ui left icon input">
                  <i className="lock icon" />
                  <input
                    type="password"
                    name="password"
                    value={password}
                    onChange={handleChangePassword}
                    required={true}
                  />
                </div>
              </div>
              <div className="ui divider"></div>
              <div className="required field">
                <label>新しいパスワード(再入力)</label>
                <div className="ui left icon input">
                  <i className="lock icon" />
                  <input
                    type="password"
                    name="password2"
                    value={password2}
                    onChange={handleChangePassword2}
                    required={true}
                  />
                </div>
              </div>
              <div className="ui divider"></div>

              <button
                className="ui fluid large olive button"
                type="submit"
                onClick={confirmResetPassword}
                disabled={!isValidField()}
              >
                この内容で登録する
              </button>
            </form>
            <div className="aw-confirm-reset-password-error">
              <span className="ui red text">{errorMessage}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ConfirmResetPasswordPage
