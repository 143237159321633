import { styled } from "linaria/react"
import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"
import ReactSelect, { StylesConfig } from "react-select"

import { clients } from "api/clients"
import { ArchivedLabel, InProgressLabel } from "component/common"
import ProjectCreationModal from "component/page/projects/projectCreationModal"
import * as m from "model"

type ProjectStatusFilter = m.JobStatus | "all"
type ProjectStatusFilterOption = {
  label: string
  value: ProjectStatusFilter
}

const FlexContainer = styled.div`
  display: flex;
  margin-top: 20px;
`
const ProjectNameFilter = styled.div`
  margin-right: 15px;
`
const ProjectCreationButton = styled.button`
  &.ui.button {
    margin-left: auto;
  }
`

export type ProjectsPageProps = {
  workspaceId: string | null
}

type ProjectsTableBodyProps = {
  projects: m.Job[]
  projectNameFilter: string
  projectStatusFilter: ProjectStatusFilter
}

export const projectStatusFilterOptions: ProjectStatusFilterOption[] = [
  { value: "all", label: "状態 : すべて" },
  { value: "unarchived", label: "状態 : 進行中" },
  { value: "archived", label: "状態 : アーカイブ済み" },
]

const stylesConfig: StylesConfig<ProjectStatusFilterOption, false> = {
  container: (styles) => ({ ...styles, width: 200 }),
}

const ProjectsTableBody: React.FC<ProjectsTableBodyProps> = React.memo((props) => {
  const filteredProjects = useMemo(
    () =>
      props.projects.filter((x) => {
        const nameCondition = !props.projectNameFilter || x.jobName.includes(props.projectNameFilter)
        const statusCondition = props.projectStatusFilter === "all" || x.status === props.projectStatusFilter
        return nameCondition && statusCondition
      }),
    [props]
  )
  const projectElements = filteredProjects.map((project) => {
    return (
      <tr key={project.jobId}>
        <td className="single line">
          <Link to={`/workspaces/${project.workspaceId}/project?projectId=${project.jobId}`}>{project.jobName}</Link>
        </td>
        <td className="single line">
          {m.isInProgress(project) ? <InProgressLabel></InProgressLabel> : <ArchivedLabel></ArchivedLabel>}
        </td>
        <td>{project.note}</td>
      </tr>
    )
  })
  return <tbody>{projectElements}</tbody>
})

export const ProjectsPage: React.FC<ProjectsPageProps> = React.memo((props) => {
  const [projects, setProjects] = useState<m.Job[]>([])
  const [projectNameFilter, setProjectNameFilter] = useState<string>("")
  const [projectStatusFilter, setProjectStatusFilter] = useState<ProjectStatusFilter>("unarchived")
  const [isProjectCreationModalOpen, setIsProjectCreationModalOpen] = useState<boolean>(false)

  const loadProjects = async (workspaceId: string): Promise<m.Job[]> => {
    const jobs = await clients.job.list(workspaceId, ["name"])
    return jobs.filter((x) => m.isProject(x))
  }
  useEffect(() => {
    if (props.workspaceId !== null) {
      loadProjects(props.workspaceId).then((projects) => setProjects(projects))
    }
  }, [props.workspaceId])

  if (!props.workspaceId) {
    return <></>
  }

  return (
    <div className="ui container">
      <FlexContainer>
        <ProjectNameFilter className="ui icon input">
          <input
            type="text"
            placeholder="プロジェクト名"
            value={projectNameFilter}
            onChange={(e) => setProjectNameFilter(e.target.value)}
          ></input>
          <i className="search icon"></i>
        </ProjectNameFilter>
        <ReactSelect
          options={projectStatusFilterOptions}
          onChange={(x) => {
            if (x) setProjectStatusFilter(x.value)
          }}
          value={projectStatusFilterOptions.find((option) => option.value === projectStatusFilter)}
          styles={stylesConfig}
        />
        <ProjectCreationButton
          className="ui olive ok button rounded-pill"
          onClick={() => setIsProjectCreationModalOpen(true)}
        >
          プロジェクト作成
        </ProjectCreationButton>
      </FlexContainer>
      <table className="ui striped table">
        <thead>
          <tr>
            <th className="signle line six wide">プロジェクト名</th>
            <th className="single line four wide">状態</th>
            <th className="single line six wide">備考</th>
          </tr>
        </thead>
        <ProjectsTableBody
          projects={projects}
          projectNameFilter={projectNameFilter}
          projectStatusFilter={projectStatusFilter}
        />
      </table>
      <ProjectCreationModal
        workspaceId={props.workspaceId}
        isOpen={isProjectCreationModalOpen}
        setIsOpen={setIsProjectCreationModalOpen}
      ></ProjectCreationModal>
    </div>
  )
})

export default ProjectsPage
