import React, { useEffect, useState, useMemo } from "react"
import { Link, NavLink, useHistory, useLocation } from "react-router-dom"
import { Dropdown, DropdownItemProps, DropdownProps } from "semantic-ui-react"

import { ApiError } from "api/apiError"
import { clients } from "api/clients"
import { WorkspaceStore } from "dataStore/workspaceStore"
import * as m from "model"
import { AppStore } from "model/appStore"
import { pages, notFoundPagePath } from "model/page"
import "component/header.css"
import { Dispatch, Reducer } from "reducer/common"

export type HeaderProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
}

export const Header: React.FC<HeaderProps> = (props) => {
  const [dropdownMenu, setDropdownMenu] = useState<DropdownItemProps[]>([])
  const location = useLocation()
  const history = useHistory()
  const displayValue = (pageName: string): "none" | "" => {
    const role = props.workspaceMember?.role
    const page = pages.find((x) => x.name === pageName)
    if (!role || !page) return "none"
    return page.allowedRoles.has(role) ? "" : "none"
  }

  const splitPathname = useMemo(() => location.pathname.split("/"), [location.pathname])
  const indexOfWorkspaceId = useMemo(() => splitPathname.indexOf("workspaces") + 1, [splitPathname])
  const currentPage = useMemo(() => pages.find((x) => x.pattern.test(location.pathname)), [location.pathname])
  const isCurrentPageNotFound = useMemo(() => !currentPage, [currentPage])

  const logout = (): void => {
    props.dispatch.apply(props.reducer.resetToken())
    props.dispatch.apply(props.reducer.resetUserId())
    history.push("/login")
  }

  const changeWorkspace = async (_: React.SyntheticEvent<HTMLElement>, dropdownProps: DropdownProps): Promise<void> => {
    if (typeof dropdownProps.value !== "string") return
    const workspaceId = dropdownProps.value

    const workspaceMember = (await clients.myInfo.getMyWorkspaceMembers(workspaceId))[0]
    if (!workspaceMember) return

    props.dispatch.apply(props.reducer.setWorkspaceId(workspaceId))
    props.dispatch.apply(props.reducer.setWorkspaceMember(workspaceMember))

    WorkspaceStore.setWorkspaceId(workspaceId)
    if (indexOfWorkspaceId > 0) splitPathname[indexOfWorkspaceId] = workspaceId
    if (currentPage && !currentPage.allowedRoles.has(workspaceMember.role)) {
      splitPathname[indexOfWorkspaceId + 1] = "my"
      history.push(splitPathname.join("/"))
    } else if (currentPage?.isProjectPage) {
      splitPathname[indexOfWorkspaceId + 1] = "projects"
      history.push(splitPathname.join("/"))
    } else if (currentPage?.isMemberPage) {
      const membersPath = `/workspaces/${splitPathname[indexOfWorkspaceId]}/members`
      history.push(membersPath)
    } else {
      history.push({
        pathname: splitPathname.join("/"),
        search: location.search,
        hash: location.hash,
      })
    }
  }

  useEffect(() => {
    const handle = async (): Promise<void> => {
      if (currentPage?.isNoLoginPage) return
      if (!props.workspaceId && !isCurrentPageNotFound) {
        history.push(notFoundPagePath)
      }

      const [workspaces, workspaceMembers] = await Promise.all([
        clients.myInfo.getMyWorkspaces(),
        props.workspaceId ? clients.myInfo.getMyWorkspaceMembers(props.workspaceId) : Promise.resolve([]),
      ])
      const dropdownMenu = workspaces.map((workspace) => ({
        key: workspace.workspaceId,
        text: workspace.workspaceName,
        value: workspace.workspaceId,
      }))
      setDropdownMenu(dropdownMenu)

      const workspaceMember = workspaceMembers[0]
      if (!workspaceMember || (currentPage && !currentPage.allowedRoles.has(workspaceMember.role))) {
        if (!isCurrentPageNotFound) history.push(notFoundPagePath)
        return
      }
      WorkspaceStore.setWorkspaceId(workspaceMember.workspaceId)
    }
    handle().catch((e: ApiError) => {
      if (e.status === 401) {
        history.push("/login")
        return
      }
      throw e
    })
  }, [location.pathname, props.workspaceId, currentPage, isCurrentPageNotFound, history])

  return (
    <header className="aw-header">
      <div className="aw-header-inner">
        <div className="aw-header-item aw-header-item-logo">
          <img className="aw-header-img" src="/logo.png" alt="logo" />
        </div>
        <div className="aw-product-name">ANNOWORK</div>
        {!currentPage?.isNoLoginPage && (
          <>
            <div className="aw-header-item aw-header-item-full">
              <NavLink
                to={`/workspaces/${props.workspaceId}/my`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("my") }}
              >
                マイスケジュール
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/actual`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("actual") }}
              >
                実績
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/availability`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("availability") }}
              >
                予定稼働
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/schedule`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("schedule") }}
              >
                スケジュール
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/reports`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("reports") }}
              >
                レポート
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/projects`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("projects") }}
              >
                プロジェクト
              </NavLink>
              <NavLink
                to={`/workspaces/${props.workspaceId}/members`}
                className="aw-menu-button"
                activeClassName="aw-current-content"
                style={{ display: displayValue("members") }}
              >
                メンバー
              </NavLink>
            </div>
            <div className="aw-header-item">
              <div className="aw-header-dropdown">
                <Dropdown
                  selection
                  options={dropdownMenu}
                  defaultValue={props.workspaceId ?? ""}
                  direction="left"
                  onChange={changeWorkspace}
                />
              </div>
            </div>
            <div className="aw-header-item">
              <div className="aw-header-item">
                <Link to="/settings/account">
                  <img src="/personCircle.svg" alt="" />
                </Link>
              </div>
              <div className="aw-header-item">
                <div className="aw-logout">
                  <img src="/logout.svg" alt="" onClick={logout} />
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </header>
  )
}

export default Header
