import { MyInfoApiClient } from "api"
import { HttpApiClient } from "api/httpApiClient"
import { ScheduleResponse, toSchedule } from "api/v1/scheduleApiClientImpl"
import { WorkspaceResponse, toWorkspace } from "api/v1/workspaceApiClientImpl"
import { WorkspaceMemberResponse, toWorkspaceMember } from "api/v1/workspaceMemberApiClientImpl"
import * as m from "model"

interface AccountResponse {
  accountId: string
  userId: string
  username: string
  email: string
  locale: m.Locale
  authority: "user" | "admin"
  externalLinkageInfo: m.AccountExternalLinkageInfo
  createdDatetime: string
  updatedDatetime: string
}

const toAccount = (data: AccountResponse): m.Account =>
  Object.assign(data, {
    createdDatetime: new Date(data.createdDatetime),
    updatedDatetime: new Date(data.updatedDatetime),
  }) as m.Account

export class MyInfoApiClientImpl implements MyInfoApiClient {
  constructor(private readonly client: HttpApiClient = new HttpApiClient()) {}

  getMyAccount = async (): Promise<m.Account> => {
    const path = "/api/v1/my/account"
    const res = await this.client.getWithAuth<AccountResponse>(path)
    return toAccount(res.data)
  }

  async putMyAccount(request: m.PutMyAccountRequest): Promise<m.Account> {
    return await this.client.putWithAuth<AccountResponse>("/api/v1/my/account", request).then((response) => {
      return toAccount(response.data)
    })
  }

  async changePassword(oldPassword: string, newPassword: string): Promise<void> {
    await this.client.postWithAuth<{ old_password: string; new_password: string }>("/api/v1/my/account/password", {
      old_password: oldPassword,
      new_password: newPassword,
    })
  }

  getMyWorkspaces = async (): Promise<m.Workspace[]> => {
    const path = "/api/v1/my/workspaces"
    const res = await this.client.getWithAuth<WorkspaceResponse[]>(path)
    return res.data.map(toWorkspace)
  }

  getMyWorkspaceMembers = async (workspaceId?: string): Promise<m.WorkspaceMember[]> => {
    const path = "/api/v1/my/workspace-members"
    const param = new Map<string, string>()
    if (workspaceId) param.set("workspace_id", workspaceId)
    const res = await this.client.getWithAuth<WorkspaceMemberResponse[]>(path, param)
    return res.data.map(toWorkspaceMember)
  }

  getMySchedules = async (workspaceId?: string, termStart?: string, termEnd?: string): Promise<m.Schedule[]> => {
    const path = "/api/v1/my/schedules"
    const param = new Map<string, string>()
    if (workspaceId) param.set("workspace_id", workspaceId)
    if (termStart) param.set("term_start", termStart)
    if (termEnd) param.set("term_end", termEnd)
    const res = await this.client.getWithAuth<ScheduleResponse[]>(path, param)
    return res.data.map(toSchedule)
  }
}
