import moment from "moment"
import React, { useMemo } from "react"
import { Grid } from "semantic-ui-react"

import * as c from "component/common/expectedWorkingTime/common"
import * as m from "model"

type WeekSummaryColumnProps = {
  startDateOfWeek: m.DateOnly
  expectedWorkingTimeMap: Map<string, m.ExpectedWorkingTime>
}

export const WeekSummaryColumn: React.FC<WeekSummaryColumnProps> = (props) => {
  const endDateOfWeek = useMemo(() => props.startDateOfWeek.add(6), [props.startDateOfWeek])
  const sumOfHours = useMemo(() => {
    const sum = c
      .getExpectedWorkingTimesOfWeek(props.expectedWorkingTimeMap, props.startDateOfWeek)
      .reduce((acc, expected) => (expected.expectedWorkingTime?.expectedWorkingHours ?? 0) + acc, 0)
    return Math.round(sum * 100) / 100
  }, [props.expectedWorkingTimeMap, props.startDateOfWeek])

  return (
    <Grid.Column width={3} className="outer-row-column">
      <span>
        {c.formatDateWithSlashSeparater(props.startDateOfWeek)} - {c.formatDateWithSlashSeparater(endDateOfWeek)}
        <br />
        合計 <c.TotalValue>{sumOfHours}</c.TotalValue> 時間
      </span>
    </Grid.Column>
  )
}

type WeekHeaderColumnProps = {
  date: m.DateOnly
}

export const WeekHeaderColumn: React.FC<WeekHeaderColumnProps> = (props) => {
  const itemNameBorderClassName = useMemo(() => {
    const rightEdge = props.date.utcZeroHourDate.getDay() === 6 ? ["aw-right-edge"] : []
    return [c.itemNameBorder, ...rightEdge, ...c.getDayClassNames(props.date)].join(" ")
  }, [props.date])
  const workingClassName = useMemo(() => c.getWorkingClassName(props.date), [props.date])

  return (
    <c.DayOfWeekAndDayGridColumn className={itemNameBorderClassName}>
      <div className={`${c.itemNameWeek} ${workingClassName}`}>{moment(props.date.utcZeroHourDate).format("ddd")}</div>
      <div className={`${c.itemNameDay} ${workingClassName}`}>{moment(props.date.utcZeroHourDate).format("D")}</div>
    </c.DayOfWeekAndDayGridColumn>
  )
}

type WeekHoursColumnProps = {
  expectedWorkingTimeOfDay: c.ExpectedWorkingTimeOfDay
}

export const WeekHoursColumn: React.FC<WeekHoursColumnProps> = (props) => {
  const columnClassName = useMemo(() => {
    const textBorderClassName =
      props.expectedWorkingTimeOfDay.date.utcZeroHourDate.getDay() === 6 ? ["aw-right-edge"] : []
    return [...textBorderClassName, ...c.getDayClassNames(props.expectedWorkingTimeOfDay.date)].join(" ")
  }, [props.expectedWorkingTimeOfDay.date])

  return (
    <c.ExpectedHoursTextBorderGridColumn className={columnClassName}>
      <c.ExpectedHoursTextValue>
        {c.hoursToString(props.expectedWorkingTimeOfDay.expectedWorkingTime?.expectedWorkingHours ?? 0)}
      </c.ExpectedHoursTextValue>
    </c.ExpectedHoursTextBorderGridColumn>
  )
}
