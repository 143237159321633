import React, { useCallback, useEffect, useState } from "react"
import { Tab, TabProps } from "semantic-ui-react"

import { clients } from "api/clients"
import { Account, PutMyAccountRequest } from "model"
import { AppStore } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"

export type MyAccountSettingProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  // no prop
}

export const MyAccountSettingPage: React.FC<MyAccountSettingProps> = (props) => {
  const [account, setAccount] = useState<Account>()
  const [userId, setUserId] = useState("")
  const [username, setUsername] = useState("")
  const [oldPassword, setOldPassword] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const [passwordShown, setPasswordShown] = useState(false)
  const [errorMessage, setErrorMessage] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)

  useEffect(() => {
    clients.myInfo.getMyAccount().then((account) => {
      props.dispatch.apply(props.reducer.setUserId(account.userId))
      setAccount(account)
      setUserId(account.userId)
      setUsername(account.username)
    })
  }, [props.dispatch, props.reducer])

  const panes = [
    {
      menuItem: "基本設定",
      render: () => (
        <Tab.Pane>
          <h1 className="ui header">
            基本設定
            <div className="sub header">
              以下の項目はAnnowork上であなたと同じワークスペースに所属する他のユーザーに公開されます。
            </div>
          </h1>
          <form className="ui form" onSubmit={(e) => e.preventDefault()}>
            <table className="ui definition table">
              <tbody>
                <tr>
                  <td className="required field">
                    <label>ユーザーID</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="例）Abc_123"
                      maxLength={30}
                      pattern="\w{3,30}"
                      required={true}
                      className="ui input"
                      value={userId}
                      onChange={handleChangeUserId}
                    />
                    <div className="ui small list">
                      <div className="item">半角英数字とアンダースコア(_)が使用できます。</div>
                      <div className="item">3文字～30文字。</div>
                      <div className="item">ログイン時に使用するIDで、他の人と同じにすることはできません。</div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="required field">
                    <label>ユーザー名</label>
                  </td>
                  <td>
                    <input
                      type="text"
                      placeholder="例）yamada tarou"
                      maxLength={60}
                      pattern=".{1,60}"
                      required={true}
                      className="ui input"
                      value={username}
                      onChange={handleChangeUsername}
                    />
                    <div className="ui small list">
                      <div className="item">任意の文字が使えます。</div>
                      <div className="item">1文字～60文字。</div>
                      <div className="item">
                        同名ユーザーが複数いても区別できるように、フルネームにするか、ニックネームなどを推奨します。
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="field"></td>
                  <td className="single line">
                    <div className="ui right aligned">
                      <button type="submit" className="ui olive button" onClick={updateAccount}>
                        アカウント設定を更新
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="ui error message" style={{ display: errorMessage === "" ? "none" : "" }}>
            <ul>
              <li>{errorMessage}</li>
            </ul>
          </div>
          <div className="ui positive message" style={{ display: !isSuccess ? "none" : "" }}>
            <div className="center aligned">アカウント設定を更新しました。</div>
          </div>
        </Tab.Pane>
      ),
    },
    {
      menuItem: "パスワード設定",
      render: () => (
        <Tab.Pane>
          <h1 className="ui header">パスワード設定</h1>
          <form className="ui form" onSubmit={(e) => e.preventDefault()}>
            <table className="ui definition table">
              <tbody>
                <tr>
                  <td className="required field collapsing">
                    <label>現在のパスワード</label>
                  </td>
                  <td className="field">
                    <input
                      type="password"
                      required={true}
                      className="ui input"
                      value={oldPassword}
                      onChange={handleChangeOldPassword}
                    />
                  </td>
                </tr>
                <tr>
                  <td className="required field collapsing">
                    <label>新しいパスワード</label>
                  </td>
                  <td className="field">
                    <input
                      type={passwordShown ? "text" : "password"}
                      required={true}
                      className="ui input"
                      value={newPassword}
                      onChange={handleChangeNewPassword}
                    />
                    <div className="ui small list">
                      <div className="item">
                        <div className="ui checkbox">
                          <input type="checkbox" id="show-new-password" onChange={handleChangeShowPassword} />
                          <label htmlFor="show-new-password" className="af-checkbox-label">
                            パスワードを表示する
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="ui small list">
                      <div className="item">
                        <strong>パスワードは8文字以上必要です。</strong>
                      </div>
                      <div className="item">
                        <strong>imaomoituitasorenarinopasuwa-do</strong>{" "}
                        のように自分だけが分かる文にすると、安全で覚えやすいパスワードになります。
                      </div>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td className="field"></td>
                  <td className="single line">
                    <div className="ui right aligned">
                      <button type="submit" className="ui olive button" onClick={changePassword}>
                        新しいパスワードに変更
                      </button>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
          </form>
          <div className="ui error message" style={{ display: errorMessage === "" ? "none" : "" }}>
            <div className="center aligned">{errorMessage}</div>
          </div>
          <div className="ui positive message" style={{ display: !isSuccess ? "none" : "" }}>
            <div className="center aligned">パスワードを変更しました。</div>
          </div>
        </Tab.Pane>
      ),
    },
  ]

  const handleChangeUserId = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUserId(e.target.value)
  }, [])
  const handleChangeUsername = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setUsername(e.target.value)
  }, [])
  const handleChangeOldPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setOldPassword(e.target.value)
  }, [])
  const handleChangeNewPassword = useCallback((e: React.ChangeEvent<HTMLInputElement>): void => {
    setNewPassword(e.target.value)
  }, [])
  const handleChangeShowPassword = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>): void => {
      setPasswordShown(!passwordShown)
    },
    [passwordShown]
  )
  const handleChangeTab = useCallback((event: React.MouseEvent<HTMLDivElement>, data: TabProps): void => {
    setIsSuccess(false)
    setErrorMessage("")
  }, [])

  const updateAccount = useCallback(async (): Promise<void> => {
    if (!account) return
    setIsSuccess(false)
    setErrorMessage("")
    const request: PutMyAccountRequest = {
      username: username,
      userId: userId,
      locale: account.locale,
      lastUpdatedDatetime: account.updatedDatetime.toISOString(),
      externalLinkageInfo: account.externalLinkageInfo,
    }
    await clients.myInfo
      .putMyAccount(request)
      .then((account) => {
        setIsSuccess(true)
        setAccount(account)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }, [account, username, userId])

  const changePassword = useCallback(async (): Promise<void> => {
    if (!account) return
    setIsSuccess(false)
    setErrorMessage("")
    await clients.myInfo
      .changePassword(oldPassword, newPassword)
      .then(() => {
        setIsSuccess(true)
      })
      .catch((error) => {
        setErrorMessage(error.message)
      })
  }, [account, oldPassword, newPassword])

  return (
    <div className="ui container">
      <Tab menu={{ fluid: true, vertical: true, tabular: true }} panes={panes} onTabChange={handleChangeTab}></Tab>
    </div>
  )
}

export default MyAccountSettingPage
