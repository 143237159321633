import { css } from "linaria"
import { styled } from "linaria/react"
import React from "react"
import { Button, ButtonProps } from "semantic-ui-react"

import "moment/locale/ja"
import DatePicker from "component/common/DatePicker"

const FlexContainer = styled.div`
  display: flex;
  gap: 5px;
`
const whiteButton = css`
  &.ui.basic.icon.button {
    background-color: white;
  }
`

type DatePickerWithButtonsProps = {
  value: string
  readOnly?: boolean
  placeholder?: string
  /* eslint-disable @typescript-eslint/no-explicit-any */
  onCalendarChange: (e: React.SyntheticEvent<HTMLElement>, data: any) => void
  onCalendarBlur?: (e: React.SyntheticEvent<HTMLElement>) => void
  onPrevClick: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void
  onNextClick: (event: React.MouseEvent<HTMLButtonElement>, data: ButtonProps) => void
}

export const DatePickerWithButtons: React.FC<DatePickerWithButtonsProps> = React.memo((props) => {
  return (
    <FlexContainer>
      <Button className={whiteButton} icon="angle left" basic onClick={props.onPrevClick} />
      <DatePicker
        value={props.value}
        readOnly={props.readOnly}
        placeholder={props.placeholder}
        onChange={props.onCalendarChange}
        onBlur={props.onCalendarBlur}
      />
      <Button className={whiteButton} icon="angle right" basic onClick={props.onNextClick} />
    </FlexContainer>
  )
})

export default DatePickerWithButtons
