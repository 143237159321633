import { styled } from "linaria/react"
import React, { useCallback, useState } from "react"
import { Dropdown } from "semantic-ui-react"

import { clients } from "api/clients"
import DeleteConfirmationModal from "component/page/project/deleteConfirmationModal"
import PutJobModal from "component/page/project/putJobModal"
import * as m from "model"

const Menu = styled.div`
  &.ui.menu {
    height: 20px;
    background-color: inherit;
    border: none;
    box-shadow: none;
    min-height: 0;
  }
  .item::before {
    display: none;
  }
`

type JobActionMenuProps = {
  project: m.Job
  job: m.Job
  setJobs: (f: (prev: m.Job[]) => m.Job[]) => void
}

const JobActionMenu: React.FC<JobActionMenuProps> = React.memo((props) => {
  const [isPutJobModalOpen, setIsPutJobModalOpen] = useState<boolean>(false)
  const [isDeleteConfirmationModalOpen, setIsDeleteConfirmationModalOpen] = useState<boolean>(false)

  const toggleJobStatus = useCallback(() => {
    const body: m.PutJobRequest = {
      jobName: props.job.jobName,
      parentJobId: props.project.jobId,
      status: props.job.status === "unarchived" ? "archived" : "unarchived",
      externalLinkageInfo: props.job.externalLinkageInfo,
      lastUpdatedDatetime: props.job.updatedDatetime,
    }
    clients.job
      .putJob(props.job.workspaceId, props.job.jobId, body)
      .then((newJob) => props.setJobs((jobs) => jobs.map((x) => (x.jobId === newJob.jobId ? newJob : x))))
      .catch((error: Error) => window.alert(error.message))
  }, [props])

  return (
    <>
      <Menu className="ui menu">
        <Dropdown icon="ellipsis vertical" item>
          <Dropdown.Menu>
            <Dropdown.Item text="編集" icon="pencil" onClick={() => setIsPutJobModalOpen(true)} />
            <Dropdown.Item
              text={props.job.status === "unarchived" ? "アーカイブ" : "進行を再開"}
              icon={props.job.status === "unarchived" ? "folder" : "play"}
              onClick={toggleJobStatus}
            />
            {props.job.status === "archived" ? (
              <Dropdown.Item text={"削除"} icon="trash" onClick={() => setIsDeleteConfirmationModalOpen(true)} />
            ) : (
              <></>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </Menu>
      <PutJobModal
        isOpen={isPutJobModalOpen}
        project={props.project}
        job={props.job}
        setIsOpen={setIsPutJobModalOpen}
        setJobs={props.setJobs}
      ></PutJobModal>
      <DeleteConfirmationModal
        job={props.job}
        isOpen={isDeleteConfirmationModalOpen}
        setIsOpen={setIsDeleteConfirmationModalOpen}
        setJobs={props.setJobs}
      ></DeleteConfirmationModal>
    </>
  )
})

export default JobActionMenu
