import * as m from "model"

export const getMemberDateKey = (workspaceMemberId: string, date: m.DateOnly): string =>
  `${date.toISOString()}#${workspaceMemberId}`

export interface ScheduleState {
  members: Map<string, m.WorkspaceMember>
  jobs: Map<string, m.Job>
  expecteds: Map<string, m.ExpectedWorkingTime>
  actuals: Map<string, m.ActualWorkingTime>
  schedules: Map<string, m.Schedule>
}

export interface ScheduleSumState {
  requiresLoadSum: boolean
  sumOfActuals: m.SumOfTimes
  sumOfSchedules: m.SumOfTimes
}

export const initialScheduleState: ScheduleState = {
  members: new Map(),
  jobs: new Map(),
  actuals: new Map(),
  expecteds: new Map(),
  schedules: new Map(),
}

export const initialScheduleSumState = {
  requiresLoadSum: true,
  sumOfActuals: {},
  sumOfSchedules: {},
}
