import { ActualWorkingTimeApiClient } from "api"
import { ActualWorkingTime, PutActualWorkingTimeRequest } from "model"
import { dayms } from "utils/date"

const orgId = "kurusugawa"
const pastTime = new Date("2020-01-01T12:00:00Z")

let actualCount = 0
function createActualWorkTime(
  jobId: string,
  memberId: string,
  startDelta: number,
  endDelta: number,
  date: Date,
  note?: string
): ActualWorkingTime {
  return {
    workspaceId: orgId,
    actualWorkingTimeId: (++actualCount).toString(),
    jobId: jobId,
    workspaceMemberId: memberId,
    startDatetime: new Date(date.getTime() + startDelta),
    endDatetime: new Date(date.getTime() + endDelta),
    createdDatetime: pastTime,
    updatedDatetime: pastTime,
    note: note,
  }
}

export class ActualWorkingTimeApiClientMock implements ActualWorkingTimeApiClient {
  list = async (workspaceId: string, termStart: Date, termEnd: Date, jobId?: string): Promise<ActualWorkingTime[]> => {
    console.log(`ActualWorkingTimeApiClientMock.list(${workspaceId}, ${termStart}, ${termEnd}, ${jobId})`)
    const actuals: ActualWorkingTime[] = [
      createActualWorkTime("0", "k", -5 * dayms + 12 * 60 * 1000, -5 * dayms + 18 * 60 * 1000, termEnd),
      createActualWorkTime("0", "k", -3 * dayms + 12 * 60 * 1000, -3 * dayms + 14 * 60 * 1000, termEnd),
      createActualWorkTime("0", "k", -1 * dayms + 12 * 60 * 1000, -1 * dayms + 20 * 60 * 1000, termEnd),
    ]
    return actuals
  }

  listSum = async (workspaceId: string, jobId?: string): Promise<Record<string, Record<string, number>>> => {
    console.log(`ActualWorkingTimeApiClientMock.listSum(${workspaceId}, ${jobId})`)
    return {
      "2": {
        f: 11,
        k: 12,
        a: 13,
      },
      "3": {
        f: 14,
      },
      "4": {
        s: 15,
        t: 16,
      },
    }
  }

  listByMember = async (
    workspaceId: string,
    workspaceMemberId: string,
    termStart?: Date,
    termEnd?: Date
  ): Promise<ActualWorkingTime[]> => {
    console.log(
      `ActualWorkingTimeApiClientMock.listByMember(${workspaceId}, ${workspaceMemberId}, ${
        termStart?.toISOString() ?? "undefined"
      }, ${termEnd?.toISOString() ?? "undefined"})`
    )
    const validTermEnd = termEnd ?? new Date()
    const actuals: ActualWorkingTime[] = [
      createActualWorkTime(
        "0",
        "k",
        -8 * dayms + 20 * 60 * 60 * 1000,
        -7 * dayms + 3 * 60 * 60 * 1000,
        validTermEnd,
        "noteテスト"
      ),
      createActualWorkTime("k0", "k", -5 * dayms + 12 * 60 * 60 * 1000, -5 * dayms + 18 * 60 * 60 * 1000, validTermEnd),
      createActualWorkTime("k1", "k", -5 * dayms + 19 * 60 * 60 * 1000, -5 * dayms + 20 * 60 * 60 * 1000, validTermEnd),
      createActualWorkTime("0", "k", -3 * dayms + 21 * 60 * 60 * 1000, -2 * dayms + 2 * 60 * 60 * 1000, validTermEnd),
      createActualWorkTime("0", "k", -2 * dayms + 2 * 60 * 60 * 1000, -2 * dayms + 14 * 60 * 60 * 1000, validTermEnd),
      createActualWorkTime(
        "1",
        "k",
        -2 * dayms + (14 * 60 + Math.floor(Math.random() * 60)) * 60 * 1000,
        -2 * dayms + (Math.floor(Math.random() * 540) + 15 * 60) * 60 * 1000,
        validTermEnd,
        "開始と終了がランダム"
      ),
      createActualWorkTime("0", "k", -1 * dayms + 12 * 60 * 60 * 1000, 0 * dayms + 10 * 60 * 60 * 1000, validTermEnd),
    ]
    return actuals
  }

  put = async (request: PutActualWorkingTimeRequest): Promise<ActualWorkingTime> => {
    console.log(
      `ActualWorkingTimeApiClientMock.put(id:${request.actualWorkingTimeId}, org:${request.workspaceId}, member:${
        request.workspaceMemberId
      }, start:${request.startDatetime.toISOString()}, end:${request.endDatetime.toISOString()}, lastUpdated:${
        request.updatedDatetime?.toISOString() ?? ""
      })`
    )
    return { ...request, createdDatetime: new Date(), updatedDatetime: new Date() }
  }

  delete = async (
    workspaceId: string,
    workspaceMemberId: string,
    actualWorkingTimeId: string
  ): Promise<ActualWorkingTime> => {
    console.log(
      `ActualWorkingTimeApiClientMock.delete(id:${actualWorkingTimeId}, org:${workspaceId}, member:${workspaceMemberId})`
    )
    return {
      actualWorkingTimeId,
      workspaceId,
      workspaceMemberId,
      jobId: "",
      startDatetime: new Date(),
      endDatetime: new Date(),
      createdDatetime: new Date(),
      updatedDatetime: new Date(),
    }
  }
}
