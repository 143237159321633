import { clients } from "api/clients"
import { TokenStore } from "dataStore/tokenStore"
import { UserIdStore } from "dataStore/userIdStore"
import { WorkspaceStore } from "dataStore/workspaceStore"
import * as m from "model"
import { AppStore } from "model/appStore"
import { initialScheduleState, initialScheduleSumState } from "model/scheduleState"
import { Reducer, reducer as commonReducer } from "reducer/common"

//import { dayms, dayString, toDateOnlyUnixtimeMs } from "utils/date"
const initialWorkspace = (): string | null => {
  const pathnames = document.location.pathname.split("/")
  const idIndex = pathnames.indexOf("workspaces") + 1
  return idIndex > 0 ? pathnames[idIndex] : WorkspaceStore.getWorkspaceId() ?? ""
}

export const mockReducer: Reducer = {
  ...commonReducer,
  async loadInitialData(store: AppStore): Promise<AppStore> {
    const members = await clients.myInfo.getMyWorkspaceMembers().catch((e) => {
      console.log(`ワークスペースの取得に失敗 : ${e.message ?? ""}`)
      return [] as m.WorkspaceMember[]
    })
    const member = members.filter((member) => member.workspaceId === initialWorkspace())[0]

    const token = "dummy"
    const userId = "user"
    TokenStore.setToken(token)
    UserIdStore.set(userId)
    return {
      ...store,
      token: token,
      userId: userId,
      workspaceId: initialWorkspace(),
      workspaceMember: member,
      schedule: initialScheduleState,
      scheduleSum: initialScheduleSumState,
    }
  },

  async loadUserId(store: AppStore): Promise<AppStore> {
    return {
      ...store,
      userId: UserIdStore.get() ?? "user",
    }
  },
}
