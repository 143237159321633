import { styled } from "linaria/react"
import React, { useState, useEffect, useMemo } from "react"
import { Link } from "react-router-dom"

import { clients } from "api/clients"
import { ArchivedLabel, InProgressLabel } from "component/common"
import JobActionMenu from "component/page/project/jobActionMenu"
import PutJobModal from "component/page/project/putJobModal"
import * as m from "model"

const FlexContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
`

export type JobsTabProps = {
  project: m.Job
}

type JobsTableBodyProps = {
  project: m.Job
  jobs: m.Job[]
  setJobs: (f: (prev: m.Job[]) => m.Job[]) => void
}

const JobsTableBody: React.FC<JobsTableBodyProps> = React.memo((props) => {
  const sortedJobs = useMemo(() => props.jobs.sort((a, b) => a.jobName.localeCompare(b.jobName, "ja-JP")), [props.jobs])
  const jobElements = sortedJobs.map((job) => {
    return (
      <tr key={job.jobId}>
        <td className="single line">
          {job.externalLinkageInfo.url ? (
            <Link to={{ pathname: job.externalLinkageInfo.url }} target="_blank">
              {job.jobName}
            </Link>
          ) : (
            job.jobName
          )}
        </td>
        <td className="single line">{job.jobId}</td>
        <td className="single line">
          {m.isInProgress(job) ? <InProgressLabel></InProgressLabel> : <ArchivedLabel></ArchivedLabel>}
        </td>
        <td>
          <JobActionMenu project={props.project} job={job} setJobs={props.setJobs}></JobActionMenu>
        </td>
      </tr>
    )
  })
  return <tbody>{jobElements}</tbody>
})

export const JobsTab: React.FC<JobsTabProps> = React.memo((props) => {
  const [jobs, setJobs] = useState<m.Job[]>([])
  const [isPutJobModalOpen, setIsPutJobModalOpen] = useState<boolean>(false)

  useEffect(() => {
    clients.job.getChildren(props.project.workspaceId, props.project.jobId).then((jobs) => setJobs(jobs))
  }, [props.project])

  return (
    <div className="ui tab basic active segment">
      <FlexContainer>
        <button className="ui olive ok button rounded-pill" onClick={() => setIsPutJobModalOpen(true)}>
          ジョブを追加
        </button>
      </FlexContainer>
      <table className="ui striped table">
        <thead>
          <tr>
            <th className="single line five wide">ジョブ名</th>
            <th className="signle line five wide">ジョブID</th>
            <th className="single line five wide">状態</th>
            <th className="single line one wide"></th>
          </tr>
        </thead>
        <JobsTableBody project={props.project} jobs={jobs} setJobs={setJobs} />
      </table>
      <PutJobModal
        isOpen={isPutJobModalOpen}
        project={props.project}
        setIsOpen={setIsPutJobModalOpen}
        setJobs={setJobs}
      ></PutJobModal>
    </div>
  )
})

export default JobsTab
