import moment from "moment-timezone"

// `Date#toLocalDateString`で出力される形式の日付文字列 (例: 2022/1/1)
export type LocalDateString = string

// TODO 将来的に国際化対応する必要がある
export const defaultTimezone = "Asia/Tokyo"

export const dateStringToDate = (dateString: string, timezone: string = defaultTimezone): Date =>
  moment.tz(dateString, timezone).toDate()

export const dateToDateString = (date: Date, timezone: string = defaultTimezone): string =>
  moment(date).tz(timezone).format().slice(0, 10)

export const hourms = 60 * 60 * 1000
export const dayms = 24 * 60 * 60 * 1000

export const dayString = (date: Date): string => date.toISOString().slice(0, 10)
export const toZeroPaddedLocalDateString = (date: Date): string =>
  date
    .toLocaleDateString()
    .split("/")
    .map((x) => x.padStart(2, "0"))
    .join("/")
export const toZeroPaddedLocalDateStringWithDay = (date: Date): string =>
  `${toZeroPaddedLocalDateString(date)} (${toLocalDayString(date)})`
export const toISO8601LocalDateString = (date: Date): string => toZeroPaddedLocalDateString(date).replaceAll("/", "-")
export const toLocalDayString = (date: Date): string => {
  switch (date.getDay()) {
    case 0:
      return "日"
    case 1:
      return "月"
    case 2:
      return "火"
    case 3:
      return "水"
    case 4:
      return "木"
    case 5:
      return "金"
    case 6:
      return "土"
    default:
      return ""
  }
}

export const toDateOnlyUnixtimeMs = (unixtimeMs: number): number => Math.floor(unixtimeMs / dayms) * dayms

export const toHours = (ms: number): number => ms / hourms
export const toDays = (ms: number): number => ms / dayms

export const plusDays = (date: Date, days: number): Date => new Date(date.getTime() + dayms * days)
