import { styled } from "linaria/react"
import React, { useCallback } from "react"
import ReactModal from "react-modal"

import { clients } from "api/clients"
import * as m from "model"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "340px",
    height: "200px",
    padding: 0,
  },
}

const ModalHeader = styled.h2`
  padding: 1rem 1rem 0 1rem;
`
const ModalContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem 1rem;
  height: 100%;
  width: 100%;
`
const ModalFooter = styled.div`
  display: flex;
  justify-content: right;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
  .ui.button {
    margin-left: 0.75em;
  }
`
const Divider = styled.div`
  &.ui.divider {
    margin: 0;
  }
`

type ModalType = "invitation" | "delete"

type ConfirmationModal = {
  modalType: ModalType
  workspaceMember: m.WorkspaceMember
  isOpen: boolean
  setIsOpen: (value: boolean) => void
  setWorkspaceMember: (value: m.WorkspaceMember) => void
}

const ConfirmationModal: React.FC<ConfirmationModal> = React.memo(
  ({ modalType, workspaceMember, isOpen, setIsOpen, setWorkspaceMember }) => {
    const inviteMember = useCallback(async () => {
      const workspaceTags = await clients.workspaceTag.getWorkspaceMemberTags(
        workspaceMember.workspaceId,
        workspaceMember.workspaceMemberId
      )
      const body = {
        userId: workspaceMember.userId,
        role: workspaceMember.role,
        workspaceTags: workspaceTags.map((x) => x.workspaceTagId),
        lastUpdatedDatetime: workspaceMember.updatedDatetime.toISOString(),
      }
      clients.workspaceMember
        .putWorkspaceMember(workspaceMember.workspaceId, workspaceMember.workspaceMemberId, body)
        .then((member) => {
          setWorkspaceMember(member)
          setIsOpen(false)
        })
        .catch((error: Error) => window.alert(error.message))
    }, [workspaceMember, setIsOpen, setWorkspaceMember])

    const deleteMember = useCallback(() => {
      clients.workspaceMember
        .deleteWorkspaceMember(workspaceMember.workspaceId, workspaceMember.workspaceMemberId)
        .then((member) => {
          setWorkspaceMember(member)
          setIsOpen(false)
        })
        .catch((error: Error) => window.alert(error.message))
    }, [workspaceMember, setIsOpen, setWorkspaceMember])

    const onOKClick = useCallback(
      () => (modalType === "invitation" ? inviteMember() : deleteMember()),
      [modalType, inviteMember, deleteMember]
    )

    return (
      <ReactModal isOpen={isOpen} style={modalStyle} onRequestClose={() => setIsOpen(false)}>
        <ModalHeader>{modalType === "invitation" ? "ワークスペースへ招待" : "ワークスペースから脱退"}</ModalHeader>
        <Divider className="ui divider"></Divider>
        <ModalContent>
          <span>
            本当に<b>「{workspaceMember.username}」</b>を
            {modalType === "invitation" ? "ワークスペースへ招待しますか？" : "ワークスペースから脱退させますか？"}
          </span>
        </ModalContent>
        <ModalFooter>
          <button className="ui olive ok button rounded-pill" onClick={onOKClick}>
            {modalType === "invitation" ? "招待" : "脱退"}
          </button>
          <button className="ui orange cancel delete button rounded-pill" onClick={() => setIsOpen(false)}>
            キャンセル
          </button>
        </ModalFooter>
      </ReactModal>
    )
  }
)

export default ConfirmationModal
