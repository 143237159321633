import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useHistory } from "react-router-dom"

import { clients } from "api/clients"
import { AssignModal } from "component/page/schedule/assignModal"
import {
  Calendar,
  groupToJobMember,
  itemToSchedule,
  TimelineGroup,
  TimelineItem,
} from "component/page/schedule/calendar"
import { UnassignedHour, isUnassignedHourNearlyEqual0 } from "component/page/schedule/common"
import { timezoneOffset } from "component/page/schedule/timezone"
import * as m from "model"
import { AppStore, ModalState } from "model/appStore"
import { getMemberDateKey, ScheduleState, ScheduleSumState } from "model/scheduleState"
import { Dispatch, Reducer } from "reducer/common"
import { dayms, dayString, toDateOnlyUnixtimeMs } from "utils/date"

export type SchedulePageProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  modal: ModalState
  token: string | null
  userId: string | null
  workspaceId: string | null
  workspaceMember: m.WorkspaceMember | null
  schedule: ScheduleState
  scheduleSum: ScheduleSumState
}

const now = Date.now()
const utcToday = toDateOnlyUnixtimeMs(now)
// TODO 日本時間基準になっている
const timezoneToday = toDateOnlyUnixtimeMs(now - timezoneOffset)
const defaultStartUnixtimeMs = timezoneToday - 7 * dayms
const defaultEndUnixtimeMs = timezoneToday + 30 * dayms

function calcSumByJob(jobs: m.Job[], sumOfActuals: m.SumOfTimes, sumOfSchedules: m.SumOfTimes): m.RootJobSum {
  return jobs.reduce((acc, job) => {
    const actuals = sumOfActuals[job.jobId] ?? {}
    const actualTotal = Object.entries(actuals).reduce((acc, [_, v]) => acc + v, 0)
    const schedules = sumOfSchedules[job.jobId] ?? {}
    const scheduleTotal = Object.entries(schedules).reduce((acc, [_, v]) => acc + v, 0)
    // TODO ジョブの階層構造が親(プロジェクト)と子(ジョブ)の2段階であることが前提のコードになっている
    const targetJobId = m.getProjectId(job)
    const { total, children } = acc.get(targetJobId) ?? { total: 0, children: new Map<string, number>() }
    const newTotal = total + actualTotal + scheduleTotal
    const childrenActual = Object.entries(actuals).reduce((acc, [k, v]) => acc.set(k, (acc.get(k) ?? 0) + v), children)
    const newChildren = Object.entries(schedules).reduce(
      (acc, [k, v]) => acc.set(k, (acc.get(k) ?? 0) + v),
      childrenActual
    )
    return acc.set(targetJobId, { total: newTotal, children: newChildren })
  }, new Map<string, m.RootJobSumParent>())
}

function calcSumByMember(
  jobs: m.Job[],
  members: m.WorkspaceMember[],
  sumOfActuals: m.SumOfTimes,
  sumOfSchedules: m.SumOfTimes
): m.RootJobSum {
  const initial: m.RootJobSum = new Map(
    members.map((member) => [member.workspaceMemberId, { total: 0, children: new Map<string, number>() }])
  )
  // TODO ジョブの階層構造が親(プロジェクト)と子(ジョブ)の2段階であることが前提のコードになっている
  const jobToRootJob = new Map<string, string>(jobs.map((job) => [job.jobId, m.getProjectId(job)]))
  const getDisplaySum = (
    sum: m.SumOfTimes,
    result: m.RootJobSum = new Map<string, m.RootJobSumParent>()
  ): m.RootJobSum =>
    Object.entries(sum).reduce(
      (acc, [jobId, detail]) =>
        Object.entries(detail).reduce((acc, [memberId, value]) => {
          const targetJobId = jobToRootJob.get(jobId) ?? jobId
          const { total, children } = acc.get(memberId) ?? { total: 0, children: new Map<string, number>() }
          const newTotal = total + value
          const newChildren = children.set(targetJobId, (children.get(targetJobId) ?? 0) + value)
          return acc.set(memberId, { total: newTotal, children: newChildren })
        }, acc),
      result
    )
  return getDisplaySum(sumOfActuals, getDisplaySum(sumOfSchedules, initial))
}

function compareJob(x: m.Job, y: m.Job): number {
  const archived = (x.status === "archived" ? 1 : 0) - (y.status === "archived" ? 1 : 0)
  if (archived) return archived
  return x.jobName.localeCompare(y.jobName)
}
function compareMember(x: m.WorkspaceMember, y: m.WorkspaceMember): number {
  const inactive = (x.status === "inactive" ? 1 : 0) - (y.status === "inactive" ? 1 : 0)
  if (inactive) return inactive
  return x.username.localeCompare(y.username)
}

export const SchedulePage: React.FC<SchedulePageProps> = (props) => {
  const [requiresLoadJobMember, setRequiresLoadJobMember] = useState(true)
  const [jobMemberLoading, setJobMemberLoading] = useState(false)
  const [sumLoading, setSumLoading] = useState(false)
  const [mode, setMode] = useState<"job" | "member">("job")
  const [jobFilter, setJobFilter] = useState<"archived" | "unarchived" | "all">("unarchived")
  const [loadedPastOffsetUnixtimeMs, setLoadedPastOffsetUnixtimeMs] = useState<number | undefined>(undefined)
  const [loadedFutureDateUnixtimeMs, setLoadedFutureDateUnixtimeMs] = useState<number | undefined>(undefined)
  const [currentWorkspaceId, setCurrentWorkspaceId] = useState<string>()
  const [unassignedHours, setUnassignedHours] = useState<UnassignedHour[]>([])
  const history = useHistory()

  const members = useMemo(
    () => Array.from(props.schedule.members.values()).sort(compareMember),
    [props.schedule.members]
  )
  const rootJobActuals = useMemo(
    () =>
      Array.from(props.schedule.actuals.values()).map((x) => {
        // TODO ジョブの階層構造が親(プロジェクト)と子(ジョブ)の2段階であることが前提のコードになっている
        const job = props.schedule.jobs.get(x.jobId)
        return job ? { ...x, jobId: m.getProjectId(job) } : x
      }),
    [props.schedule.actuals, props.schedule.jobs]
  )
  const jobs = useMemo(() => Array.from(props.schedule.jobs.values()).sort(compareJob), [props.schedule.jobs])
  const rootJobs = useMemo(() => jobs.filter((x) => m.getProjectId(x) === x.jobId), [jobs])
  const activeJobs = useMemo(() => rootJobs.filter((x) => x.status === "unarchived"), [rootJobs])

  // スケジュール表に表示する対象となるジョブ、実績、スケジュール
  const visibleJobs = useMemo(() => {
    if (jobFilter === "all") {
      return rootJobs
    }
    return rootJobs.filter((x) => x.status === jobFilter)
  }, [jobFilter, rootJobs])
  const visibleJobMap = useMemo(() => new Map(visibleJobs.map((x) => [x.jobId, x])), [visibleJobs])
  const visibleActuals = useMemo(
    () => rootJobActuals.filter((x) => visibleJobMap.has(x.jobId)),
    [rootJobActuals, visibleJobMap]
  )
  const visibleSchedules = useMemo(
    () => Array.from(props.schedule.schedules.values()).filter((x) => visibleJobMap.has(x.jobId)),
    [props.schedule.schedules, visibleJobMap]
  )

  const rootJobSumByJob = useMemo(
    () => calcSumByJob(jobs, props.scheduleSum.sumOfActuals, props.scheduleSum.sumOfSchedules),
    [jobs, props.scheduleSum.sumOfActuals, props.scheduleSum.sumOfSchedules]
  )
  const rootJobSumByMember = useMemo(
    () => calcSumByMember(jobs, members, props.scheduleSum.sumOfActuals, props.scheduleSum.sumOfSchedules),
    [jobs, members, props.scheduleSum.sumOfActuals, props.scheduleSum.sumOfSchedules]
  )
  const rootJobSum = useMemo(
    () => (mode === "job" ? rootJobSumByJob : rootJobSumByMember),
    [mode, rootJobSumByJob, rootJobSumByMember]
  )

  const onCalendarClick = useCallback(
    (time: number, group: TimelineGroup, item: TimelineItem | null) => {
      if (time < timezoneToday) {
        return
      }
      const { job, member } = groupToJobMember(group)
      if (job != null && job.status === "archived") {
        return
      }
      const date = m.DateOnly.fromUnixtimeMs(time)
      const schedule = item == null ? null : itemToSchedule(item)
      const modal: ModalState = {
        kind: "assign",
        schedule: schedule,
        selectedJobs: job == null ? [] : [job],
        selectedMembers: member == null ? [] : [member],
        startDate: schedule?.startDate ?? date,
        endDate: schedule?.endDate ?? date,
        assignType: schedule?.type ?? "percentage",
        hours: schedule?.type === "hours" ? schedule.value : null,
        percentage: schedule == null ? 100 : schedule.type === "percentage" ? schedule.value : null,
        errorMessage: undefined,
      }
      props.dispatch.apply(props.reducer.setModal(modal))
    },
    [props.dispatch, props.reducer]
  )

  const onBoundsChange = useCallback(
    (startUnixtimeMs: number, endUnixtimeMs: number): void => {
      if (!props.workspaceId) return
      // ユーザーの時間(JST)とスケジュール画面のコンポーネントの基準時間(UTC)の差をtimezoneOffsetを使って補正している
      // TODO パブリックリリース時はユーザーの時間は日本時間基準決め打ちだが将来的には国際化対応を検討
      const todayOffsetUnixtimeMs = utcToday + timezoneOffset
      const startOffsetUnixtimeMs = toDateOnlyUnixtimeMs(startUnixtimeMs) + timezoneOffset
      const endDateUnixtimeMs = toDateOnlyUnixtimeMs(endUnixtimeMs)

      // スケジュール画面のactuals, expecteds, schedulesは最初にすべてを取得するのではなく、横スクロール時に不足分を取得する
      // 一度取得したリソースは再取得しないように、取得した範囲をloadedPastOffsetUnixtimeMs、loadedFutureDateUnixtimeMsでステート管理する
      // loadedPastOffsetUnixtimeMs: ステート管理している最小日
      // loadedFutureDateUnixtimeMs: ステート管理している最大日

      const pastStartOffsetUnixtimeMs = loadedPastOffsetUnixtimeMs
        ? startOffsetUnixtimeMs < loadedPastOffsetUnixtimeMs
          ? startOffsetUnixtimeMs
          : undefined
        : startOffsetUnixtimeMs
      const pastEndOffsetUnixtimeMs = loadedPastOffsetUnixtimeMs ? loadedPastOffsetUnixtimeMs : todayOffsetUnixtimeMs
      const futureEndDateUnixtimeMs = loadedFutureDateUnixtimeMs
        ? loadedFutureDateUnixtimeMs < endDateUnixtimeMs
          ? endDateUnixtimeMs
          : undefined
        : endDateUnixtimeMs
      const futureStartDateUnixtimeMs = loadedFutureDateUnixtimeMs ? loadedFutureDateUnixtimeMs + dayms : utcToday
      const actualWorkingTimes = pastStartOffsetUnixtimeMs
        ? clients.actualWorkingTime.list(
            props.workspaceId,
            new Date(pastStartOffsetUnixtimeMs),
            new Date(pastEndOffsetUnixtimeMs)
          )
        : Promise.resolve([])
      const expectedWorkingTimes = futureEndDateUnixtimeMs
        ? clients.expectedWorkingTime.list(
            props.workspaceId,
            dayString(new Date(futureStartDateUnixtimeMs)),
            dayString(new Date(futureEndDateUnixtimeMs))
          )
        : Promise.resolve([])
      const schedules = futureEndDateUnixtimeMs
        ? clients.schedule.list(
            props.workspaceId,
            dayString(new Date(futureStartDateUnixtimeMs)),
            dayString(new Date(futureEndDateUnixtimeMs))
          )
        : Promise.resolve([])

      const newSchedule = Promise.all([actualWorkingTimes, expectedWorkingTimes, schedules]).then(
        ([actuals, expecteds, schedules]) => {
          const newActuals = actuals.reduce(
            (acc, actual) => acc.set(actual.actualWorkingTimeId, actual),
            props.schedule.actuals
          )
          const newExpecteds = expecteds.reduce(
            (acc, expected) => acc.set(getMemberDateKey(expected.workspaceMemberId, expected.date), expected),
            props.schedule.expecteds
          )
          const newSchedules = schedules.reduce(
            (acc, schedule) => acc.set(schedule.scheduleId, schedule),
            props.schedule.schedules
          )
          return { actuals: newActuals, expecteds: newExpecteds, schedules: newSchedules }
        }
      )
      newSchedule.then((newObject) => {
        props.dispatch.apply(
          props.reducer.setScheduleState((prev: ScheduleState) => {
            return {
              ...prev,
              actuals: new Map(newObject.actuals),
              expecteds: new Map(newObject.expecteds),
              schedules: new Map(newObject.schedules),
            }
          })
        )
        if (pastStartOffsetUnixtimeMs) setLoadedPastOffsetUnixtimeMs(pastStartOffsetUnixtimeMs)
        if (futureEndDateUnixtimeMs) setLoadedFutureDateUnixtimeMs(futureEndDateUnixtimeMs)
      })
    },
    [
      props.workspaceId,
      props.dispatch,
      props.reducer,
      props.schedule,
      loadedPastOffsetUnixtimeMs,
      loadedFutureDateUnixtimeMs,
    ]
  )

  const loadSum = useCallback(async (workspaceId: string, oldSum: ScheduleSumState): Promise<ScheduleSumState> => {
    const sumOfActualsPromise = clients.actualWorkingTime.listSum(workspaceId)
    const sumOfSchedulesPromise = clients.schedule.listSum(workspaceId)
    const [sumOfActuals, sumOfSchedules] = await Promise.all([sumOfActualsPromise, sumOfSchedulesPromise])
    return {
      ...oldSum,
      sumOfActuals: sumOfActuals,
      sumOfSchedules: sumOfSchedules,
      requiresLoadSum: false,
    }
  }, [])

  const loadJobMember = useCallback(
    async (workspaceId: string): Promise<[Map<string, m.WorkspaceMember>, Map<string, m.Job>]> => {
      const jobsPromise = clients.job.list(workspaceId, [])
      const membersPromise = clients.workspaceMember.getWorkspaceMembers(workspaceId, false)
      const [jobs, members] = await Promise.all([jobsPromise, membersPromise])
      const newJobs = jobs.reduce((acc, job) => acc.set(job.jobId, job), new Map())
      const newMembers = members.reduce((acc, member) => acc.set(member.workspaceMemberId, member), new Map())
      return [newMembers, newJobs]
    },
    []
  )

  useEffect(() => {
    const handle = setTimeout(() => {
      // Tokenがない場合はlogin画面に遷移
      if (!props.token || !props.workspaceId) {
        history.push("/login")
        return
      }

      const workspaceId = props.workspaceId

      if (workspaceId !== currentWorkspaceId) {
        setRequiresLoadJobMember(true)
        // ワークスペース切替時にactuals,expecteds,schedulesを初期化
        props.dispatch.apply(
          props.reducer.setScheduleState((prev: ScheduleState) => {
            return {
              ...prev,
              actuals: new Map(),
              expecteds: new Map(),
              schedules: new Map(),
            }
          })
        )
        props.dispatch.apply(
          props.reducer.setScheduleSumState({
            ...props.scheduleSum,
            requiresLoadSum: true,
          })
        )
        setCurrentWorkspaceId(workspaceId)
        // ワークスペース切替時にはスケジュール、予定稼働、実績稼働のキャッシュを初期化する
        setLoadedPastOffsetUnixtimeMs(undefined)
        setLoadedFutureDateUnixtimeMs(undefined)
      }

      // ジョブ、メンバーについてはページロード時に取得する
      if (requiresLoadJobMember && !jobMemberLoading) {
        setJobMemberLoading(true)
        loadJobMember(workspaceId).then(([members, jobs]) => {
          props.dispatch.apply(
            props.reducer.setScheduleState((prev: ScheduleState) => {
              return {
                ...prev,
                members: members,
                jobs: jobs,
              }
            })
          )
          setJobMemberLoading(false)
          setRequiresLoadJobMember(false)
        })
      }
      // 合計についてはスケジュールに変化があった際に取得する
      if (props.scheduleSum.requiresLoadSum && !sumLoading) {
        setSumLoading(true)
        loadSum(workspaceId, props.scheduleSum).then((scheduleSumState) => {
          props.dispatch.apply(props.reducer.setScheduleSumState(scheduleSumState))
          setSumLoading(false)
        })
      }
    })
    return () => clearTimeout(handle)
  }, [
    props.dispatch,
    props.reducer,
    props.token,
    props.workspaceId,
    props.workspaceMember,
    props.schedule,
    props.scheduleSum,
    requiresLoadJobMember,
    jobMemberLoading,
    loadJobMember,
    loadSum,
    sumLoading,
    history,
    currentWorkspaceId,
  ])

  // 未割り当てを遅延更新
  useEffect(() => {
    if (loadedFutureDateUnixtimeMs) {
      const scheduleMap = Array.from(props.schedule.schedules.values()).reduce((scheduleMap, schedule) => {
        const dateCount = schedule.endDate.diff(schedule.startDate) + 1
        return Array.from({ length: dateCount }).reduce((scheduleMap: Map<string, m.Schedule[]>, _, i) => {
          const date = schedule.startDate.add(i)
          const key = getMemberDateKey(schedule.workspaceMemberId, date)
          return scheduleMap.set(key, [...(scheduleMap.get(key) ?? []), schedule])
        }, scheduleMap)
      }, new Map<string, m.Schedule[]>())
      const timezoneTodayDateOnly = m.DateOnly.fromUnixtimeMs(timezoneToday)
      const futureDateCount = m.DateOnly.fromUnixtimeMs(loadedFutureDateUnixtimeMs).diff(timezoneTodayDateOnly)
      const newUnassignedHours = Array.from({ length: futureDateCount }).flatMap((_, i) => {
        const date = timezoneTodayDateOnly.add(i)
        return members.flatMap((member) => {
          if (member.status !== "active") return []
          const key = getMemberDateKey(member.workspaceMemberId, date)
          const expected = props.schedule.expecteds.get(key)
          const schedules = scheduleMap.get(key) ?? []
          if (expected == null && schedules.length === 0) return []
          const expectedWorkingHours = expected?.expectedWorkingHours ?? 0
          const hour = schedules.reduce(
            (hour, schedule) =>
              schedule.type === "hours" ? hour - schedule.value : hour - (expectedWorkingHours * schedule.value) / 100,
            expectedWorkingHours
          )
          const fixedHour = isUnassignedHourNearlyEqual0(hour) ? 0 : hour
          return [{ member: member, date: date, hour: fixedHour }]
        })
      })
      setUnassignedHours(newUnassignedHours)
    }
  }, [loadedFutureDateUnixtimeMs, members, props.schedule.expecteds, props.schedule.schedules])

  if (jobMemberLoading) return <></>

  return (
    <>
      <div style={{ position: "absolute" }}>
        <div>
          <div className="ui small buttons">
            <button className={"ui olive button" + (mode === "job" ? "" : " basic")} onClick={() => setMode("job")}>
              プロジェクト
            </button>
            <button
              className={"ui olive button" + (mode === "member" ? "" : " basic")}
              onClick={() => setMode("member")}
            >
              メンバー
            </button>
          </div>
        </div>
        <div>
          <div className="ui small buttons">
            <button
              className={"ui olive button" + (jobFilter === "unarchived" ? "" : " basic")}
              onClick={() => setJobFilter("unarchived")}
            >
              進行中
            </button>
            <button
              className={"ui olive button" + (jobFilter === "archived" ? "" : " basic")}
              onClick={() => setJobFilter("archived")}
            >
              アーカイブ
            </button>
            <button
              className={"ui olive button" + (jobFilter === "all" ? "" : " basic")}
              onClick={() => setJobFilter("all")}
            >
              すべて
            </button>
          </div>
        </div>
      </div>
      <Calendar
        mode={mode}
        members={members}
        jobs={visibleJobs}
        expecteds={Array.from(props.schedule.expecteds.values())}
        actuals={visibleActuals}
        schedules={visibleSchedules}
        unassignedHours={unassignedHours}
        rootJobSum={rootJobSum}
        defaultStartUnixtimeMs={defaultStartUnixtimeMs}
        defaultEndUnixtimeMs={defaultEndUnixtimeMs}
        onClick={onCalendarClick}
        onBoundsChange={onBoundsChange}
      />
      <AssignModal
        dispatch={props.dispatch}
        reducer={props.reducer}
        token={props.token}
        workspaceId={props.workspaceId}
        members={members}
        jobs={activeJobs}
        state={props.modal != null && props.modal.kind === "assign" ? props.modal : null}
        schedule={props.schedule}
      />
    </>
  )
}

export default SchedulePage
