import { clients } from "api/clients"
import { TokenStore } from "dataStore/tokenStore"
import { UserIdStore } from "dataStore/userIdStore"
import { WorkspaceStore } from "dataStore/workspaceStore"
import { WorkspaceMember } from "model"
import { AppStore } from "model/appStore"
import { Reducer, reducer as commonReducer } from "reducer/common"

const loadUserId = async (store: AppStore): Promise<AppStore> => {
  const account = await clients.myInfo.getMyAccount().catch(() => undefined)
  if (account) {
    const lastWorkspace = initialWorkspace()
    UserIdStore.set(account.userId)
    const members = await clients.myInfo.getMyWorkspaceMembers(lastWorkspace).catch((e) => {
      console.log(`ワークスペースの取得に失敗 : ${e.message ?? ""}`)
      return [] as WorkspaceMember[]
    })
    const member = members[0]
    return {
      ...store,
      userId: account?.userId ?? null,
      workspaceId: member ? member.workspaceId : null,
      workspaceMember: member ?? null,
    }
  } else {
    UserIdStore.clear()
    return {
      ...store,
      userId: null,
      workspaceId: null,
      workspaceMember: null,
    }
  }
}

const initialWorkspace = (): string | undefined => {
  const pathnames = document.location.pathname.split("/")
  const idIndex = pathnames.indexOf("workspaces") + 1
  return idIndex > 0 ? pathnames[idIndex] : WorkspaceStore.getWorkspaceId()
}

export const reducerImpl: Reducer = {
  ...commonReducer,
  async loadInitialData(store: AppStore): Promise<AppStore> {
    const accountAppStore = await loadUserId(store)
    return {
      ...accountAppStore,
      token: TokenStore.getToken() ?? null,
    }
  },

  async loadUserId(store: AppStore): Promise<AppStore> {
    return loadUserId(store)
  },
}
