import { styled } from "linaria/react"
import React from "react"

const UIContainer = styled.div`
  margin-top: 50px;
  text-align: center;
  h1 {
    font-size: 40px;
  }
  p {
    font-size: 18px;
  }
`

type NotFoundPageProps = {
  // no props
}

export const NotFoundPage: React.FC<NotFoundPageProps> = React.memo((props) => {
  return (
    <UIContainer className="ui container">
      <h1>404 Not Found</h1>
      <p>ページが存在しないか、閲覧権限がありません。</p>
    </UIContainer>
  )
})

export default NotFoundPage
