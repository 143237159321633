import moment from "moment-timezone"

import * as m from "model"

export const formatDate = (
  value: string,
  setDate: (date: m.DateOnly | null) => void,
  setDateValue?: (value: string) => void
): void => {
  const setDateAndValue = (
    momentDate: moment.Moment,
    setDate: (date: m.DateOnly | null) => void,
    setDateValue?: (value: string) => void
  ): void => {
    const dateOnly = m.DateOnly.fromDateAsLocal(momentDate.toDate())
    setDate(dateOnly)
    if (setDateValue) setDateValue(dateOnly.toISOString().replaceAll("-", "/"))
  }

  const slashParseResult = moment(value, "YYYY/M/D", true)
  if (slashParseResult.isValid()) {
    setDateAndValue(slashParseResult, setDate, setDateValue)
    return
  }
  const hyphenParseResult = moment(value, "YYYY-M-D", true)
  if (hyphenParseResult.isValid()) {
    setDateAndValue(hyphenParseResult, setDate, setDateValue)
    return
  }
  const noDelimiterParseResult = moment(value, "YYYYMMDD", true)
  if (noDelimiterParseResult.isValid()) {
    setDateAndValue(noDelimiterParseResult, setDate, setDateValue)
    return
  }
  setDate(null)
}
