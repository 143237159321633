import { styled } from "linaria/react"
import React, { useCallback, useState } from "react"
import ReactModal from "react-modal"
import { useHistory } from "react-router-dom"
import { v4 as uuidv4 } from "uuid"

import { clients } from "api/clients"
import * as m from "model"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "380px",
    height: "590px",
    padding: 0,
  },
}

const ProjectCreationForm = styled.form`
  height: 100%;
  width: 100%;
`
const ProjectCreationFormHeader = styled.h1`
  padding: 1rem 1rem 0 1rem;
`
const ProjectCreationFormContent = styled.div`
  padding: 0 1rem;
`
const ProjectCreationFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  .ui.button {
    margin-left: 0.75em;
  }
`
const ProjectCreationFormButtons = styled.div`
  display: flex;
  justify-content: right;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
`

type ProjectCreationModalProps = {
  workspaceId: string
  isOpen: boolean
  setIsOpen: (value: boolean) => void
}

const ProjectCreationModal: React.FC<ProjectCreationModalProps> = React.memo((props) => {
  const history = useHistory()
  const [projectName, setProjectName] = useState<string>("")
  const [projectTargetHour, setProjectTargetHour] = useState<string>("")
  const [projectNote, setProjectNote] = useState<string>("")

  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [errorMessage, setErrorMessage] = useState<string>("")

  const createProject = useCallback(
    (event: React.FormEvent) => {
      event.preventDefault()
      setIsLoading(true)

      const body: m.PutJobRequest = {
        jobName: projectName,
        status: "unarchived",
        targetHours: projectTargetHour ? Number(projectTargetHour) : undefined,
        note: projectNote || undefined,
        externalLinkageInfo: {},
      }
      clients.job
        .putJob(props.workspaceId, uuidv4(), body)
        .then((newProject) => history.push(`/workspaces/${props.workspaceId}/project?projectId=${newProject.jobId}`))
        .catch((error: Error) => setErrorMessage(error.message))
        .finally(() => setIsLoading(false))
    },
    [props.workspaceId, history, projectName, projectTargetHour, projectNote, setIsLoading, setErrorMessage]
  )

  return (
    <ReactModal isOpen={props.isOpen} style={modalStyle} onRequestClose={() => props.setIsOpen(false)}>
      <ProjectCreationForm
        className={"ui form" + (isLoading ? " loading" : "") + (errorMessage ? " error" : "")}
        onSubmit={createProject}
      >
        <ProjectCreationFormHeader>プロジェクト</ProjectCreationFormHeader>
        <div className="ui divider"></div>
        <ProjectCreationFormContent>
          <div className="required field">
            <label>プロジェクト名</label>
            <input
              className="ui input"
              type="text"
              placeholder="例）PROJECT NAME"
              value={projectName}
              onChange={(e) => setProjectName(e.target.value)}
              required
            />
          </div>
          <div className="field">
            <label>目標時間 (h)</label>
            <input
              className="ui input"
              type="number"
              placeholder="例) 160"
              min={0.01}
              step={0.01}
              value={projectTargetHour}
              onChange={(e) => setProjectTargetHour(e.target.value)}
            />
          </div>
          <div className="field">
            <label>備考</label>
            <textarea
              placeholder="メモ"
              value={projectNote}
              onChange={(e) => setProjectNote(e.target.value)}
            ></textarea>
          </div>
        </ProjectCreationFormContent>
        <ProjectCreationFormFooter>
          <div className="ui error message">{errorMessage ?? undefined}</div>
          <ProjectCreationFormButtons>
            <button className="ui olive ok button rounded-pill" type="submit">
              作成
            </button>
            <button className="ui orange cancel delete button rounded-pill" onClick={() => props.setIsOpen(false)}>
              キャンセル
            </button>
          </ProjectCreationFormButtons>
        </ProjectCreationFormFooter>
      </ProjectCreationForm>
    </ReactModal>
  )
})

export default ProjectCreationModal
