import { css } from "linaria"
import { styled } from "linaria/react"
import React, { useMemo } from "react"

import * as m from "model"
import * as du from "utils/date"

const RedColor = css`
  color: palevioletred;
`
const SummaryTD = styled.td`
  font-weight: bold;
`

export interface DailyReport {
  date: Date
  expectedWorkingHours: number
  assignedWorkingHoursReports: AssignedWorkingHoursReport[]
  actualWorkingHoursReports: ActualWorkingHoursReport[]
}
export interface AssignedWorkingHoursReport {
  projectId: m.ProjectId
  projectName: string
  assignedWorkingHours: number
}
export interface ActualWorkingHoursReport {
  projectId: m.ProjectId
  projectName: string
  actualWorkingHours: number
}

type ScheduleTableProps = {
  dailyReports: DailyReport[]
}

const getReportTDs = (
  projectName: string,
  hours: number,
  rowSpan: number | undefined = undefined
): React.ReactElement => {
  return (
    <>
      <td rowSpan={rowSpan}>{projectName}</td>
      <td className="right aligned single line" rowSpan={rowSpan}>
        {hoursToString(hours)}
      </td>
    </>
  )
}
const getEmptyReportTDs = (rowSpan: number | undefined = undefined): React.ReactElement => {
  return getReportTDs("", 0, rowSpan)
}
const getSummaryAssignedWorkingHoursTDs = (reports: AssignedWorkingHoursReport[]): React.ReactElement => {
  const total = reports.reduce<number>((acc, report) => {
    return acc + report.assignedWorkingHours
  }, 0)
  return (
    <>
      <SummaryTD className="single line">合計</SummaryTD>
      <SummaryTD className="right aligned single line">{hoursToString(total)}</SummaryTD>
    </>
  )
}
const getSummaryActualWorkingHoursTDs = (reports: ActualWorkingHoursReport[]): React.ReactElement => {
  const total = reports.reduce((acc, report) => {
    return acc + report.actualWorkingHours
  }, 0)
  return (
    <>
      <SummaryTD className="single line">合計</SummaryTD>
      <SummaryTD className="right aligned single line">{hoursToString(total)}</SummaryTD>
    </>
  )
}

const getDailyReportTRs = (dailyReport: DailyReport): React.ReactElement[] => {
  const assignedWorkingHoursReports = [...dailyReport.assignedWorkingHoursReports].sort((a, b) =>
    a.projectName.localeCompare(b.projectName, "ja-JP")
  )
  const actualWorkingHoursReports = [...dailyReport.actualWorkingHoursReports].sort((a, b) =>
    a.projectName.localeCompare(b.projectName, "ja-JP")
  )
  const lengthDiff = assignedWorkingHoursReports.length - actualWorkingHoursReports.length
  const maxReportLength = Math.max(assignedWorkingHoursReports.length, actualWorkingHoursReports.length)

  return Array.from({ length: maxReportLength }).map((_, index) => {
    const isTailIndex =
      index === assignedWorkingHoursReports.length - 1 || index === actualWorkingHoursReports.length - 1
    const hasSameLength = lengthDiff === 0
    const [assignedWorkingHoursRowSpan, actualWorkingHoursRowSpan] = (() => {
      if (!isTailIndex || hasSameLength) {
        return [1, 1]
      }
      if (lengthDiff > 0) {
        return [1, lengthDiff + 1]
      }
      return [-lengthDiff + 1, 1]
    })()

    const assignedWorkingHoursReport = assignedWorkingHoursReports[index]
    const assignedWorkingHoursReportTDs =
      assignedWorkingHoursReport &&
      getReportTDs(
        assignedWorkingHoursReport.projectName,
        assignedWorkingHoursReport.assignedWorkingHours,
        assignedWorkingHoursRowSpan
      )
    const actualWorkingHoursReport = actualWorkingHoursReports[index]
    const actualWorkingHoursReportTDs =
      actualWorkingHoursReport &&
      getReportTDs(
        actualWorkingHoursReport.projectName,
        actualWorkingHoursReport.actualWorkingHours,
        actualWorkingHoursRowSpan
      )

    return (
      <tr key={`${dailyReport.date.toLocaleDateString()}-${index}`}>
        {assignedWorkingHoursReportTDs}
        {actualWorkingHoursReportTDs}
      </tr>
    )
  })
}

const hoursToString = (hours: number): string => {
  const hourOnly = Math.floor(hours)
  const minute = Math.round((hours - hourOnly) * 60)
  return `${hourOnly.toString().padStart(2, "0")}:${minute.toString().padStart(2, "0")}`
}

export const ScheduleTable: React.FC<ScheduleTableProps> = React.memo((props) => {
  const dailyReportsTRs = useMemo(() => {
    return props.dailyReports.map((dailyReport) => {
      const { assignedWorkingHoursReports, actualWorkingHoursReports } = dailyReport
      const maxReportLength = Math.max(assignedWorkingHoursReports.length, actualWorkingHoursReports.length)
      const rowSpan = maxReportLength + 1
      const day = dailyReport.date.getDay()
      const isSundayOrSaturday = day === 0 || day === 6
      return (
        <>
          <tr>
            <td className="single line" rowSpan={rowSpan}>
              <span className={isSundayOrSaturday ? RedColor : ""}>
                {du.toZeroPaddedLocalDateStringWithDay(dailyReport.date)}
              </span>
            </td>
            <td className="right aligned single line" rowSpan={rowSpan}>
              {hoursToString(dailyReport.expectedWorkingHours)}
            </td>
            {assignedWorkingHoursReports.length === 0
              ? getEmptyReportTDs(rowSpan)
              : getSummaryAssignedWorkingHoursTDs(assignedWorkingHoursReports)}
            {actualWorkingHoursReports.length === 0
              ? getEmptyReportTDs(rowSpan)
              : getSummaryActualWorkingHoursTDs(actualWorkingHoursReports)}
          </tr>
          {getDailyReportTRs(dailyReport)}
        </>
      )
    })
  }, [props.dailyReports])

  return (
    <table className="ui celled structured table">
      <thead>
        <tr>
          <th className="single line" rowSpan={2}>
            日付
          </th>
          <th className="right aligned single line" rowSpan={2}>
            予定稼働時間
          </th>
          <th className="right aligned single line" colSpan={2}>
            アサイン
          </th>
          <th className="right aligned single line" colSpan={2}>
            実績
          </th>
        </tr>
        <tr>
          <th>プロジェクト名</th>
          <th className="right aligned single line">時間</th>
          <th>プロジェクト名</th>
          <th className="right aligned single line">時間</th>
        </tr>
      </thead>
      <tbody>{dailyReportsTRs}</tbody>
    </table>
  )
})

export default ScheduleTable
