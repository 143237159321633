import { styled } from "linaria/react"
import React, { useCallback, useState } from "react"
import ReactModal from "react-modal"
import ReactSelect from "react-select"
import { v4 as uuidv4 } from "uuid"

import { clients } from "api/clients"
import { WorkspaceMember, RoleOption } from "component/page/workspaceMembers/index"
import * as m from "model"
import { AppStore, WorkspaceMemberInvitationModalState } from "model/appStore"
import { Dispatch, Reducer } from "reducer/common"

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "340px",
    height: "400px",
    padding: 0,
  },
}

const MemberInvitationForm = styled.form`
  height: 100%;
  width: 100%;
`
const MemberInvitationFormHeader = styled.h1`
  padding: 1rem 1rem 0 1rem;
`
const MemberInvitationFormContent = styled.div`
  padding: 0 1rem;
`
const MemberInvitationFormFooter = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  .ui.button {
    margin-left: 0.75em;
  }
`
const MemberInvitationFormButtons = styled.div`
  display: flex;
  justify-content: right;
  background: #f9fafb;
  border-top: 1px solid rgba(34, 36, 38, 0.15);
  padding: 1rem;
`

type MemberInvitationModalProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  workspaceId: string | null
  assignableRoles: RoleOption[]
  modal: WorkspaceMemberInvitationModalState | null
  members: WorkspaceMember[]
  setMembers: (f: (prev: WorkspaceMember[]) => WorkspaceMember[]) => void
}

const MemberInvitationModal: React.FC<MemberInvitationModalProps> = React.memo((props) => {
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const hideModal = useCallback(
    () => props.dispatch.apply(props.reducer.setModal(null)),
    [props.dispatch, props.reducer]
  )
  const setUserId = useCallback(
    (event: React.FocusEvent<HTMLInputElement>) => {
      if (props.modal === null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.modal, userId: event.target.value }))
    },
    [props.dispatch, props.reducer, props.modal]
  )
  const setRole = useCallback(
    (role) => {
      if (props.modal === null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.modal, role: role.value }))
    },
    [props.dispatch, props.reducer, props.modal]
  )
  const inviteMember = useCallback(
    async (event: React.FormEvent) => {
      event.preventDefault()
      if (props.modal === null || props.workspaceId === null) {
        return
      }
      setIsLoading(true)

      const modal = props.modal
      const workspaceId = props.workspaceId
      const oldMember = props.members.find((x) => x.userId === modal.userId && x.status === "inactive")

      const [memberId, body] = await (async (
        member?: WorkspaceMember
      ): Promise<[string, m.PutWorkspaceMemberRequest]> => {
        if (member) {
          const workspaceTags = await clients.workspaceTag.getWorkspaceMemberTags(workspaceId, member.workspaceMemberId)
          const body = {
            role: modal.role,
            workspaceTags: workspaceTags.map((x) => x.workspaceTagId),
            lastUpdatedDatetime: member.updatedDatetime,
          }
          return [member.workspaceMemberId, body]
        } else {
          const body = {
            userId: modal.userId,
            role: modal.role,
            workspaceTags: [],
          }
          return [uuidv4(), body]
        }
      })(oldMember)

      clients.workspaceMember
        .putWorkspaceMember(workspaceId, memberId, body)
        .then((newMember) => {
          const member: WorkspaceMember = {
            workspaceMemberId: newMember.workspaceMemberId,
            userId: newMember.userId,
            username: newMember.username,
            role: newMember.role,
            company: oldMember?.company ?? "",
            status: newMember.status,
            updatedDatetime: newMember.updatedDatetime.toISOString(),
          }
          props.setMembers((members) => {
            const index = members.findIndex((x) => x.workspaceMemberId === member.workspaceMemberId)
            if (index === -1) {
              return members.concat(member)
            } else {
              return members.map((x) => (x.workspaceMemberId === member.workspaceMemberId ? member : x))
            }
          })
          props.dispatch.apply(props.reducer.setModal(null))
        })
        .catch((error: Error) => {
          props.dispatch.apply(
            props.reducer.setModal({
              ...modal,
              errorMessage: error.message,
            })
          )
        })
        .finally(() => setIsLoading(false))
    },
    [props]
  )

  return (
    <ReactModal isOpen={props.modal !== null} style={modalStyle} onRequestClose={hideModal}>
      <MemberInvitationForm
        className={"ui form" + (isLoading ? " loading" : "") + (props.modal?.errorMessage ? " error" : "")}
        onSubmit={inviteMember}
      >
        <MemberInvitationFormHeader>メンバーの追加</MemberInvitationFormHeader>
        <MemberInvitationFormContent>
          <div className="required field">
            <label>ユーザーID</label>
            <input type="text" defaultValue={props.modal?.userId ?? ""} onChange={setUserId} required />
          </div>
          <div className="required field">
            <label>役割</label>
            <ReactSelect
              options={props.assignableRoles}
              onChange={setRole}
              value={props.assignableRoles.find((assignableRole) => assignableRole.value === props.modal?.role)}
            />
          </div>
        </MemberInvitationFormContent>
        <MemberInvitationFormFooter>
          <div className="ui error message">{props.modal?.errorMessage ?? undefined}</div>
          <MemberInvitationFormButtons>
            <button className="ui olive ok button rounded-pill" type="submit">
              追加
            </button>
            <button className="ui orange cancel delete button rounded-pill" onClick={hideModal}>
              キャンセル
            </button>
          </MemberInvitationFormButtons>
        </MemberInvitationFormFooter>
      </MemberInvitationForm>
    </ReactModal>
  )
})

export default MemberInvitationModal
