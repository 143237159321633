import "react-datepicker/dist/react-datepicker.css"
import "component/page/schedule/assignModal.css"

import ja from "date-fns/locale/ja"
import React, { useCallback } from "react"
import ReactDatepicker, { registerLocale } from "react-datepicker"
import ReactModal from "react-modal"
import ReactSelect from "react-select"
import { v4 as uuidv4 } from "uuid"

import { clients } from "api/clients"
import * as m from "model"
import { AppStore, AssignModalState } from "model/appStore"
import { ScheduleState } from "model/scheduleState"
import { Dispatch, Reducer } from "reducer/common"

registerLocale("ja", ja)

const modalStyle = {
  overlay: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "rgba(0,0,0,.85)",
    zIndex: 200,
  },
  content: {
    display: "flex",
    flexDirection: "column" as const,
    position: "static" as const,
    width: "540px",
    height: "600px",
    padding: 0,
  },
}

export type AssignModalProps = {
  dispatch: Dispatch<AppStore>
  reducer: Reducer
  token: string | null
  workspaceId: string | null
  members: m.WorkspaceMember[]
  jobs: m.Job[]
  state: AssignModalState | null
  schedule: ScheduleState
}

export const AssignModal: React.FC<AssignModalProps> = (props) => {
  const onRequestClose = useCallback(
    () => props.dispatch.apply(props.reducer.setModal(null)),
    [props.dispatch, props.reducer]
  )
  const onJobSelectChange = useCallback(
    (jobs: ReadonlyArray<m.Job>) => {
      if (props.state == null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.state, selectedJobs: jobs }))
    },
    [props.dispatch, props.reducer, props.state]
  )
  const onMemberSelectChange = useCallback(
    (members: ReadonlyArray<m.WorkspaceMember>) => {
      if (props.state == null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.state, selectedMembers: members }))
    },
    [props.dispatch, props.reducer, props.state]
  )
  const onStartChange = useCallback(
    (date: Date) => {
      if (props.state == null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.state, startDate: m.DateOnly.fromDateAsUTC(date) }))
    },
    [props.dispatch, props.reducer, props.state]
  )
  const onEndChange = useCallback(
    (date: Date) => {
      if (props.state == null) {
        return
      }
      props.dispatch.apply(props.reducer.setModal({ ...props.state, endDate: m.DateOnly.fromDateAsUTC(date) }))
    },
    [props.dispatch, props.reducer, props.state]
  )
  const setAssignTypePercentage = useCallback(() => {
    if (props.state == null) {
      return
    }
    props.dispatch.apply(props.reducer.setModal({ ...props.state, assignType: "percentage" }))
  }, [props.dispatch, props.reducer, props.state])
  const setAssignTypeHours = useCallback(() => {
    if (props.state == null) {
      return
    }
    props.dispatch.apply(props.reducer.setModal({ ...props.state, assignType: "hours" }))
  }, [props.dispatch, props.reducer, props.state])
  const onPercentageChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.state == null) {
        return
      }
      const value = e.target.value
      const num = value == null || value.length === 0 ? null : Number(value)
      props.dispatch.apply(props.reducer.setModal({ ...props.state, percentage: num }))
    },
    [props.dispatch, props.reducer, props.state]
  )
  const onHoursChange = useCallback(
    (e: React.FocusEvent<HTMLInputElement>) => {
      if (props.state == null) {
        return
      }
      const value = e.target.value
      const num = value == null || value.length === 0 ? null : Number(value)
      props.dispatch.apply(props.reducer.setModal({ ...props.state, hours: num }))
    },
    [props.dispatch, props.reducer, props.state]
  )

  const onSubmitClick = useCallback(async () => {
    if (!props.workspaceId || props.state?.kind !== "assign") return
    const workspaceId = props.workspaceId
    const modalState = props.state

    const errors = []
    if (modalState.selectedJobs.length === 0) errors.push("プロジェクトが選択されていません。")
    if (modalState.selectedMembers.length === 0) errors.push("メンバーが選択されていません。")
    const maybeValue =
      modalState.assignType === "hours"
        ? modalState.hours
        : modalState.assignType === "percentage"
        ? modalState.percentage
        : null
    if (!maybeValue) errors.push("選択されたアサインルールに対する値が設定されていません。")
    // スケジュールの重複チェック(ただし現状読み込み済みの範囲でのチェックのため完全ではない)
    const scheduleArray = Array.from(props.schedule.schedules.values())
    const existsOverlapping = modalState.selectedJobs.some((job) =>
      modalState.selectedMembers.some((member) =>
        scheduleArray.some(
          (schedule) =>
            modalState.schedule?.scheduleId !== schedule.scheduleId &&
            schedule.jobId === job.jobId &&
            schedule.workspaceMemberId === member.workspaceMemberId &&
            schedule.startDate.utcZeroHourDate <= modalState.endDate.utcZeroHourDate &&
            modalState.startDate.utcZeroHourDate <= schedule.endDate.utcZeroHourDate
        )
      )
    )
    if (existsOverlapping) errors.push("他のスケジュールと重複しています。")
    if (errors.length > 0) {
      const newModalState = {
        ...modalState,
        errorMessage: errors.join("\n"),
      }
      props.dispatch.apply(props.reducer.setModal(newModalState))
      return
    }

    // ここに処理が来る場合はmaybeValueはnullではないことが確定している
    const value = maybeValue ?? 0
    if (modalState.schedule) {
      const putScheduleRequest: m.PutScheduleRequest = {
        ...modalState.schedule,
        startDate: modalState.startDate,
        endDate: modalState.endDate,
        type: modalState.assignType,
        value: value,
      }
      const updatedSchedule = await clients.schedule.put(putScheduleRequest).catch((e) => {
        // TODO 適切なエラー処理について検討が必要。まずは重複スケジュールの登録をさせないことを優先するため暫定的にconsoleに出力している
        console.warn(
          `ジョブID=(${putScheduleRequest.jobId})、ワークスペースメンバーID=(${
            putScheduleRequest.workspaceMemberId
          }) に対するスケジュールを登録できませんでした。理由 : ${e.message ?? e}`
        )
        return undefined
      })
      if (updatedSchedule) {
        props.dispatch.apply(
          props.reducer.setScheduleState((prev) => {
            return {
              ...prev,
              schedules: new Map(prev.schedules.set(updatedSchedule.scheduleId, updatedSchedule)),
              requiresLoadSum: true,
            }
          })
        )
      }
      props.dispatch.apply(props.reducer.setModal(null))
    } else {
      const putScheduleRequests: m.PutScheduleRequest[] = modalState.selectedJobs.flatMap((job) =>
        modalState.selectedMembers.map((member) => ({
          scheduleId: uuidv4(),
          workspaceId: workspaceId,
          jobId: job.jobId,
          workspaceMemberId: member.workspaceMemberId,
          startDate: modalState.startDate,
          endDate: modalState.endDate,
          type: modalState.assignType,
          value: value,
        }))
      )
      const putSchedulePromises = putScheduleRequests.map((request) =>
        clients.schedule.put(request).catch((e: Error) => {
          const job = props.jobs.find((job) => job.jobId === request.jobId)
          const member = props.members.find((member) => member.workspaceMemberId === request.workspaceMemberId)
          throw new Error(
            `プロジェクト:${job?.jobName ?? request.jobId}, ワークスペースメンバー:${
              member?.username ?? request.workspaceMemberId
            } のスケジュール登録時にエラーが発生しました : ${e.message}`
          )
        })
      )
      const putScheduleResults = await Promise.allSettled(putSchedulePromises)
      props.dispatch.apply(
        props.reducer.setScheduleState((prev) => {
          const newSchedules = putScheduleResults.reduce(
            (acc, result) => (result.status === "fulfilled" ? acc.set(result.value.scheduleId, result.value) : acc),
            prev.schedules
          )
          return {
            ...prev,
            schedules: new Map(newSchedules),
            requiresLoadSum: true,
          }
        })
      )
      props.dispatch.apply(props.reducer.setModal(null))
    }
  }, [props.workspaceId, props.state, props.schedule, props.dispatch, props.reducer, props.jobs, props.members])

  const onDeleteClick = useCallback(async () => {
    if (!props.workspaceId) return
    if (props.state?.schedule) {
      const deletingScheduleId = props.state.schedule.scheduleId
      await clients.schedule.delete(props.workspaceId, deletingScheduleId)
      props.schedule.schedules.delete(deletingScheduleId)
      props.dispatch.apply(
        props.reducer.setScheduleState((prev) => {
          return {
            ...prev,
            schedules: new Map(props.schedule.schedules),
            requiresLoadSum: true,
          }
        })
      )
      props.dispatch.apply(props.reducer.setModal(null))
    }
  }, [props.dispatch, props.reducer, props.state, props.workspaceId, props.schedule])

  return (
    <ReactModal isOpen={props.state != null} style={modalStyle} onRequestClose={onRequestClose}>
      <h1 className="aw-modal-header">アサイン</h1>
      <div className="aw-modal-content">
        <div className="ui grid">
          <div className="row">
            <div className="column">
              <span className="ui small text gray">プロジェクト</span>
              <ReactSelect
                getOptionLabel={(x) => x.jobName}
                getOptionValue={(x) => x.jobId}
                isDisabled={props.state?.schedule != null}
                isMulti
                onChange={onJobSelectChange}
                options={props.jobs}
                placeholder=""
                value={props.state?.selectedJobs ?? []}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <span className="ui small text gray">メンバー</span>
              <ReactSelect
                getOptionLabel={(x) => x.username}
                getOptionValue={(x) => x.workspaceMemberId}
                isDisabled={props.state?.schedule != null}
                isMulti
                onChange={onMemberSelectChange}
                options={props.members}
                placeholder=""
                value={props.state?.selectedMembers ?? []}
              />
            </div>
          </div>
          <div className="row">
            <div className="eight wide column">
              <span className="ui small text gray">開始日</span>
              <ReactDatepicker
                className="aw-datepicker"
                selected={props.state?.startDate.utcZeroHourDate}
                dateFormat="yyyy/MM/dd"
                locale="ja"
                onChange={onStartChange}
              />
            </div>
            <div className="eight wide column">
              <span className="ui small text gray">終了日</span>
              <ReactDatepicker
                className="aw-datepicker"
                dateFormat="yyyy/MM/dd"
                locale="ja"
                selected={props.state?.endDate.utcZeroHourDate}
                onChange={onEndChange}
              />
            </div>
          </div>
          <div className="row">
            <div className="column">
              <span className="ui small text gray">アサインルール</span>
              <div style={{ marginTop: "10px" }}>
                <span className="ui radio checkbox">
                  <input
                    type="radio"
                    checked={props.state?.assignType === "percentage"}
                    onChange={setAssignTypePercentage}
                  />
                  <label style={{ cursor: "pointer" }} onClick={setAssignTypePercentage}>
                    予定稼働時間に対しての割合
                  </label>
                </span>
                <span className="ui right labeled input" style={{ marginLeft: "10px" }}>
                  <input
                    type="number"
                    min="1"
                    max="100"
                    step="1"
                    defaultValue={props.state?.percentage ?? ""}
                    onBlur={onPercentageChange}
                  />
                  <span className="ui label">%</span>
                </span>
              </div>
              <div style={{ marginTop: "10px" }}>
                <span className="ui radio checkbox">
                  <input type="radio" checked={props.state?.assignType === "hours"} onChange={setAssignTypeHours} />
                  <label style={{ cursor: "pointer" }} onClick={setAssignTypeHours}>
                    1日あたりの時間
                  </label>
                </span>
                <span className="ui right labeled input" style={{ marginLeft: "10px" }}>
                  <input
                    type="number"
                    min="0.25"
                    max="23.75"
                    step="0.25"
                    defaultValue={props.state?.hours ?? ""}
                    onBlur={onHoursChange}
                  />
                  <span className="ui label">時 / 日</span>
                </span>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column">
              <div className="aw-modal-error">{props.state?.errorMessage ?? ""}</div>
            </div>
          </div>
        </div>
      </div>
      <div className="aw-modal-footer">
        <div>
          {props.state?.schedule == null ? (
            <button className="ui olive ok button rounded-pill" onClick={onSubmitClick}>
              登録
            </button>
          ) : (
            <>
              <button className="ui olive ok button rounded-pill" onClick={onSubmitClick}>
                更新
              </button>
              <button className="ui orange cancel delete button rounded-pill" onClick={onDeleteClick}>
                削除
              </button>
            </>
          )}
        </div>
      </div>
    </ReactModal>
  )
}

export default AssignModal
