export type ErrorResponse = ApiErrorResponse | ApiGatewayErrorResponse
export interface ApiErrorResponse {
  errors: {
    request_id: string | null | undefined
    error_code: string | null | undefined
    message: string | null | undefined
    ext: Record<string, any> | null | undefined // eslint-disable-line @typescript-eslint/no-explicit-any
  }[]
}
export interface ApiGatewayErrorResponse {
  message: string
}

function isApiErrorResponse(errorResponse: ErrorResponse): errorResponse is ApiErrorResponse {
  return (errorResponse as ApiErrorResponse).errors !== undefined
}

export class ApiError extends Error {
  readonly status?: number
  readonly response?: ErrorResponse

  constructor(readonly cause: Error, response?: { status: number; data: ErrorResponse }) {
    super(
      !!response
        ? isApiErrorResponse(response.data)
          ? response.data.errors.map((e) => e.message ?? "").join("\n")
          : response.data.message
        : cause.message
    )
    this.status = response?.status
    this.response = response?.data
  }
}
